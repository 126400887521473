import { useCurrentUser } from '@/auth/hooks/use-current-user'
import { useProfileSettings } from '../hooks/use-profile-section'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import {
  CalendarIcon,
  ExclamationTriangleIcon,
  UpdateIcon,
} from '@radix-ui/react-icons'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { format } from 'date-fns'

export const ProfileSection = () => {
  const { user } = useCurrentUser()

  const { userAttributes, form, loading, submitting, error, onSubmit } =
    useProfileSettings()

  return (
    <section className="w-full space-y-4">
      <h2 className="text-lg font-medium">Profile</h2>
      {loading ? (
        <div className="w-full flex justify-center content-center pt-12 pb-16">
          <div className="flex flex-row items-center">
            <UpdateIcon className="w-4 h-4 animate-spin mr-2 duration-1000" />
            Loading
          </div>
        </div>
      ) : (
          <Form {...form}>
            <form onSubmit={onSubmit} className="space-y-1">
              <div className="space-y-3 mb-4">
                <FormItem className="w-full mb-4">
                  <FormLabel>Email address</FormLabel>
                  <FormControl>
                    <Input
                      type="email"
                      value={user?.email || ''}
                      disabled
                      className="w-full text-sm font-light"
                      readOnly
                    />
                  </FormControl>
                </FormItem>
                {error && (
                  <Alert variant="destructive">
                    <ExclamationTriangleIcon className="h-4 w-4" />
                    <AlertTitle>Failed to update profile</AlertTitle>
                    <AlertDescription>{error.message}</AlertDescription>
                  </Alert>
                )}
                <FormField
                  control={form.control}
                  name="given_name"
                  render={({ field }) => (
                    <FormItem className="w-full mb-4">
                      <FormLabel>First Name</FormLabel>
                      <FormControl>
                        <Input
                          type="text"
                          autoComplete="given-name"
                          placeholder={userAttributes.given_name}
                          {...field}
                          className="w-full"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="family_name"
                  render={({ field }) => (
                    <FormItem className="w-full mb-4">
                      <FormLabel>Last Name</FormLabel>
                      <FormControl>
                        <Input
                          type="text"
                          autoComplete="family-name"
                          placeholder={userAttributes.family_name}
                          {...field}
                          className="w-full"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="birthdate"
                  render={({ field }) => (
                    <FormItem className="w-full mb-4">
                      <FormLabel>Date of birth</FormLabel>
                      <FormControl>
                        <Input
                          className="w-full"
                          type="date"
                          autoComplete="bday"
                          placeholder={userAttributes.birthdate}
                          {...field}
                          value={field.value || ''}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <Button
                className="w-full"
                variant="default"
                type="submit"
                formAction="submit"
                loading={submitting}
                disabled={submitting || !form.formState.isDirty}
              >
                Save
              </Button>
            </form>
          </Form>
      )}
    </section>
  )
}

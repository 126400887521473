import { useMemo, useEffect } from 'react'
import { debounce } from 'lodash'
import { SearchClient } from 'typesense'
import Bugsnag from '@bugsnag/js'
import {
  DocumentSchema,
  SearchResponseFacetCountSchema,
  SearchResponseHit,
} from 'typesense/lib/Typesense/Documents'

export interface UseTypesenseSearchProps<T extends DocumentSchema> {
  query?: string
  queryBy?: Readonly<Array<keyof T>>
  filterBy?: string
  sortBy?: string
  /** Typesense page indexing starts from 1 */
  page?: number
  pageSize?: number
  debounceWait: number
  options: TypesenseOptions
  onSearchSuccess: (
    newResults: T[],
    metadata: {
      found: number
      query: string
      filterBy?: string
      sortBy?: string
      page: number
      facets?: SearchResponseFacetCountSchema<T>[]
    },
  ) => void
  onSearchError: (err: Error | string, query: string) => void
  parseResults: (hits: SearchResponseHit<any>[]) => T[]
  metricRecorder?: (
    query: string,
    results: SearchResponseHit<any>[],
    duration: number,
    metadata: {
      found: number
      page: number
      filter?: string | undefined
      sort?: string | undefined
    },
  ) => void
  skip?: boolean
}

export type TypesenseOptions = {
  host: string
  apiKey: string
  collection: string
}

/**
 * Hook for running a Typesense search on a given collection with debounced
 * queries and retries.
 */
export const useTypesenseSearch = <T extends DocumentSchema>({
  query,
  queryBy,
  filterBy,
  sortBy,
  page,
  pageSize,
  debounceWait,
  options,
  onSearchSuccess,
  onSearchError,
  parseResults,
  metricRecorder,
  skip = false,
}: UseTypesenseSearchProps<T>) => {
  const typesenseClient = useMemo(() => {
    return new SearchClient({
      nodes: [
        {
          host: options.host,
          port: 443,
          protocol: 'https',
        },
      ],
      apiKey: options.apiKey,
      connectionTimeoutSeconds: 5,
      numRetries: 2, // Totals to 3 attempts, including the initial attempt
    })
  }, [options.apiKey, options.host])

  const runTypeSenseSearch = useMemo(() => {
    return debounce(
      async (
        searchTerm: string,
        filter?: string,
        sort?: string,
        pageNumber?: number,
        perPage?: number,
      ) => {
        if ((searchTerm?.trim().length === 0 && !filter) || skip) {
          return onSearchSuccess([], {
            query: searchTerm,
            filterBy: filter,
            sortBy: sort,
            page: pageNumber ?? 1,
            found: 0,
          })
        }
        const startTime = Date.now()
        try {
          const response = await typesenseClient
            .collections<T>(options.collection)
            .documents()
            .search(
              {
                q: searchTerm.trim(),
                query_by: queryBy ? queryBy.join(', ') : 'symbol, name',
                filter_by: filter,
                sort_by: sort,
                page: pageNumber ?? 1,
                per_page: perPage ?? 20,
              },
              {},
            )
          const hits = response.hits ?? []
          const results = parseResults(hits)

          response.facet_counts

          const duration = Date.now() - startTime
          metricRecorder?.(searchTerm, hits, duration, {
            page: pageNumber ?? 1,
            found: response.found,
            filter,
            sort,
          })

          return onSearchSuccess(results, {
            query: searchTerm,
            filterBy: filter,
            sortBy: sort,
            page: pageNumber ?? 1,
            found: response.found,
            facets: response.facet_counts,
          })
        } catch (err) {
          if (err instanceof Error) {
            // 'err' is an instance of the Error class
            onSearchError(err, searchTerm)
            Bugsnag.notify(err, (report) => {
              report.addMetadata('TypesenseSearch', 'host', options.host)
              report.addMetadata(
                'TypesenseSearch',
                'collection',
                options.collection,
              )
              report.addMetadata('TypesenseSearch', 'searchTerm', searchTerm)
              filter && report.addMetadata('TypesenseSearch', 'filters', filter)
              sort && report.addMetadata('TypesenseSearch', 'sort', sort)
            })
          }
        }
      },
      debounceWait,
    )
  }, [
    debounceWait,
    onSearchSuccess,
    typesenseClient,
    options.collection,
    options.host,
    queryBy,
    parseResults,
    metricRecorder,
    onSearchError,
    skip,
  ])
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    runTypeSenseSearch(query ?? '*', filterBy, sortBy, page, pageSize)
  }, [filterBy, page, pageSize, query, runTypeSenseSearch, sortBy])
}

import { createContext, useEffect, useState } from 'react'
import { z } from 'zod'

type Theme = 'dark' | 'light'
const ThemeSchema = z.union([z.literal('dark'), z.literal('light')])

type ThemeProviderProps = {
  children: React.ReactNode
  defaultTheme?: Theme
  storageKey?: string
}

type ThemeProviderState = {
  theme: Theme
  setTheme: React.Dispatch<React.SetStateAction<Theme>>
}

const initialState: ThemeProviderState = {
  theme: 'light',
  setTheme: () => 0,
}

export const ThemeProviderContext =
  createContext<ThemeProviderState>(initialState)

export const ThemeProvider = ({
  children,
  defaultTheme = 'light',
  storageKey = '@zoya/color-theme',
  ...props
}: ThemeProviderProps) => {
  const [theme, setTheme] = useState<Theme>(
    ThemeSchema.safeParse(localStorage.getItem(storageKey)).data ||
      defaultTheme,
  )

  useEffect(() => {
    localStorage.setItem(storageKey, theme)

    const root = window.document.documentElement
    root.classList.remove('light', 'dark')

    switch (theme) {
      case 'dark':
        root.classList.add('dark')
        break
      case 'light':
        root.classList.add('light')
        break
    }
  }, [theme])

  const value = {
    theme,
    setTheme,
  }

  return (
    <ThemeProviderContext.Provider {...props} value={value}>
      {children}
    </ThemeProviderContext.Provider>
  )
}

import { memoize } from 'lodash'
import { Purchases } from '@revenuecat/purchases-js'

export const configurePurchases = memoize(
  async (userId?: string) => {
    if (!userId || typeof userId !== 'string') {
      console.debug('Invalid user id')
      return
    }

    
    if (!Purchases.isConfigured()) {
      console.debug('Configuring purchases')
      Purchases.configure(
        import.meta.env.VITE_REVENUECAT_PUBLIC_API_KEY,
        userId,
      )
      return
    }
    
    const prevConfigUserId = Purchases.getSharedInstance().getAppUserId()

    if (prevConfigUserId === userId) {
      console.debug('Purchases already configured for user')
      return
    }

    console.debug('Changing user ')
    await Purchases.getSharedInstance().changeUser(userId)
  },
  (userId) => userId,
)

import { ZoyaLogo } from '@/common/components/zoya-logo'
import { SignInForm } from '../components/forms/sign-in'

export const SignIn = () => {
  return (
    <div className="w-full h-screen	 lg:grid lg:min-h-[600px] lg:grid-cols-2 xl:min-h-[800px]">
      <div className="flex items-center justify-center py-12">
        <div className="mx-auto grid w-[350px] gap-6">
          <div className="block mb-12 lg:hidden ">
            <ZoyaLogo className="w-28" />
          </div>

          <div className="grid gap-2 text-center">
            <h1 className="text-3xl font-bold text-foreground text-start lg:text-center">
              Login
            </h1>
            <p className="text-jungle/60 text-start lg:text-center">
              Enter your email below to login to your account
            </p>
          </div>
          <SignInForm />
        </div>
      </div>
      <div className="hidden lg:block  ">
        <div className="h-[98%] bg-jungle dark:bg-background flex items-center justify-center py-12 m-2 rounded-2xl ">
          <img src="/zoya-logo-dark.svg" className="w-36" />
        </div>
      </div>
    </div>
  )
}

import { useHotkeys } from '@mantine/hooks'
import { useTheme } from '../hooks/use-theme'

export const ShortcutsListener = () => {
  const { setTheme } = useTheme()

  const recordShortcutMetric = (shortcut: string) => {
    // TODO record metrics for shortcuts used
    console.debug(shortcut)
  }

  useHotkeys([
    [
      'mod+j',
      () => {
        recordShortcutMetric('mod+j')

        setTheme((_) => (_ === 'dark' ? 'light' : 'dark'))
      },
    ],
  ])

  return null
}

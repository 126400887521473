import { Button } from '@/components/ui/button'
import { DotsVerticalIcon } from '@radix-ui/react-icons'

import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { AvatarIcon, ExclamationTriangleIcon } from '@radix-ui/react-icons'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { useCallback, useEffect } from 'react'
import {
  Maybe,
  PortfolioAccount,
  SnaptradeConnectionType,
  SupportedCountryCodes,
  useSyncAllInstitutionsMutation,
  useUnlinkPortfolioAccountMutation,
} from '@/graphQL/generatedGraphql'

import { SnapTradeReact } from 'snaptrade-react'
import { useSnaptradeLink } from '../hooks/use-snaptrade-link'
import { usePlaidLink } from '../hooks/use-plaid-link'
import { AccountCard } from './account-card'
import { useGetInstitutionLogo } from '../hooks/use-get-institutions-logo'
import { useToast } from '@/hooks/use-toast'
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"

const defaultLogo = {
  image:
    'iVBORw0KGgoAAAANSUhEUgAAAJgAAACYCAMAAAAvHNATAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAJcEhZcwAACxMAAAsTAQCanBgAAAA8UExURUdwTO3t7e7u7uzs7O3t7e3t7e3t7e/v7+zs7O7u7u3t7REREZubm0hISH9/fy8vL9nZ2bq6uh8fH2dnZxAlB0cAAAAKdFJOUwB4X5DFoe8ZQd81kq9CAAADm0lEQVR42u2c2a7qMAxFSZuZAi38/79eaJmHUjtOstE9+wmJSizZjmNSx6tVopxvWqN1CHFUCFobpbxfVZRXRtv4SUc+XwVqhukqq4vCOd8ugLoomEJsJKqz4fKzOUWmOtutcTmNZZhYo0wuNK9jorSHxBrRpK3mRLDEHepUFJQSQ/Mhiio0MuYyUVwS/vQ2ZlCy0VwbM6lNMpoLMZtCAlljY0ZZtjtVzCwFsxpfVmfVXC+7ReUM+5QlUIqLSlaOi0ZWkotCVpaLQKZjYS1cmyYWl4HI98w9oIlV9HXfdLYOmHVYC3Lx0mxjNbWAATbJIzryS5iZWFUG0pEzzqzryJmVqWJ1vd0AXASQw4v8c50BarB38a8xwF5M5mPENJlGAdOQEfZqMoMDZkAN9riXN0BgD+lfZJfsDptOxGSyuaLbrY/adaLhb4SwhNBuRXbqP6Nhs75TMto1/L0klgSal/DkDWu/lXJom74m77F6sVizqZ58xjppK4Hmk7LrO6yjegE0lVBYfMB6QtsMCSWGlcWSQLPMEPuCJYDmOf/aFmAlozX0LNYvw5rQrin30NOLMmLsH5ZijWiXp9dbevQTjbxZjnWPtiFHPzX2O6oBJn9SM5qjL8rRZPvFMdPvxxijRz95UU6/tFv6+FQ/9gXA4jBtN5tF2rEceSowGDXPYU3Ugf4bhrNT9jsa147+E8d8wdnChz2Fa9+zwFhVIsVmvAIjMMvX7j6kxwRy+eY+m3a8wJ/AIiZY/AP7A/t/wWDTBSyYxgTTuGAGE8wwX89nB1PMI5UCYB4TzDPfPRQA450MZwfjHo/lBtPco+HcYIbbPJAbTJEP7oZu1PjX8jB9nk6qz5+76ez19amBGvvE6N+umSKdQ1n64XAZME3vUCkDpugvIMqAOfrLt6FjihL8oX7/3+csBtUM8pAsKraYzicLQF8avMaeR08KvBQXVcDqArypAeiwnsuu2E1HSOHvfqOxDbcVELZ5EsVkboXZb2p+qaUZtgkct22+fvw3v3Y1o/JePnczyUM6EvnCFO4VM9hLebjXGHEvflbJZi3QZf/5IuzHrmPDXmDHvfKPOyQBd6wE7iCOMvmMOVgIdNgL8Hgc3IFCuCOYTu7MYjQrMFQOdcxXBqMFsRl8qKPkRP0JOhcQdJKizjWyM8mheSeJOu4KtSrnEFHuJFHblhoJawKJyq3KCXK07w3O6BmmOsOQb3Reqefx0W2T7r1/3Z/PsFrHmzcAAAAASUVORK5CYII=',
  type: 'base64',
  format: 'png',
}

interface InstitutionCardProps {
  institution: {
    accountId: string
    institutionName: Maybe<string> | undefined
    accounts: PortfolioAccount[]
  }
  onSyncAccountClick: () => void
  onRefreshSuccess: () => void
  onRemoveSuccess: () => void
  onAccountUpdateSuccess: () => void
}

export const InstitutionCard = ({
  institution,
  onSyncAccountClick,
  onRefreshSuccess,
  onRemoveSuccess,
  onAccountUpdateSuccess,
}: InstitutionCardProps) => {
  const [syncAccountMutation] = useSyncAllInstitutionsMutation()
  const { toast } = useToast()

  const onRelinkSuccess = useCallback(() => {
    syncAccountMutation({
      onCompleted: () => {
        onRefreshSuccess()
      },
    })
  }, [syncAccountMutation, onRefreshSuccess])

  const {
    loginLink,
    isSnaptradeOpen,
    onSnaptradeClose,
    onSnaptradeLinkSuccess,
    createConnectionUrl,
  } = useSnaptradeLink(onRelinkSuccess)

  const onRefreshSnaptradeButtonClick = useCallback(() => {
    createConnectionUrl({
      variables: {
        input: {
          reconnect: institution.accountId,
          connectionType: SnaptradeConnectionType.Trade,
        },
      },
    })
  }, [createConnectionUrl, institution])

  const [unlinkAccount] = useUnlinkPortfolioAccountMutation()

  const onRemoveButtonClick = useCallback(() => {
    toast({
      title: "Unlinking account",
      description: "Please wait while we unlink your account...",
    })
    unlinkAccount({
      variables: {
        accountId: institution.accountId,
      },
      onCompleted: () => {
        onRemoveSuccess()
        toast({
          title: "Account unlinked",
          description: "Your account has been successfully unlinked.",
        })
      },
      onError: (error) => {
        toast({
          title: "Error unlinking account",
          description: error.message,
          variant: "destructive",
        })
      },
    })
  }, [onRemoveSuccess, unlinkAccount, institution, toast])

  const { createLinkTokenMutation } = usePlaidLink(onRelinkSuccess)

  const onRefreshPlaidButtonClick = useCallback(() => {
    createLinkTokenMutation({
      variables: {
        countryCodes: SupportedCountryCodes.Us,
        accountId: institution.accountId,
      },
    })
  }, [createLinkTokenMutation, institution])

  const institutionLogo = useGetInstitutionLogo(
    institution.accounts[0].institutionId ?? '',
    institution.accounts[0].vendor === 'snaptrade'
  )

  const logo = institutionLogo ?? defaultLogo
  const logoUri =
    logo.type === 'base64'
      ? `data:image/${logo.format};base64,${logo.image}`
      : logo.image

  const hasFailedSync = institution.accounts.some(acc => acc.holdingSyncStatus === 'Failed')

  const onRefreshButtonClick = useCallback(() => {
    const vendor = institution.accounts[0].vendor
    if (vendor === 'snaptrade') {
      onRefreshSnaptradeButtonClick()
    } else if(vendor === 'plaid') {
      onRefreshPlaidButtonClick()
    }else{
      toast({
        title: "Refresh connection failed",
        description: "We couldn’t refresh your connection. Please try unlinking this institution and linking it again.",
      })
    }
  }, [institution, onRefreshSnaptradeButtonClick, onRefreshPlaidButtonClick])

  return (
    <div className="p-6 rounded-lg border">
      <div className="flex items-center justify-between space-y-1.5 pb-6">
        <div className="flex items-center">
          <Avatar>
            <AvatarFallback>
              <Avatar className="h-10 w-10">
                <AvatarImage className="w-full self-center " src={logoUri} />
              </Avatar>
            </AvatarFallback>
          </Avatar>
          <h3 className="text-md font-medium ml-3">
            {institution.institutionName}
          </h3>
        </div>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" size="icon">
              <DotsVerticalIcon className="h-4 w-4" />
              <span className="sr-only">Open menu</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem onClick={onSyncAccountClick}>
              Sync Accounts
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              onClick={
                onRefreshButtonClick
              }
            >
              Refresh Connection
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              onClick={onRemoveButtonClick}
              className="text-red-600"
            >
              Unlink Institution
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      {hasFailedSync && (
        <Alert 
          variant="destructive" 
          className="mb-4 cursor-pointer hover:opacity-90 transition-opacity"
          onClick={onRefreshButtonClick}
        >
          <ExclamationTriangleIcon className="h-4 w-4" />
          <AlertTitle>Account syncing failed</AlertTitle>
          <AlertDescription>
            Tap here to refresh the connection.
          </AlertDescription>
        </Alert>
      )}
      <div className="space-y-4">
        {institution.accounts.map((acc) => (
          <AccountCard
            key={`${acc.accountId}-${acc.name}`}
            account={acc}
            onAccountUpdateSuccess={onAccountUpdateSuccess}
          />
        ))}
      </div>
      <SnapTradeReact
        loginLink={loginLink}
        isOpen={isSnaptradeOpen}
        close={onSnaptradeClose}
        onSuccess={isSnaptradeOpen ? onSnaptradeLinkSuccess : undefined}
      />
    </div>
  )
}
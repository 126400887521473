import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { signInSchema } from '@/auth/validation'
import { z } from 'zod'
import { useSignIn } from '@/auth/hooks/use-sign-in'

import { Link, useLocation, useNavigate } from 'react-router-dom'
import {
  CheckCircledIcon,
  ExclamationTriangleIcon,
} from '@radix-ui/react-icons'
import { Label } from '@/components/ui/label'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { useCallback } from 'react'

export const SignInForm = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const navState = z
    .object({
      passwordReset: z.boolean().nullish(),
      from: z.object({ pathname: z.string() }).nullish(),
    })
    .safeParse(location.state).data

  // this is needed to reroute users to the original route the user tried to
  // access.
  const from = navState?.from?.pathname || '/'
  const passwordResetSuccess = navState?.passwordReset

  const form = useForm<z.infer<typeof signInSchema>>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: zodResolver(signInSchema),
    mode: 'onBlur',
  })

  const handleAuthSuccess = useCallback(() => {
    navigate(from)
  }, [from])

  const { signIn, error } = useSignIn(handleAuthSuccess)

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(signIn)}>
        <div className="grid gap-4">
          <div className="grid gap-2">
            {passwordResetSuccess && (
              <Alert>
                <CheckCircledIcon className="h-4 w-4" />
                <AlertTitle className="mt-1">
                  Password reset succesfully
                </AlertTitle>
              </Alert>
            )}
            {error && (
              <Alert variant="destructive">
                <ExclamationTriangleIcon className="h-4 w-4" />
                <AlertTitle>Failed to sign in</AlertTitle>
                <AlertDescription>{error.message}</AlertDescription>
              </Alert>
            )}
          </div>
          <div className="grid gap-2">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="grid gap-2">
            <div className="flex items-center">
              <Label htmlFor="password">Password</Label>
              <Link
                to="/auth/forgot-password"
                className="ml-auto inline-block text-sm underline"
              >
                Forgot your password?
              </Link>
            </div>
            <Input
              id="password"
              type="password"
              required
              {...form.register('password')}
            />
          </div>
          <Button
            type="submit"
            formAction="submit"
            className="w-full"
            disabled={form.formState.isSubmitting}
            loading={form.formState.isSubmitting}
          >
            Login
          </Button>
        </div>
        <div className="mt-4 text-center text-sm">
          Don&apos;t have an account?{' '}
          <Link to="/auth/sign-up" className="underline">
            Sign up
          </Link>
        </div>
      </form>
    </Form>
  )
}

import { SettingsPage } from './pages/settings'
import { RequireAuth } from '@/auth/components/RequireAuth'

export const SettingsRouter = () => {
  return (
    <RequireAuth>
      <SettingsPage />
    </RequireAuth>
  )
}

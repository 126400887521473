import { z } from 'zod'
import { resetCodeRequestSchema, signInSchema } from '../validation'
import { useState } from 'react'
import { forgotPassword } from '../api'

interface ForgotPasswordResponse {
  AttributeName: string
  DeliveryMedium: string
  Destination: string
}

export const usePasswordResetCode = (onSuccess?: () => void) => {
  const [response, setResponse] = useState<ForgotPasswordResponse>()
  const [error, setError] = useState<Error>()
  const request = async (args: z.infer<typeof resetCodeRequestSchema>) => {
    setError(undefined)
    const res = await forgotPassword(args)
    if (res.error) {
      console.log(res.error)
      setError(res.error as Error)
    } else {
      setResponse(res.data as ForgotPasswordResponse)
      onSuccess && onSuccess()
    }
  }

  return { request, error, response }
}

import { useCallback, useState } from 'react'
import { useCreateSnaptradeConnectionUrlMutation } from '@/graphQL/generatedGraphql'

export const useSnaptradeLink = (
  onSaveConnectionSuccess: (authorizationId: string) => void,
) => {
  const [isSnaptradeOpen, setIsSnaptradeOpen] = useState(false)
  const [loginLink, setLoginLink] = useState('')

  const [createConnectionUrl] = useCreateSnaptradeConnectionUrlMutation({
    onCompleted: (data) => {
      if (data.portfolio?.snaptrade.createConnectionUrl?.redirectUri) {
        setLoginLink(data.portfolio.snaptrade.createConnectionUrl.redirectUri)
        setIsSnaptradeOpen(true)
      }
    },
  })

  const onSnaptradeLinkSuccess = useCallback(
    (authorizationId: string) => {
      setIsSnaptradeOpen(false)
      onSaveConnectionSuccess(authorizationId)
    },
    [setIsSnaptradeOpen, onSaveConnectionSuccess],
  )

  const onSnaptradeClose = () => setIsSnaptradeOpen(false)

  return {
    loginLink,
    isSnaptradeOpen,
    onSnaptradeClose,
    onSnaptradeLinkSuccess,
    createConnectionUrl,
  }
}

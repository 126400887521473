import { Card, CardContent, CardHeader } from '@/components/ui/card'
import { Button } from '@/components/ui/button'
import { UpdateIcon, CheckIcon } from '@radix-ui/react-icons'
import { Avatar, AvatarImage } from '@/components/ui/avatar'
import { useCallback, useEffect, useState } from 'react'
import {
  SnaptradeConnectionType,
  SupportedCountryCodes,
  useCreatePlaidLinkTokenMutation,
  useCreateSnaptradeConnectionUrlMutation,
  useSavePlaidLinkedAccountMutation,
  useSaveSnaptradeConnectionMutation,
  useSnaptradeBrokeragesQuery,
} from '@/graphQL/generatedGraphql'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
  DialogTrigger,
} from '@/components/ui/dialog'
import { SnapTradeReact } from 'snaptrade-react'
import { ScrollArea } from '@/components/ui/scroll-area'
import { useSnaptradeLink } from '../hooks/use-snaptrade-link'
import { usePlaidLink } from '../hooks/use-plaid-link'
import { PlaidLinkOnSuccessMetadata } from 'react-plaid-link'
import { useMediaQuery } from "@mantine/hooks"
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerDescription,
  DrawerTrigger,
} from "@/components/ui/drawer"
import { useToast } from "@/hooks/use-toast"
import { useCustomerInfo } from '@/purchases/hooks/use-customer-info'
import { ToastAction } from "@/components/ui/toast"
import { useNavigate } from 'react-router-dom'
import { UpgradePrompt } from './upgrade-prompt'

interface LinkInstitutionProps {
  slug: string
  squareLogoUrl: string | null | undefined
  logoUrl: string
  onBrokerageClick: (slug: string) => void
  displayName: string
  isDrawer: boolean  // New prop to differentiate between dialog and drawer
  isLast: boolean
}

const LinkInstitution = ({
  squareLogoUrl,
  logoUrl,
  slug,
  displayName,
  onBrokerageClick,
  isDrawer,
  isLast,
}: LinkInstitutionProps) => {
  const onClick = useCallback(() => {
    onBrokerageClick(slug)
  }, [onBrokerageClick])
  return (
    <DialogClose asChild>
      <div
        className={`flex items-center p-3 hover:cursor-pointer ${
          isDrawer
            ? `hover:bg-slate-100 ${isLast ? '' : 'border-b border-slate-200'}`
            : 'hover:border-slate-500 border border-slate-200 rounded-md mb-2'
        }`}
        onClick={onClick}
      >
        <div className="mr-4">
          <Avatar className="h-12 w-12 border-slate-200 rounded-full">
            <AvatarImage
              className="p-1 w-full rounded-full"
              src={squareLogoUrl ?? logoUrl}
            />
          </Avatar>
        </div>
        <div className="flex-grow">
          <p>{displayName}</p>
        </div>
      </div>
    </DialogClose>
  )
}

interface LinkAccountButtonProps {
  hasAccount: boolean
  onSaveConnectionSuccess: () => void
}

export const LinkAccountButton = ({
  hasAccount,
  onSaveConnectionSuccess,
}: LinkAccountButtonProps) => {
  const [open, setOpen] = useState(false)
  const [showUpgradeDialog, setShowUpgradeDialog] = useState(false)
  const isDesktop = useMediaQuery("(min-width: 768px)")
  const { toast } = useToast()
  const { data, loading } = useSnaptradeBrokeragesQuery({
    fetchPolicy: 'cache-first',
  })

  const { isZoyaProUser } = useCustomerInfo()
  const navigate = useNavigate()

  const handleButtonClick = () => {
    if (isZoyaProUser || !hasAccount) {
      setOpen(true)
    } else {
      setShowUpgradeDialog(true)
    }
  }

  const handleUpgradeClick = () => {
    setShowUpgradeDialog(false)
    navigate('/settings/subscription') // Adjust this path as needed
  }

  const [saveConnection] = useSaveSnaptradeConnectionMutation()

  const onLinkSuccess = useCallback(
    (authorizationId: string) => {
      const loadingToast = toast({
        title: "Linking account",
        description: "Please wait while we connect your account...",
        duration: Infinity, // Make the toast persist
      })

      saveConnection({
        variables: { brokerageAuthorizationId: authorizationId },
        onCompleted: () => {
          loadingToast.dismiss() // Dismiss the loading toast
          toast({
            title: "Account linked",
            description: "Your account has been successfully linked.",
          })
          onSaveConnectionSuccess()
        },
        onError: (error) => {
          loadingToast.dismiss() // Dismiss the loading toast
          toast({
            title: "Error",
            description: "Failed to link account. Please try again.",
            variant: "destructive",
          })
        },
      })
    },
    [saveConnection, toast, onSaveConnectionSuccess],
  )

  const {
    loginLink,
    isSnaptradeOpen,
    onSnaptradeClose,
    onSnaptradeLinkSuccess,
    createConnectionUrl,
  } = useSnaptradeLink(onLinkSuccess)

  const onBrokerageClick = useCallback(
    (slug: string) => {
      createConnectionUrl({
        variables: {
          input: {
            broker: slug,
            connectionType: SnaptradeConnectionType.Trade,
          },
        },
      })
    },
    [createConnectionUrl],
  )

  const [savePlaidLinkedAccountMutation] = useSavePlaidLinkedAccountMutation()

  const onPlaidLinkSuccess = useCallback(
    (public_token: string, metadata: PlaidLinkOnSuccessMetadata) => {
      const loadingToast = toast({
        title: "Linking account",
        description: "Please wait while we connect your account...",
        duration: Infinity, // Make the toast persist
      })

      savePlaidLinkedAccountMutation({
        variables: {
          publicToken: public_token,
          institution: {
            id: metadata.institution?.institution_id ?? '',
            name: metadata.institution?.name,
          },
          accounts: metadata.accounts.map((account) => ({
            id: account.id,
            name: account.name ?? '',
            type: account.type,
            subtype: String(account.subtype),
            mask: account.mask ?? '',
          })),
        },
        onCompleted: () => {
          loadingToast.dismiss() // Dismiss the loading toast
          toast({
            title: "Account linked",
            description: "Your account has been successfully linked.",
          })
          onSaveConnectionSuccess()
        },
        onError: (error) => {
          loadingToast.dismiss() // Dismiss the loading toast
          toast({
            title: "Error",
            description: "Failed to link account. Please try again.",
            variant: "destructive",
          })
        },
      })
    },
    [savePlaidLinkedAccountMutation, toast, onSaveConnectionSuccess],
  )

  const { createLinkTokenMutation } = usePlaidLink(onPlaidLinkSuccess)

  const onSearchMoreClick = useCallback(() => {
    createLinkTokenMutation({
      variables: {
        countryCodes: SupportedCountryCodes.Us,
      },
    })
  }, [createLinkTokenMutation])

  const renderContent = (isDrawer: boolean) => (
    <>
      <ScrollArea className="h-[400px] w-full">
        {loading ? (
          <section className="w-full flex justify-center content-center pt-12 pb-16 ">
            <div className="flex flex-row items-center">
              <UpdateIcon className="w-4 h-4 animate-spin mr-2 duration-1000" />
              Loading
            </div>
          </section>
        ) : null}
        <div className={isDrawer ? "px-4 flex justify-center" : ""}>
          <div className={`flex flex-col ${
            isDrawer ? 'w-full max-w-md border rounded-lg overflow-hidden' : ''
          }`}>
            {data?.portfolio?.snaptrade.brokerages.map((brokerage, index, array) => {
              return (
                <LinkInstitution
                  key={brokerage.slug}
                  slug={brokerage.slug}
                  logoUrl={brokerage.logoUrl}
                  squareLogoUrl={brokerage.squareLogoUrl}
                  displayName={brokerage.displayName}
                  onBrokerageClick={onBrokerageClick}
                  isDrawer={isDrawer}
                  isLast={index === array.length - 1}
                />
              )
            })}
          </div>
        </div>
      </ScrollArea>
    </>
  )

  const triggerButton = (
    <Button 
      className="w-full" 
      variant="default" 
      size="lg"
      onClick={handleButtonClick}
    >
      {hasAccount ? 'Add More Accounts' : 'Add Account'}
    </Button>
  )

  return (
    <>
      {triggerButton}
      {(isZoyaProUser || !hasAccount) && (
        <>
          {isDesktop ? (
            <Dialog open={open} onOpenChange={setOpen}>
              <DialogContent className="max-w-md w-full">
                <DialogHeader>
                  <DialogTitle>
                    {hasAccount ? 'Add More Accounts' : 'Add Account'}
                  </DialogTitle>
                  <DialogDescription>Select an institution to link your account</DialogDescription>
                </DialogHeader>
                {renderContent(false)}
                <DialogFooter>
                  <DialogClose asChild>
                    <Button variant="outline" className="w-full" onClick={onSearchMoreClick}>
                      Search for more institutions
                    </Button>
                  </DialogClose>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          ) : (
            <Drawer open={open} onOpenChange={setOpen}>
              <DrawerContent>
                <DrawerHeader>
                  <DrawerTitle>
                    {hasAccount ? 'Add More Accounts' : 'Add Account'}
                  </DrawerTitle>
                  <DrawerDescription className="text-lg">
                    Select an institution to link your account
                  </DrawerDescription>
                </DrawerHeader>
                {renderContent(true)}
                <DrawerFooter>
                  <DrawerClose asChild>
                    <Button variant="outline" onClick={onSearchMoreClick}>Search for more institutions</Button>
                  </DrawerClose>
                </DrawerFooter>
              </DrawerContent>
            </Drawer>
          )}
        </>
      )}
      <UpgradePrompt
        open={showUpgradeDialog}
        onOpenChange={setShowUpgradeDialog}
        title="Upgrade to Link Multiple Accounts"
        description="You need Zoya Pro to link more than one account. Upgrade now to unlock this feature and many more."
      />
      <SnapTradeReact
        loginLink={loginLink}
        isOpen={isSnaptradeOpen}
        close={onSnaptradeClose}
        onSuccess={isSnaptradeOpen ? onSnaptradeLinkSuccess : undefined}
      />
    </>
  )
}
import { UpdateIcon } from '@radix-ui/react-icons'
import { useCallback, useEffect, useState } from 'react'
import {
  PortfolioAccount,
  PortfolioAccountEdge,
  useGetPortfolioAccountsQuery,
  useGetPortfolioHoldingSyncStatusQuery,
  useSyncAllInstitutionsMutation,
} from '@/graphQL/generatedGraphql'
import { SnapTradeReact } from 'snaptrade-react'
import { LinkAccountButton } from './link-account-button'
import { InstitutionCard } from './institution-card'

const groupByGroupId = (accounts: PortfolioAccountEdge[]) => {
  const grouped: Record<string, PortfolioAccount[]> = accounts.reduce(
    (acc, edge) => {
      const groupId = edge.node.groupId
      if (!groupId) return acc

      if (!acc[groupId]) {
        acc[groupId] = []
      }
      acc[groupId].push(edge.node)

      return acc
    },
    {} as Record<string, PortfolioAccount[]>,
  )

  return Object.keys(grouped).map((institutionId) => ({
    accountId: grouped[institutionId][0].accountId ?? '',
    institutionName: grouped[institutionId][0].institution,
    accounts: grouped[institutionId],
  }))
}

export const LinkedAccountsSection = () => {
  const [syncAccountMutation] = useSyncAllInstitutionsMutation()
  const {
    data,
    refetch,
    loading: portfolioAccountLoading,
    fetchMore
  } = useGetPortfolioAccountsQuery({
    onCompleted: (resp) => {
        if(resp.portfolio?.accounts?.pageInfo.hasNextPage && resp.portfolio.accounts.pageInfo.endCursor){
          fetchMore({
            variables: {after: resp.portfolio.accounts.pageInfo.endCursor} })
        }
    } 
  })

  const { startPolling, stopPolling } = useGetPortfolioHoldingSyncStatusQuery({
    onCompleted: (resp) => {
      if (resp.portfolio?.account?.holdingSyncStatus === 'InProgress') {
        startPolling(500)
      } else {
        stopPolling()
      }
      refetch()
    },
    onError: () => {
      stopPolling()
    },
    fetchPolicy: 'network-only',
  })

  const institutions = groupByGroupId(
    data?.portfolio?.accounts?.edges?.filter(
      (acc) => acc.node.accountId != 'all',
    ) ?? [],
  )

  const onSyncAccountClick = useCallback(() => {
    syncAccountMutation({
      onCompleted: () => {
        startPolling(500)
      },
    })
  }, [syncAccountMutation, startPolling])

  const onSaveConnectionSuccess = useCallback(() => {
    refetch()
    startPolling(500)
  }, [startPolling])

  const onAccountUpdateSuccess = useCallback(() => {
    refetch()
  }, [refetch])

  const onRemoveSuccess = useCallback(() => {
    refetch()
  }, [refetch])

  return (
    <section className="w-full space-y-4">
      <h2 className="text-lg font-medium">Linked Accounts</h2>
      {portfolioAccountLoading ? (
        <div className="w-full flex justify-center content-center pt-12 pb-16">
          <div className="flex flex-row items-center">
            <UpdateIcon className="w-4 h-4 animate-spin mr-2 duration-1000" />
            Loading
          </div>
        </div>
      ) : institutions.length === 0 ? (
        <p className="text-muted-foreground">
          Link your brokerage accounts to start tracking your investments.
        </p>
      ) : (
        institutions.map((institution) => (
          <div key={institution.accountId} className="mb-4 last:mb-0">
            <InstitutionCard
              institution={institution}
              onSyncAccountClick={onSyncAccountClick}
              onRefreshSuccess={onSaveConnectionSuccess}
              onRemoveSuccess={onRemoveSuccess}
              onAccountUpdateSuccess={onAccountUpdateSuccess}
            />
          </div>
        ))
      )}

      <div className="pb-6">
        <LinkAccountButton
          hasAccount={institutions.length > 0}
          onSaveConnectionSuccess={onSaveConnectionSuccess}
        />
      </div>
    </section>
  )
}
import { Auth, Hub } from 'aws-amplify'
import { useEffect, useState } from 'react'

interface UserAttributes {
  sub: string
  email: string
  email_verified: boolean
  name: string
}

export const useCurrentUser = () => {
  const [user, setUser] = useState<UserAttributes | null>()

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((res) => {
        setUser(res?.attributes as UserAttributes)
        return res
      })
      .catch((e) => setUser(null))

    const removeListener = Hub.listen('auth', ({ payload }) => {
      switch (payload.event) {
        case 'signIn':
        case 'tokenRefresh':
          Auth.currentAuthenticatedUser()
            .then((res) => {
              setUser(res?.attributes as UserAttributes)
              return res
            })
            .catch((e) => setUser(null))
          break
        case 'signOut':
        case 'userDeleted':
        case 'signIn_failure':
        case 'autoSignIn_failure':
        case 'tokenRefresh_failure':
        case 'cognitoHostedUI_failure':
        case 'customState_failure':
          //For other auth events you can refer to (V5): https://docs.amplify.aws/javascript/build-a-backend/auth/auth-events/
          setUser(null)
      }
    })
    return removeListener
  }, [])

  return { user }
}

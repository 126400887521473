import { z } from 'zod'
import { userAttributesSchema } from '@/auth/validation'
import { useCallback, useLayoutEffect, useState } from 'react'
import { updateUserAttributes, getUserAttributes } from '@/auth/api'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { format, addDays } from 'date-fns'

/**

 */
export const useProfileSettings = (onSuccess?: () => void) => {
  const [error, setError] = useState<Error>()
  const [attributesLoading, setAttributesLoading] = useState<Boolean>(false)
  const [userAttributes, setUserAttributes] = useState<
    Record<string, string | undefined>
  >({})

  const form = useForm<z.infer<typeof userAttributesSchema>>({
    defaultValues: {
      given_name: userAttributes.given_name ?? '',
      family_name: userAttributes.family_name ?? '',
      birthdate: userAttributes.birthdate ?? '',
    },
    resolver: zodResolver(userAttributesSchema),
    mode: 'onBlur',
  })

  const fetchUserAttributes = useCallback(async () => {
    setAttributesLoading(true)
    const response = await getUserAttributes()
    if (response.data) {
      setUserAttributes(response.data)
      form.setValue('given_name', response.data.given_name ?? '')
      form.setValue('family_name', response.data.family_name ?? '')
      // Adjust the date to account for timezone differences
      if (response.data.birthdate) {
        const date = new Date(response.data.birthdate)
        form.setValue('birthdate', format(addDays(date, 1), 'yyyy-MM-dd'))
      } else {
        form.setValue('birthdate', '')
      }
    }
    setAttributesLoading(false)
  }, [])

  useLayoutEffect(() => {
    fetchUserAttributes()
  }, [])

  const onSubmit = form.handleSubmit(
    async (attributes: z.infer<typeof userAttributesSchema>) => {
      setError(undefined)
      const res = await updateUserAttributes({ attributes })
      if (res.error) {
        setError(res.error as Error)
      } else {
        fetchUserAttributes()
        form.reset()
        onSuccess && onSuccess()
      }
    },
  )

  return {
    userAttributes,
    form,
    onSubmit,
    error,
    loading: attributesLoading,
    submitting: form.formState.isSubmitting,
  }
}

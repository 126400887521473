import { Alert, AlertTitle, AlertDescription } from '@/components/ui/alert'
import { Button } from '@/components/ui/button'
import {
  CheckCircledIcon,
  ExclamationTriangleIcon,
  InfoCircledIcon,
  UpdateIcon,
} from '@radix-ui/react-icons'
import { useEmailVerification } from '../hooks/use-email-verification'
import { useLocation } from 'react-router-dom'
import { z } from 'zod'
import { ZoyaLogo } from '@/common/components/zoya-logo'

export const EmailVerification = () => {
  const location = useLocation()
  const navState = z
    .object({
      email: z.string().email(),
      password: z.string(),
    })
    .safeParse(location.state).data

  const {
    resendVerificationDisabled,
    resendVerificationVisible,
    resendVerificationError,
    verifcationDone,
    resendVerificationEmail,
  } = useEmailVerification({
    email: navState?.email,
    password: navState?.password,
  })

  return (
    <div className="w-full max-h-screen h-screen	 lg:grid lg:min-h-[600px] lg:grid-cols-2 xl:min-h-[800px]">
      <div className="flex items-center justify-center py-12">
        <div className="h-full mx-auto grid grid-cols-1 grid-rows-3 w-[350px] gap-6 ">
          <div className="block mb-12 ">
            <ZoyaLogo className="w-28 lg:hidden" />
          </div>
          {!verifcationDone ? (
            <div className="grid gap-2 auto-rows-auto text-center">
              <div className="flex justify-center ">
                <UpdateIcon className=" w-10 h-10 animate-spin duration-2000" />
              </div>
              <h1 className="text-3xl font-bold">Email verification</h1>
              <p className="text-balance text-muted-foreground">
                We have sent a verification code to your email address. Please
                check your inbox and click on the link in order to verify your
                account
              </p>

              {resendVerificationVisible && (
                <Button
                  variant="outline"
                  disabled={resendVerificationDisabled}
                  onClick={resendVerificationEmail}
                >
                  Resend verification Email
                </Button>
              )}
              {resendVerificationError && (
                <Alert variant="destructive">
                  <ExclamationTriangleIcon className="h-4 w-4" />
                  <AlertTitle className="mt-1">
                    Failed to resend the verification email
                  </AlertTitle>
                </Alert>
              )}
            </div>
          ) : (
            <div className="flex-col justify-center content-center  text-center ">
              <div className="flex justify-center h-16 ">
                <CheckCircledIcon className=" w-14 h-14 " />
              </div>
              <h1 className="text-3xl font-bold">Email Verified</h1>
            </div>
          )}
          {!verifcationDone && (
            <Alert className="self-end bg-background-20">
              <InfoCircledIcon className="h-4 w-4" />
              <AlertTitle>Heads up!</AlertTitle>
              <AlertDescription>
                It may take a minute for the confirmation email to reach your
                inbox. If you are unable to find it, please check your spam/junk
                folder.
              </AlertDescription>
            </Alert>
          )}
        </div>
      </div>
      <div className="hidden lg:block  ">
        <div className="h-[98%] bg-jungle dark:bg-background flex items-center justify-center py-12 m-2 rounded-2xl ">
          <img src="/zoya-logo-dark.svg" className="w-36" />
        </div>
      </div>
    </div>
  )
}

import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { useTypesenseSearch } from '@/data/hooks/useTypesenseSearch'
import { ZakatHolding } from '@/zakat/types'
import { zodResolver } from '@hookform/resolvers/zod'
import React, { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useConfig } from 'statsig-react'
import { z } from 'zod'
import { isString, isEmpty, noop } from 'lodash'
import { SearchResponseHit } from 'typesense/lib/Typesense/Documents'
import { InputWithOptions } from '@/common/components/searchable-select'

const TYPESENSE_HOST = import.meta.env.VITE_TYPESENSE_HOST
const TYPESENSE_KEY = import.meta.env.VITE_TYPESENSE_KEY
const TYPESENSE_COLLECTION = import.meta.env.VITE_TYPESENSE_COLLECTION

export const isNonEmptyString = (value: unknown): value is string => {
  return isString(value) && !isEmpty(value)
}

interface AddHoldingModalProps {
  trigger: React.ReactNode
  addHolding: (newHolding: Partial<ZakatHolding>) => void
}

const AddHoldingSchema = z
  .object({
    symbol: z.string().min(1, "Symbol can't be empty"),
    name: z.string().optional(),
    quantity: z.string().min(1, 'Amount must be greater than 0'),
    strategy: z.union([z.literal('PASSIVE'), z.literal('ACTIVE')]),
  })
  .transform((_) => ({
    ..._,
    quantity: Number(_.quantity),
  }))

export const AddHoldingModal: React.FC<AddHoldingModalProps> = ({
  trigger,
  addHolding,
}) => {
  const form = useForm<z.infer<typeof AddHoldingSchema>>({
    defaultValues: {
      symbol: '',
      name: '',
      quantity: 0,
      strategy: 'PASSIVE',
    },
    resolver: zodResolver(AddHoldingSchema),
    mode: 'onBlur',
  })

  const { config } = useConfig('search_config')

  const typesenseOptions = useMemo(() => {
    return {
      host: config.get('host', TYPESENSE_HOST, isNonEmptyString),
      apiKey: config.get(
        'zakatOverrideApiKey',
        config.get('apiKey', TYPESENSE_KEY, isNonEmptyString),
        isNonEmptyString,
      ),
      collection: config.get(
        'zakatOverrideCollection',
        config.get('collection', TYPESENSE_COLLECTION, isNonEmptyString),
        isNonEmptyString,
      ),
    }
  }, [config])

  const [symbolSearchResults, setSymbolSearchResults] = useState<
    {
      title: string
      description: string
      value: string
    }[]
  >([])

  const onSearchSuccess = useCallback(
    (newResults: { symbol: string; name: string }[]) => {
      // console.table(newResults)
      setSymbolSearchResults(
        newResults.map((_) => ({
          value: _.symbol,
          title: _.symbol,
          description: _.name,
        })),
      )
    },
    [],
  )

  const parseResults = useCallback(
    (result: SearchResponseHit<Record<string, unknown>>[]) => {
      return result.map((_) => {
        return _.document as { symbol: string; name: string }
      })
    },
    [],
  )

  useTypesenseSearch<{ symbol: string; name: string }>({
    query: form.watch('symbol'),
    pageSize: 10,
    debounceWait: 250,
    options: typesenseOptions,
    onSearchSuccess,
    onSearchError: noop,
    parseResults,
  })

  const onOpenChange = useCallback(
    (isOpen: boolean) => isOpen && form.reset(),
    [],
  )

  return (
    <Dialog onOpenChange={onOpenChange}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Add Holding</DialogTitle>
          <DialogDescription>
            Stocks and funds available in the US, UK, and Canada can be searched
            below. For international symbols, please use the import CSV feature
            to add them to your holdings.
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(addHolding)} autoComplete="off">
            <div className="flex-1 flex-col items-center space-y-4 ">
              <FormField
                control={form.control}
                name="symbol"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{field.name}</FormLabel>
                    <FormControl>
                      <InputWithOptions
                        inputProps={field}
                        options={symbolSearchResults}
                        onOptionSelected={(option) => {
                          form.setValue('name', option.description)
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="quantity"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Shares</FormLabel>
                    <FormControl>
                      <Input type="number" {...field} />
                    </FormControl>
                    <FormMessage />
                    <FormDescription>number of shares you hold</FormDescription>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="strategy"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Type</FormLabel>
                    <FormControl>
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue defaultValue="PASSIVE" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="PASSIVE">
                            Long-term (Passive)
                          </SelectItem>
                          <SelectItem value="ACTIVE">
                            Short-term (Active)
                          </SelectItem>
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                    <FormDescription>
                      select whether your intention is to hold them long-term{' '}
                      <br />
                      (&gt; 12 months) or short-term (&lt; 12 months).
                    </FormDescription>
                  </FormItem>
                )}
              />
            </div>

            <DialogFooter className="sm:justify-start sm:mt-6">
              <DialogClose asChild>
                <Button type="button" variant="secondary">
                  Close
                </Button>
              </DialogClose>
              <DialogClose>
                <Button type="submit" formAction="submit" variant="default">
                  Add
                </Button>
              </DialogClose>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

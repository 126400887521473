import { Routes, Route, Navigate } from 'react-router-dom'
import { SignIn } from './pages/sign-in'
import { SignUp } from './pages/sign-up'
import { EmailVerification } from './pages/email-verification'
import { ForgotPassword } from './pages/forgot-password'
import { PasswordReset } from './pages/password-reset'
import { useCurrentUser } from './hooks/use-current-user'

export const AuthRouter = () => {
  const { user } = useCurrentUser()

  if (user) {
    return <Navigate to="/" />
  }

  return (
    <Routes>
      <Route path="log-in" element={<SignIn />} />
      <Route path="sign-up" element={<SignUp />} />
      <Route path="email-verification" element={<EmailVerification />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="password-reset" element={<PasswordReset />} />
      <Route path="*" element={<Navigate to="log-in" />} />
    </Routes>
  )
}

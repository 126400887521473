import { z } from 'zod'
import { signUpSchema } from '../validation'
import { useState } from 'react'
import { signUp } from '../api'

export const useSignUp = (onSuccess?: () => void) => {
  const [error, setError] = useState<Error>()
  const handler = async (args: z.infer<typeof signUpSchema>) => {
    setError(undefined)
    const res = await signUp(args)
    if (res.error) {
      setError(res.error as Error)
    } else {
      onSuccess && onSuccess()
    }
  }

  return { signUp: handler, error }
}

import { CustomerInfo, Purchases } from '@revenuecat/purchases-js'
import { useCallback, useEffect, useState } from 'react'
import { configurePurchases } from '../configure'
import { useCurrentUser } from '@/auth/hooks/use-current-user'

const isZoyaProUser = (info?: CustomerInfo | null) => {
  return !!info?.entitlements?.active?.['Zoya Pro']?.isActive
}

export const useCustomerInfo = () => {
  const [info, setInfo] = useState<CustomerInfo | null>()
  const { user } = useCurrentUser()

  const refetch = useCallback(async () => {
    if (!user) {
      return
    }
    if (!Purchases.isConfigured()) {
      configurePurchases(user?.sub)
    }
    try {
      setInfo(undefined)
      const res = await Purchases.getSharedInstance().getCustomerInfo()
      setInfo(res)
    } catch (error) {
      // FIXME handle error
      setInfo(null)
    }
  }, [user])

  useEffect(() => {
    let mounted = true
    if (!user) {
      return
    }

    if (!Purchases.isConfigured()) {
      configurePurchases(user.sub)
    }
    Purchases.getSharedInstance()
      .getCustomerInfo()
      .then((res) => {
        mounted && setInfo(res)
      })
      .catch((err) => {
        console.log(err)
        mounted && setInfo(null)
      })

    return () => {
      mounted = false
    }
  }, [user])

  return {
    info,
    isZoyaProUser: isZoyaProUser(info),
    loading: info === undefined,
    refetch,
  }
}

import React from 'react'
import { CheckIcon } from '@radix-ui/react-icons'
import { useMediaQuery } from "@mantine/hooks"
import { UpgradeButton } from './upgrade-button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from '@/components/ui/dialog'
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerDescription,
  DrawerFooter,
} from "@/components/ui/drawer"

interface UpgradePromptProps {
  open: boolean
  onOpenChange: (open: boolean) => void
  title?: string
  description?: string
  buttonText?: string
}

export const UpgradePrompt: React.FC<UpgradePromptProps> = ({
  open,
  onOpenChange,
  title = "Upgrade to Zoya Pro",
  description = "Upgrade now to unlock this feature and many more.",
  buttonText = "Try Free and Subscribe"
}) => {
  const isDesktop = useMediaQuery("(min-width: 768px)")

  const content = (
    <div className="flex flex-col items-center w-full">
      <ul className="space-y-3 mb-6 w-full">
        <li className="flex items-center text-sm">
          <CheckIcon className="h-5 w-5 mr-1 flex-shrink-0 text-proGold" />
          Trusted by thousands of members
        </li>
        <li className="flex items-center text-sm">
          <CheckIcon className="h-5 w-5 mr-1 flex-shrink-0 text-proGold" />
          Start with a free 7-day trial
        </li>
        <li className="flex items-center text-sm">
          <CheckIcon className="h-5 w-5 mr-1 flex-shrink-0 text-proGold" />
          Cancel at any time
        </li>
      </ul>
      <UpgradeButton className="w-full">
        {buttonText}
      </UpgradeButton>
      <p className="text-sm text-gray-500 text-center mt-2">Instant access to all features</p>
    </div>
  )

  if (isDesktop) {
    return (
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="max-w-md">
          <DialogHeader className="space-y-4">
            <DialogTitle className="text-proGold">{title}</DialogTitle>
            <DialogDescription>
              {description}
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            {content}
          </DialogFooter>
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <Drawer open={open} onOpenChange={onOpenChange}>
      <DrawerContent className="pb-6">
        <DrawerHeader className="space-y-2 pb-2">
          <DrawerTitle className="text-proGold">{title}</DrawerTitle>
          <DrawerDescription>
            {description}
          </DrawerDescription>
        </DrawerHeader>
        <DrawerFooter className="pt-2">
          {content}
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
import { useCallback, useEffect, useState } from 'react'
import {
  SupportedCountryCodes,
  useCreatePlaidLinkTokenMutation,
  useCreateSnaptradeConnectionUrlMutation,
} from '@/graphQL/generatedGraphql'
import {
  PlaidLinkOnSuccessMetadata,
  usePlaidLink as useReactPlaidLink,
} from 'react-plaid-link'

export const usePlaidLink = (
  onSaveConnectionSuccess: (
    public_token: string,
    metadata: PlaidLinkOnSuccessMetadata,
  ) => void,
) => {
  const [linkToken, setLinkToken] = useState('')

  const { open, ready } = useReactPlaidLink({
    token: linkToken,
    onSuccess: onSaveConnectionSuccess,
    onExit: () => {
      setLinkToken('')
    },
  })

  const [createLinkTokenMutation] = useCreatePlaidLinkTokenMutation({
    onCompleted: (response) => {
      setLinkToken(response.plaid?.createLinkToken.linkToken ?? '')
    },
  })

  useEffect(() => {
    if (ready) {
      open()
    }
  }, [ready, open])

  return {
    createLinkTokenMutation,
    isPlaidReady: ready,
    openPlaid: open,
  }
}

import { initAmplify } from '@/amplify'
import { initSegment } from '@/events/segment'
import { generateClient } from '@/graphQL'
import { ApolloProvider } from '@apollo/client'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { StatsigProvider } from 'statsig-react'
import { statsigOptions, statsigUserBaseConfig } from '@/events/statsig'
import { BugSnagErrorBoundary } from './Bugsnag'
import { noop } from 'lodash'
import { RoutesListener } from './RoutesListener'
import { AuthEventsListener } from '@/auth/components/AuthEventsListener'
import { merge } from 'lodash'
import { Toaster } from '@/components/ui/toaster'
import { ToastProvider } from '@/components/ui/toast'

import { useStore } from '@/store'
import { useMemo } from 'react'

// Routers
import { AuthRouter } from '@/auth/router'
import { SettingsRouter } from '@/settings/router'
import { ZakatRouter } from '@/zakat/router'
import { ThemeProvider } from '@/common/components/theme-provider'
import { ShortcutsListener } from '@/common/components/shortcuts-listener'

initAmplify()
initSegment().catch(noop)
const client = generateClient()

export const App = () => {
  const user = useStore((_) => _.authenticatedUser)
  const statsigUser = useMemo(() => {
    return merge({}, statsigUserBaseConfig, {
      email: user?.email,
      userID: user?.sub,
    })
  }, [user?.email, user?.sub])

  return (
    <BugSnagErrorBoundary>
      <StatsigProvider
        user={statsigUser}
        options={statsigOptions}
        sdkKey="client-5KBiBaFfdhKQkV9HJdVStSw7cOKJ5zyiUj9OUFVgMCI"
        waitForInitialization={false}
      >
        <ThemeProvider>
          <ToastProvider>
            <ApolloProvider client={client}>
              <BrowserRouter>
                <AuthEventsListener />
                <ShortcutsListener />
                <RoutesListener>
                  <Routes>
                    <Route path="/auth/*" element={<AuthRouter />} />
                    <Route path="/zakat/*" element={<ZakatRouter />} />
                    <Route path="/settings/*" element={<SettingsRouter />} />
                    <Route path="/" element={<Navigate to="/settings" replace />} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                  </Routes>
                </RoutesListener>
              </BrowserRouter>
            </ApolloProvider>
            <Toaster />
          </ToastProvider>
        </ThemeProvider>
      </StatsigProvider>
    </BugSnagErrorBoundary>
  )
}

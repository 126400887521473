import { buttonVariants } from '@/components/ui/button'
import { cn } from '@/lib/utils'
import { Link, useLocation } from 'react-router-dom'

interface SidebarNavProps extends React.HTMLAttributes<HTMLElement> {
  items: {
    href: string
    title: string
  }[]
}

export function SidebarNav({ className, items, ...props }: SidebarNavProps) {
  const { pathname } = useLocation()

  return (
    <nav
      className={cn(
        'flex flex-row space-x-3 space-y-0 sm:space-y-0 sm:space-x-3 md:flex-row md:space-x-3 lg:flex-col lg:space-x-0', 
        className,
      )}
      {...props}
    >
      {items.map((item) => (
        <Link
          key={item.href}
          to={item.href}
          className={cn(
            buttonVariants({ variant: 'ghost' }),
            pathname === item.href
              ? 'text-[var(--zoya-secondary-kelly)] hover:text-[var(--zoya-secondary-kelly)]' // Active link color
              : 'text-default-color hover:text-default-color', // Inactive link color
            'hover:bg-transparent justify-start pl-0', 
          )}
        >
          {item.title}
        </Link>
      ))}
    </nav>
  )
}

import { z } from 'zod'
import { resetPasswordSchema } from '../validation'
import { useState } from 'react'
import { resetPassword } from '../api'

export const useResetPassword = (onSuccess?: () => void) => {
  const [error, setError] = useState<Error>()
  const request = async (
    args: z.infer<typeof resetPasswordSchema> & { email: string },
  ) => {
    setError(undefined)
    const res = await resetPassword(args)
    if (res.error) {
      setError(res.error as Error)
    } else {
      onSuccess && onSuccess()
    }
  }

  return { request, error }
}

import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Button } from '@/components/ui/button'
import { Pencil1Icon } from '@radix-ui/react-icons'
import {
  differenceInMonths,
  format,
  formatDistanceToNow,
  isToday,
  isValid,
  isYesterday,
} from 'date-fns'
import { useCallback, useState } from 'react'
import {
  PortfolioAccount,
  useUpdatePortfolioAccountMutation,
} from '@/graphQL/generatedGraphql'
import { NumberFormat } from '@/common/numberFormat'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useForm } from 'react-hook-form'
import { startCase, toLower } from 'lodash'
import { useMediaQuery } from "@mantine/hooks"
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerDescription,
  DrawerFooter,
} from "@/components/ui/drawer"

// Helper functions
const formatLastSyncTime = (lastSyncTime: string | null | undefined): string => {
  if (!lastSyncTime) return ''
  const date = new Date(lastSyncTime)
  if (!isValid(date)) {
    return ''
  }
  return formatDistanceToNow(date, { addSuffix: true })
}

const getAccountType = (account: PortfolioAccount): string => {
  if (account.aggregator === 'snaptrade') {
    return account.type?.toLowerCase().includes('crypto') ? 'Crypto' : 'Investment'
  }
  return sentenceCase(account.type || '')
}

const lastSyncTimeFormatted = (lastSyncTime: string) => {
  const date = new Date(lastSyncTime)
  if (!isValid(date)) {
    return ''
  }
  return formatDistanceToNow(new Date(lastSyncTime), { addSuffix: true })
}

const renameAccountSchema = z.object({
  name: z.string().min(5).trim(),
})

const sentenceCase = (str: string): string => startCase(toLower(str))

interface AccountCardProps {
  account: PortfolioAccount
  onAccountUpdateSuccess: () => void
}
export const AccountCard = ({
  account,
  onAccountUpdateSuccess,
}: AccountCardProps) => {
  const [updateAccountName, { loading }] = useUpdatePortfolioAccountMutation()

  const formatter = new NumberFormat({
    currency: account.currencyCode ?? 'USD',
  })

  const [showRenameDialog, setShowRenameDialog] = useState(false)
  const [accountId, setAccountId] = useState('')
  const isDesktop = useMediaQuery("(min-width: 768px)")

  const form = useForm<z.infer<typeof renameAccountSchema>>({
    defaultValues: {
      name: '',
    },
    resolver: zodResolver(renameAccountSchema),
    mode: 'onBlur',
  })

  const onAccountNameClick = useCallback(() => {
    if (account.accountId) {
      setAccountId(account.accountId)
      form.setValue('name', account.nickname ?? account.name ?? '')
      setShowRenameDialog(true)
    }
  }, [account])

  const onFormSubmit = useCallback(
    (data: { name: string }) => {
      updateAccountName({
        variables: { nickname: data.name, accountId: accountId },
      })
        .catch((e) => console.log(e))
        .finally(() => {
          onAccountUpdateSuccess()
          setShowRenameDialog(false)
        })
    },
    [accountId, setShowRenameDialog, onAccountUpdateSuccess],
  )

  const renderRenameForm = () => (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onFormSubmit)}>
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Account Name</FormLabel>
              <FormControl>
                <Input required {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button
          type="submit"
          formAction="submit"
          className="w-full mt-4"
          disabled={form.formState.isSubmitting}
          loading={loading}
        >
          Submit
        </Button>
      </form>
    </Form>
  )

  const renderAccountStatus = () => {
    if (account.holdingSyncStatus === 'Failed') return 'Sync failed'
    if (account.holdingSyncStatus === 'InProgress') {
      return (
        <>
          Syncing
          <div className="loading-dots">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
        </>
      )
    }
    return formatLastSyncTime(account.lastSyncTime)
  }

  return (
    <div className={`p-3 bg-gray-50 dark:bg-black/10 rounded-md ${account.holdingSyncStatus === 'Failed' ? 'border border-red-500' : ''}`}>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <span className="text-sm font-medium">
            {account.nickname ?? account.name}
          </span>
          <Button variant="ghost" size="icon" className="rounded-full" onClick={onAccountNameClick}>
            <Pencil1Icon className="h-4 w-4" />
          </Button>
        </div>
        <span className="text-sm">
          {getAccountType(account)}
        </span>
      </div>
      <div className="mt-1 flex items-center justify-between">
        <span className="text-sm">
          {renderAccountStatus()}
        </span>
        <span className="text-sm">
          {account.balance != null ? formatter.moneyFormatter(account.balance) : 'N/A'}
        </span>
      </div>
      {isDesktop ? (
        <Dialog open={showRenameDialog} onOpenChange={setShowRenameDialog}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Rename your account</DialogTitle>
            </DialogHeader>
            {renderRenameForm()}
          </DialogContent>
        </Dialog>
      ) : (
        <Drawer open={showRenameDialog} onOpenChange={setShowRenameDialog}>
          <DrawerContent>
            <DrawerHeader>
              <DrawerTitle>Rename your account</DrawerTitle>
              <DrawerDescription>
                Enter a new name for your account below.
              </DrawerDescription>
            </DrawerHeader>
            <div className="px-4 pb-6">
              {renderRenameForm()}
            </div>
          </DrawerContent>
        </Drawer>
      )}
    </div>
  )
}
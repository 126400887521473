import { relayStylePagination } from '@apollo/client/utilities'
import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { createAuthLink } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'
import { Auth } from 'aws-amplify'
import { ok } from 'assert'

const {VITE_GRAPHQL_ENDPOINT} = import.meta.env

ok(VITE_GRAPHQL_ENDPOINT, 'GraphQl API endpoint is missing')

export const generateClient = () => {
  const config = {
    url: VITE_GRAPHQL_ENDPOINT,
    region: 'us-east-2',
    auth: {
      type: 'AMAZON_COGNITO_USER_POOLS',
      // Get the currently logged in users credential.
      jwtToken: async () =>
        (await Auth.currentSession()).getIdToken().getJwtToken(),
    },
    disableOffline: true,
  } as const

  const zoyaUserAgent = 'ZoyaWeb/*'

  const customHeaderLink = setContext((operation, previousContext) => {
    const headers: object =
      previousContext instanceof Object &&
      previousContext.headers &&
      previousContext.headers instanceof Object
        ? previousContext.headers
        : undefined
    return {
      ...previousContext,
      headers: {
        ...headers,
        'zoya-user-agent': zoyaUserAgent,
      },
    }
  })

  const link = ApolloLink.from([
    customHeaderLink,
    createAuthLink(config) as unknown as ApolloLink,
    createSubscriptionHandshakeLink(config),
  ])

  const client = new ApolloClient({
    link,
    cache: new InMemoryCache({
      addTypename: true,
      typePolicies: {
        Portfolio: {
          merge: true,
          fields: {
            holdings: relayStylePagination(),
            institutions: relayStylePagination(),
            accounts: relayStylePagination()
          },
        },
        Invest: {
          merge: true,
        },
      },
    }),
  })

  return client
}

import { Offerings, Purchases } from '@revenuecat/purchases-js'
import { useCallback, useEffect, useState } from 'react'
import { configurePurchases } from '../configure'
import { useCurrentUser } from '@/auth/hooks/use-current-user'

export const useOfferings = () => {
  const [offerings, setOfferings] = useState<Offerings | null>()
  const { user } = useCurrentUser()

  const refetch = useCallback(async () => {
    if (!user) {
      return
    }
    if (!Purchases.isConfigured()) {
      configurePurchases(user?.sub)
    }
    try {
      setOfferings(undefined)
      const res = await Purchases.getSharedInstance().getOfferings()
      setOfferings(res)
    } catch (error) {
      // FIXME handle error
      setOfferings(null)
    }
  }, [user])

  useEffect(() => {
    let mounted = true
    if (!user) {
      return
    }

    if (!Purchases.isConfigured()) {
      configurePurchases(user.sub)
    }
    Purchases.getSharedInstance()
      .getOfferings()
      .then((res) => {
        mounted && setOfferings(res)
      })
      .catch((err) => {
        // FIXME handle error
        mounted && setOfferings(null)
      })

    return () => {
      mounted = false
    }
  }, [user])

  return { allOfferings: offerings, loading: offerings === undefined, refetch }
}

import { Button } from '@/components/ui/button'
import { DataTable } from '@/components/ui/data-table'
import { Skeleton } from '@/components/ui/skeleton'
import { ZakatHolding } from '@/zakat/types'
import { Cross2Icon } from '@radix-ui/react-icons'
import { ColumnDef } from '@tanstack/react-table'

export const HoldingsTable: React.FC<{
  holdings: Partial<ZakatHolding>[]
  emptyContent?: React.ReactNode
  removeHolding: (symbol?: string, strategy?: string) => void
}> = ({ holdings, emptyContent, removeHolding }) => {
  const columnsDef: ColumnDef<Partial<ZakatHolding>>[] = [
    {
      accessorKey: 'name',
      header: 'Holding',
      cell: ({ row }) => {
        return (
          <div>
            <h4 className="font-semibold">{row.original.symbol}</h4>
            {row.original.dataFetch?.error ? (
              <p className="font-normal text-xs text-destructive">
                This Symbol is currently not supported
              </p>
            ) : (
              <p className="font-normal text-xs">{row.original.name}</p>
            )}
          </div>
        )
      },
    },
    {
      accessorKey: 'type',
      header: 'Type',
      cell: ({ row }) => {
        return (
          <p className="text-sm ">
            {row.original.strategy === 'ACTIVE' ? 'Short-term' : 'Long-term'}
          </p>
        )
      },
    },
    {
      accessorKey: 'quantity',
      header: 'Shares',
    },
    {
      accessorKey: 'unitPrice',
      header: 'Current Price',

      cell: ({ row }) => {
        if (!row.original.dataFetch?.finished) {
          return <Skeleton className="w-[65px] h-[20px] rounded-lg" />
        }
        if (row.original.dataFetch?.error) {
          return <p>–</p>
        }
        if (!row.original.unitPrice) {
          return null
        }
        const formatted = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: row.original.currency,
        }).format(row.original.unitPrice)
        return <p>{formatted}</p>
      },
    },
    {
      header: 'Value',
      cell: ({ row }) => {
        if (!row.original.dataFetch?.finished) {
          return <Skeleton className="w-[75px] h-[20px] rounded-lg" />
        }
        if (row.original.dataFetch?.error) {
          return <p>–</p>
        }
        if (!row.original.unitPrice || !row.original.quantity) {
          return null
        }
        const formatted = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: row.original.currency,
        }).format(row.original.unitPrice * row.original.quantity)
        return <p>{formatted}</p>
      },
    },
    {
      header: 'Actions',
      cell: ({ row }) => (
        <Button
          variant="ghost"
          onClick={(e) =>
            removeHolding(row.original.symbol, row.original.strategy)
          }
        >
          <Cross2Icon height={20} width={20} color="black" />
        </Button>
      ),
    },
  ]

  return (
    <DataTable
      columns={columnsDef}
      data={holdings}
      emptyContent={emptyContent}
    />
  )
}

import { useCallback, useEffect, useRef, useState } from 'react'
import { resendConfirmationEmail, signIn } from '../api'
import { noop } from 'lodash'
import { useInterval } from 'usehooks-ts'

const isUserVerified = (
  email: string,
  password: string,
  onVerified: () => void,
) => {
  signIn({ email, password })
    .then((res) => {
      if (!res.error && res.data) {
        onVerified()
      }
    })
    .catch(noop)
}

export const useEmailVerification = (args: {
  email?: string
  password?: string
}) => {
  const { email, password } = args

  const [resendVerification, setResendVerification] = useState<{
    visible: boolean
    disabled: boolean
    error?: string
  }>({ visible: false, disabled: false })
  const [verifcationDone, setVerifcationDone] = useState<boolean>(false)

  useEffect(() => {
    const resendVerificationVisibleLong = setTimeout(
      () => setResendVerification({ visible: true, disabled: false }),
      20000,
    )
    return () => {
      clearTimeout(resendVerificationVisibleLong)
    }
  }, [])

  const intervals = [15, 15, 15, 15, 30, 30, 60]
  const intervalIndex = useRef(0)
  const [interval, setInterval] = useState<number | null>(
    intervals[intervalIndex.current] * 1000,
  )

  useInterval(() => {
    if (email && password) {
      isUserVerified(email, password, () => {
        setVerifcationDone(true)
        setInterval(null)
      })
      intervalIndex.current += 1
      setInterval(
        intervals[intervalIndex.current]
          ? intervals[intervalIndex.current] * 1000
          : null,
      )
    }
  }, interval)

  const resendVerificationEmail = useCallback(() => {
    if (email && password) {
      setResendVerification({ visible: true, disabled: true })
      resendConfirmationEmail(email).catch((error) =>
        setResendVerification((prevState) => ({
          ...prevState,
          error: error.message,
        })),
      )
      setTimeout(() => {
        setResendVerification({ visible: true, disabled: false })
      }, 45000)
    }
  }, [email])

  return {
    resendVerificationEmail,
    resendVerificationVisible: resendVerification.visible,
    resendVerificationDisabled: resendVerification.disabled,
    resendVerificationError: resendVerification.error,
    verifcationDone,
  }
}

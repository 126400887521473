import { Auth } from 'aws-amplify'
import type { ISignUpResult } from 'amazon-cognito-identity-js'
import { debounce } from 'lodash'
import { format } from 'date-fns'

export interface User {
  name: string
  email: string
  email_verified: boolean
  sub: string
  identities?: Record<string, unknown>
}

interface ApiResponse<T = unknown> {
  data?: T | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: Error | unknown | null | undefined
}

interface UserData {
  user: ISignUpResult
  email: string
}

interface SignUpResponse {
  data: UserData | null
  error: (Error & { code: string }) | null
}

export const signIn = async (params: {
  email: string
  password: string
}): Promise<ApiResponse<User>> => {
  const username = params.email.replace('@', '').trim()
  try {
    const user = (await Auth.signIn(username, params.password.trim())) as User
    return { data: user, error: null }
  } catch (error) {
    return { data: null, error }
  }
}

export const signOut = async () => {
  try {
    await Auth.signOut()
    return { data: null, error: null }
  } catch (error) {
    return { data: null, error }
  }
}

export const signUp = async (params: {
  email: string
  password: string
  confirmPassword: string
}): Promise<SignUpResponse> => {
  const email = params.email.trim().toLowerCase()
  const username = email.replace('@', '')
  const password = params.password.trim()

  try {
    const user = await Auth.signUp({
      username,
      password,
      attributes: {
        email,
        name: username,
      },
    })
    return {
      data: { user, email },
      error: null,
    }
  } catch (error) {
    return {
      data: null,
      error: error as SignUpResponse['error'],
    }
  }
}

export const forgotPassword = async (params: {
  email: string
}): Promise<ApiResponse<null | unknown>> => {
  type CodeDeliveryDetailsContainer = { CodeDeliveryDetails?: unknown }
  try {
    const details = (await Auth.forgotPassword(params.email)) as unknown
    const data =
      details &&
      typeof details === 'object' &&
      (details as CodeDeliveryDetailsContainer).CodeDeliveryDetails
    return { data, error: null }
  } catch (error) {
    return { data: null, error }
  }
}

export const resetPassword = async (params: {
  email: string
  code: string
  newPassword: string
  newPasswordConfirmation: string
}): Promise<ApiResponse<null>> => {
  const { email, code, newPassword } = params
  const username = email.replace('@', '').trim()
  try {
    await Auth.forgotPasswordSubmit(username, code, newPassword.trim())
    return { data: null, error: null }
  } catch (error) {
    return { data: null, error }
  }
}

export const resendConfirmationEmail = debounce(
  async (email: string): Promise<unknown> => {
    const username = email.replace('@', '').trim()
    return Auth.resendSignUp(username)
  },
  30000,
  { leading: true, trailing: false },
)

export const changePassword = async (params: {
  oldPassword: string
  newPassword: string
}): Promise<ApiResponse<null>> => {
  const { oldPassword, newPassword } = params
  try {
    const user = await Auth.currentUserPoolUser()
    if (!user) {
      throw new Error('No authenticated user found')
    }
    await Auth.changePassword(user, oldPassword, newPassword)
    return { data: null, error: null }
  } catch (error) {
    return { data: null, error }
  }
}

export const updateUserAttributes = async (params: {
  attributes: {
    given_name?: string
    family_name?: string
    birthdate?: string
  }
}): Promise<ApiResponse<null>> => {
  const { attributes } = params
  try {
    const user = await Auth.currentUserPoolUser()
    if (!user) {
      throw new Error('No authenticated user found')
    }

    await Auth.updateUserAttributes(user, attributes)
    return { data: null, error: null }
  } catch (error) {
    return { data: null, error }
  }
}

export const getUserAttributes = async () => {
  try {
    const user = await Auth.currentUserPoolUser()
    if (!user) {
      throw new Error('No authenticated user found')
    }
    const res = await Auth.userAttributes(user)

    const attributes = res.reduce(
      (acc, curr) => {
        acc[curr.Name] = curr.Value
        return acc
      },
      {} as {
        given_name?: string
        family_name?: string
        birthdate?: string
      } & Record<string, string>,
    )
    return { data: attributes, error: null }
  } catch (error) {
    return { data: null, error }
  }
}

import React from 'react'
import { Button } from '@/components/ui/button'
import { useCurrentUser } from '@/auth/hooks/use-current-user'
import { cn } from '@/lib/utils'

interface UpgradeButtonProps {
  className?: string
  children?: React.ReactNode
}

export const UpgradeButton: React.FC<UpgradeButtonProps> = ({ className, children }) => {
  const { user } = useCurrentUser()

  const handleUpgradeClick = () => {
    const checkoutUrl = new URL('https://buy.stripe.com/7sI3cS3Kc8sBdvW9BX')
    if (user?.email) {
      checkoutUrl.searchParams.append('prefilled_email', user.email)
    }
    window.location.href = checkoutUrl.toString()
  }

  return (
    <Button
      variant="pro"
      className={cn('bg-proGold', className)}
      onClick={handleUpgradeClick}
    >
      {children || 'Upgrade to Zoya Pro'}
    </Button>
  )
}

import { Routes, Route, Navigate } from 'react-router-dom'
import { CalculatorPage } from './pages/calculator-page'

export const ZakatRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<CalculatorPage />} />
      <Route path="*" element={<Navigate to="/zakat" replace />} />
    </Routes>
  )
}

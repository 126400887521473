import { z } from 'zod'
import { changePasswordSchema } from '../validation'
import { useState } from 'react'
import { changePassword } from '../api'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

/**

 */
export const useChangePasswordForm = (onSuccess?: () => void) => {
  const [error, setError] = useState<Error>()

  const form = useForm<z.infer<typeof changePasswordSchema>>({
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',
    },
    resolver: zodResolver(changePasswordSchema),
    mode: 'onBlur',
  })

  const onSubmit = form.handleSubmit(
    async (args: z.infer<typeof changePasswordSchema>) => {
      setError(undefined)
      const res = await changePassword(args)
      if (res.error) {
        setError(res.error as Error)
      } else {
        form.reset() // reset the form
        onSuccess && onSuccess()
      }
    },
  )

  return { form, onSubmit, error, loading: form.formState.isSubmitting }
}

import { useCurrentUser } from '@/auth/hooks/use-current-user'
import { UserDropdown } from '@/common/components/user-dropdown'
import { SubscriptionSection } from '../components/subscription-section'
import { ZoyaLogo } from '@/common/components/zoya-logo'
import { SidebarNav } from '../components/sidebar-nav'
import { Route, Routes, Navigate, useLocation } from 'react-router-dom'
import { ProfileSection } from '../components/profile-section'
import { PasswordSection } from '../components/password-section'
import { LinkedAccountsSection } from '../components/linked-accounts-sections'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { useNavigate } from 'react-router-dom'

const sidebarNavItems = [
  {
    title: 'Profile',
    href: '/settings/profile',
  },
  {
    title: 'Subscription',
    href: '/settings/subscription',
  },
  {
    title: 'Linked Accounts',
    href: '/settings/linked-accounts',
  },
  {
    title: 'Password',
    href: '/settings/password',
  },
]

export const SettingsPage = () => {
  const { user } = useCurrentUser()
  const navigate = useNavigate()
  const location = useLocation()

  // Get the href of the first nav item
  const defaultRoute = sidebarNavItems[0]?.href || '/settings/profile'

  const handleNavChange = (value: string) => {
    navigate(value)
  }

  return (
    <div className="min-h-screen space-y-6 p-4 lg:p-10 bg-background-80 max-w-3xl mx-auto">
      <div className="mb-12 flex flex-row items-center justify-between">
        <ZoyaLogo className="w-28" />
        <UserDropdown />
      </div>
      <h1 className="text-2xl font-medium mb-8">Settings</h1>
      <div className="flex flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0">
        <aside className="lg:w-1/5">
          <div className="md:hidden lg:hidden">
            <Select onValueChange={handleNavChange} value={location.pathname}>
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Select a section" />
              </SelectTrigger>
              <SelectContent>
                {sidebarNavItems.map((item) => (
                  <SelectItem key={item.href} value={item.href}>
                    {item.title}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="hidden md:block">
            <SidebarNav items={sidebarNavItems} />
          </div>
        </aside>
        <div className="flex-1 max-w-4xl">
          <Routes>
            <Route path="/" element={<Navigate to={defaultRoute} replace />} />
            <Route path="/profile" element={<ProfileSection />} />
            <Route path="/password" element={<PasswordSection />} />
            <Route path="/subscription" element={<SubscriptionSection />} />
            <Route path="/linked-accounts" element={<LinkedAccountsSection />} />
          </Routes>
        </div>
      </div>
    </div>
  )
}
import { z } from 'zod'

export const resetCodeRequestSchema = z.object({
  email: z.string().email('Invalid email').trim(),
})
export const resetPasswordSchema = z
  .object({
    email: z.string().email('Invalid email').trim(),
    code: z.string(),
    newPassword: z
      .string()
      .min(8)
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
        'Password must have uppercase letters, lowercase letters, and numbers',
      ),
    newPasswordConfirmation: z
      .string()
      .min(8)
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
        'Password must have uppercase letters, lowercase letters, and numbers',
      ),
  })
  .refine((data) => data.newPassword === data.newPasswordConfirmation, {
    message: 'Passwords do not match',
    path: ['newPasswordConfirmation'],
  })

export const signInSchema = z.object({
  email: z.string().email('Invalid email').min(1).trim(),
  password: z.string(),
})

export const signUpSchema = z
  .object({
    email: z.string().email('Invalid email').trim(),
    password: z
      .string()
      .min(8)
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
        'Password must have uppercase letters, lowercase letters, and numbers',
      ),
    confirmPassword: z.string(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'Passwords do not match',
    path: ['confirmPassword'],
  })

const isEmptyString = (_: string | undefined) => _ === ''

export const userAttributesSchema = z
  .object({
    given_name: z.string().optional(),
    family_name: z.string().optional(),
    birthdate: z.string().optional(),
  })
  .transform((data) => {
    if (!data.given_name || isEmptyString(data.given_name)) {
      delete data.given_name;
    }
    if (!data.family_name || isEmptyString(data.family_name)) {
      delete data.family_name;
    }
    if (!data.birthdate || isEmptyString(data.birthdate)) {
      delete data.birthdate;
    }
    return data;
  })

export const changePasswordSchema = z
  .object({
    oldPassword: z.string(),
    newPassword: z
      .string()
      .min(8, 'Password must be at least 8 characters long')
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
        'Password must have uppercase letters, lowercase letters, and numbers',
      ),
    newPasswordConfirmation: z
      .string()
      .min(8, 'Password must be at least 8 characters long')
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
        'Password must have uppercase letters, lowercase letters, and numbers',
      ),
  })
  .refine((data) => data.newPassword === data.newPasswordConfirmation, {
    message: 'Passwords do not match',
    path: ['newPasswordConfirmation'],
  })

import { useCallback, useEffect, useState } from 'react'
import {
  CalculationResult,
  ZakatCalculation,
  ZakatCalculationResponseSchema,
  ZakatCalculationSchema,
  ZakatHolding,
} from '../types'
import { enrichHoldings } from '../utils/enrich-holdings'

const { VITE_ZAKAT_API_ENDPOINT } = import.meta.env

const fetchZakatCalculation = async (
  holdings: Partial<ZakatHolding>[],
): Promise<ZakatCalculation | null> => {
  try {
    const response = await fetch(`${VITE_ZAKAT_API_ENDPOINT}/zakat`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ holdings: holdings }),
    })
    const rawResponse = await response.json()
    const parsedResponse = ZakatCalculationResponseSchema.safeParse(rawResponse)
    if (!parsedResponse.success) {
      throw Error(
        "response doesn't match expected type, recieved: ",
        rawResponse,
      )
    }

    const zakatResponse = parsedResponse.data
    const formattedHoldings = zakatResponse.holdings.map((holding) => {
      return {
        ...holding,
        type: holding.strategy === 'PASSIVE' ? 'Passive' : 'Active',
        shares: holding.quantity,
      }
    })

    const activeHoldings = zakatResponse.holdings.filter(
      (holding) => holding.strategy === 'ACTIVE',
    )
    const passiveHoldings = zakatResponse.holdings.filter(
      (holding) => holding.strategy === 'PASSIVE',
    )

    const activeSummary = activeHoldings.reduce(
      (curValue, acc) => {
        return {
          baseValue: curValue.baseValue + acc.marketValue,
          zakatableAmount: curValue.zakatableAmount + acc.zakatableAmount,
          zakatDue: curValue.zakatDue + acc.zakatDue,
        }
      },
      {
        baseValue: 0,
        zakatableAmount: 0,
        zakatDue: 0,
      },
    )

    const passiveSummary = passiveHoldings.reduce(
      (curValue, acc) => {
        return {
          baseValue: curValue.baseValue + acc.marketValue,
          zakatableAmount: curValue.zakatableAmount + acc.zakatableAmount,
          zakatDue: curValue.zakatDue + acc.zakatDue,
        }
      },
      {
        baseValue: 0,
        zakatableAmount: 0,
        zakatDue: 0,
      },
    )

    const formattedData = {
      zakatDue: zakatResponse.zakatDue,
      holdings: formattedHoldings,
      summary: {
        active: activeSummary,
        passive: passiveSummary,
        successCount: zakatResponse.holdingsCount,
        fallbackCount: 0,
        failCount: 0,
      },
    }
    const parsed = ZakatCalculationSchema.safeParse(formattedData)
    if (parsed.success) {
      return parsed.data
    } else {
      throw Error("response doesn't match expected type")
    }
  } catch (error) {
    throw new Error('failed to fetch zakat calculation', { cause: error })
  }
}

export const useZakatCalculator = (args?: {
  onCalculationSuccess?: () => void
}) => {
  const [holdings, setHoldings] = useState<Partial<ZakatHolding>[]>([])
  const [calculation, setCalculation] = useState<{
    loading: boolean
    error?: Error
    result?: ZakatCalculation | null
  }>({
    loading: false,
  })

  const addHolding = async (holding: Partial<ZakatHolding>) => {
    const existingHoldings = holdings
    const symbolAlreadyExists = !!existingHoldings.find(
      (_) => _.symbol === holding.symbol && _.strategy === holding.strategy,
    )
    // if symbol already exists in the table merge the quantities,
    // otherwise add the new holding
    if (symbolAlreadyExists) {
      setHoldings(
        existingHoldings.map((existingHolding) => {
          if (
            existingHolding.symbol === holding.symbol &&
            existingHolding.strategy === holding.strategy
          ) {
            return {
              ...existingHolding,
              quantity:
                (existingHolding.quantity ?? 0) + (holding.quantity ?? 0),
            }
          } else {
            return existingHolding
          }
        }),
      )
      return
    }

    const mappedHolding: Partial<ZakatHolding> = {
      ...holding,
      dataFetch: {
        finished: false,
        missingFields: [],
      },
    }
    const newHoldings = [...existingHoldings, mappedHolding]

    setHoldings(newHoldings)

    const enrichedHoldings = await enrichHoldings(newHoldings)

    setHoldings(enrichedHoldings)
  }

  const removeHolding = useCallback((symbol?: string, strategy?: string) => {
    if (symbol && strategy) {
      setHoldings((_) =>
        _.filter(
          (holding) =>
            !(holding.symbol === symbol && holding.strategy === strategy),
        ),
      )
    }
  }, [])

  const calculate = useCallback(() => {
    setCalculation({
      loading: true,
    })
    fetchZakatCalculation(holdings)
      .then((result) => {
        setCalculation({
          loading: false,
          result,
        })
        args?.onCalculationSuccess && args.onCalculationSuccess()
      })
      .catch((error) => {
        setCalculation({
          loading: false,
          error,
        })
      })
  }, [holdings])

  return {
    holdings,
    addHolding,
    removeHolding,
    calculation,
    calculate,
  }
}

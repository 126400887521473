import { DataTable } from '@/components/ui/data-table'
import { ZakatCalculation } from '@/zakat/types'
import { ColumnDef } from '@tanstack/react-table'

const columnsDef: ColumnDef<ZakatCalculation['holdings'][number]>[] = [
  {
    accessorKey: 'name',
    header: 'Holding',
    maxSize: 200,
    minSize: 100,
    cell: ({ row }) => {
      return (
        <div>
          <h4 className="font-semibold">{row.original.symbol}</h4>
          <p className="font-normal text-xs">{row.original.name}</p>
        </div>
      )
    },
  },
  {
    accessorKey: 'strategy',
    header: 'Type',
    maxSize: 200,
    minSize: 100,
    cell: ({ row }) => {
      return (
        <p className="text-sm ">
          {row.original.strategy === 'ACTIVE' ? 'Short-term' : 'Long-term'}
        </p>
      )
    },
  },
  {
    header: 'Value',
    maxSize: 200,
    minSize: 100,
    cell: ({ row }) => {
      const formatted = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: row.original.currency,
      }).format(row.original.unitPrice * row.original.shares)
      return <p>{formatted}</p>
    },
  },
  {
    accessorKey: 'zakatLiableAmount',
    header: 'Zakat Liable Amount	',
    maxSize: 200,
    minSize: 100,
    cell: ({ row }) => {
      const formatted = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: row.original.currency,
      }).format(row.original.zakatLiableAmount)
      return <p>{formatted}</p>
    },
  },
  {
    accessorKey: 'zakatDue',
    header: 'Zakat Due',
    maxSize: 200,
    minSize: 100,
    cell: ({ row }) => {
      const formatted = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: row.original.currency,
      }).format(row.original.zakatDue)
      return <p>{formatted}</p>
    },
  },
]

export const ResultsHoldingsTable: React.FC<{
  holdings: ZakatCalculation['holdings']
}> = ({ holdings }) => {
  return <DataTable columns={columnsDef} data={holdings} />
}

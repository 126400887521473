import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  AWSJSON: any
}

export type AccountDeletionReason = {
  duplicateAccount: Scalars['Boolean']
  other: Scalars['Boolean']
  switchingToAnotherProduct: Scalars['Boolean']
  technicalIssues: Scalars['Boolean']
  tooExpensive: Scalars['Boolean']
}

export type AccountMutations = {
  __typename?: 'AccountMutations'
  requestDeleteAccount: SuccessResponse
}

export type AccountMutationsRequestDeleteAccountArgs = {
  feedback?: InputMaybe<Scalars['String']>
  otherReason?: InputMaybe<Scalars['String']>
  reasons: AccountDeletionReason
}

/**  Alpaca Funding */
export const AchStatus = {
  Approved: 'APPROVED',
  Pending: 'PENDING',
  Queued: 'QUEUED',
} as const

export type AchStatus = (typeof AchStatus)[keyof typeof AchStatus]
export type AfscReport = {
  __typename?: 'AfscReport'
  borders: Scalars['Boolean']
  divest: Scalars['Boolean']
  hq: Scalars['String']
  iexSymbol: Scalars['String']
  industry: Scalars['String']
  link: Scalars['String']
  name: Scalars['String']
  occupations: Scalars['Boolean']
  primaryExchange: Scalars['String']
  primarySymbol: Scalars['String']
  prisons: Scalars['Boolean']
  summary: Scalars['String']
}

export type AlpacaAccount = {
  __typename?: 'AlpacaAccount'
  accountNumber: Scalars['String']
  accountType?: Maybe<AlpacaAccountType>
  agreements: Array<AlpacaAgreements>
  contact: AlpacaContact
  createdAt: Scalars['String']
  cryptoStatus: AlpacaAccountStatus
  currency: Scalars['String']
  disclosures: AlpacaDisclosures
  documents: Array<AlpacaDocument>
  enabledAssets: Array<AlpacaAssetClass>
  identity: AlpacaIdentity
  lastEquity: Scalars['String']
  status: AlpacaAccountStatus
  tradingConfigurations: AlpacaTradingConfigurations
  trustedContact?: Maybe<AlpacaTrustedContact>
}

export type AlpacaAccountActivity =
  | AlpacaAccountNonTradingActivity
  | AlpacaAccountTradingActivity

/**   The enum value format is inconsistent(ie: lowercase and uppercase) because we are mirroring exactly what Alpaca is returning. */
export const AlpacaAccountActivityStatus = {
  Canceled: 'canceled',
  Correct: 'correct',
  Executed: 'executed',
} as const

export type AlpacaAccountActivityStatus =
  (typeof AlpacaAccountActivityStatus)[keyof typeof AlpacaAccountActivityStatus]
export const AlpacaAccountActivityType = {
  Acatc: 'ACATC',
  Acats: 'ACATS',
  Cil: 'CIL',
  Csd: 'CSD',
  Csw: 'CSW',
  Div: 'DIV',
  Divcgl: 'DIVCGL',
  Divcgs: 'DIVCGS',
  Divnra: 'DIVNRA',
  Divroc: 'DIVROC',
  Divtxex: 'DIVTXEX',
  Fee: 'FEE',
  Fill: 'FILL',
  Int: 'INT',
  Jnlc: 'JNLC',
  Jnls: 'JNLS',
  Ma: 'MA',
  Ptc: 'PTC',
  Reorg: 'REORG',
  Spin: 'SPIN',
  Split: 'SPLIT',
} as const

export type AlpacaAccountActivityType =
  (typeof AlpacaAccountActivityType)[keyof typeof AlpacaAccountActivityType]
export type AlpacaAccountNonTradingActivity = {
  __typename?: 'AlpacaAccountNonTradingActivity'
  accountId: Scalars['String']
  activityType?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  id: Scalars['String']
  netAmount?: Maybe<Scalars['String']>
  perShareAmount?: Maybe<Scalars['String']>
  qty?: Maybe<Scalars['String']>
  status: Scalars['String']
  symbol?: Maybe<Scalars['String']>
}

/**
 *   Alpaca
 *  The enum value format is inconsistent(ie: lowercase and uppercase) because we are mirroring exactly what Alpaca is returning.
 */
export const AlpacaAccountStatus = {
  AccountClosed: 'ACCOUNT_CLOSED',
  AccountUpdated: 'ACCOUNT_UPDATED',
  ActionRequired: 'ACTION_REQUIRED',
  Active: 'ACTIVE',
  ApprovalPending: 'APPROVAL_PENDING',
  Approved: 'APPROVED',
  Disabled: 'DISABLED',
  Edited: 'EDITED',
  Inactive: 'INACTIVE',
  Onboarding: 'ONBOARDING',
  Rejected: 'REJECTED',
  SubmissionFailed: 'SUBMISSION_FAILED',
  Submitted: 'SUBMITTED',
} as const

export type AlpacaAccountStatus =
  (typeof AlpacaAccountStatus)[keyof typeof AlpacaAccountStatus]
export type AlpacaAccountTradingActivity = {
  __typename?: 'AlpacaAccountTradingActivity'
  accountId: Scalars['String']
  activityType?: Maybe<Scalars['String']>
  cumQty?: Maybe<Scalars['String']>
  id: Scalars['String']
  leavesQty?: Maybe<Scalars['String']>
  orderId?: Maybe<Scalars['String']>
  orderStatus?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['String']>
  qty?: Maybe<Scalars['String']>
  side?: Maybe<Scalars['String']>
  symbol?: Maybe<Scalars['String']>
  transactionTime?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

export type AlpacaAccountTradingInfo = {
  __typename?: 'AlpacaAccountTradingInfo'
  accountBlocked: Scalars['Boolean']
  accountNumber: Scalars['String']
  accruedFees: Scalars['String']
  buyingPower: Scalars['String']
  cash: Scalars['String']
  cashTransferable: Scalars['String']
  cashWithdrawable: Scalars['String']
  clearingBroker: Scalars['String']
  createdAt: Scalars['String']
  cryptoStatus: Scalars['String']
  currency: Scalars['String']
  daytradeCount: Scalars['Int']
  daytradingBuyingPower: Scalars['String']
  equity: Scalars['String']
  id: Scalars['String']
  initialMargin: Scalars['String']
  lastBuyingPower: Scalars['String']
  lastCash: Scalars['String']
  lastDaytradeCount: Scalars['Int']
  lastDaytradingBuyingPower: Scalars['String']
  lastEquity: Scalars['String']
  lastInitialMargin: Scalars['String']
  lastLongMarketValue: Scalars['String']
  lastMaintenanceMargin: Scalars['String']
  lastRegtBuyingPower: Scalars['String']
  lastShortMarketValue: Scalars['String']
  longMarketValue: Scalars['String']
  maintenanceMargin: Scalars['String']
  multiplier: Scalars['String']
  patternDayTrader: Scalars['Boolean']
  pendingTransferOut: Scalars['String']
  portfolioValue: Scalars['String']
  previousClose: Scalars['String']
  regtBuyingPower: Scalars['String']
  shortMarketValue: Scalars['String']
  shortingEnabled: Scalars['Boolean']
  sma: Scalars['String']
  status: Scalars['String']
  tradeSuspendedByUser: Scalars['Boolean']
  tradingBlocked: Scalars['Boolean']
  transfersBlocked: Scalars['Boolean']
}

export const AlpacaAccountType = {
  Custodial: 'custodial',
  DonorAdvised: 'donor_advised',
  Trading: 'trading',
} as const

export type AlpacaAccountType =
  (typeof AlpacaAccountType)[keyof typeof AlpacaAccountType]
export type AlpacaAchRelationship = {
  __typename?: 'AlpacaAchRelationship'
  accountId: Scalars['String']
  accountMask?: Maybe<Scalars['String']>
  accountOwnerName: Scalars['String']
  approvalMethod?: Maybe<Scalars['String']>
  bankAccountNumber: Scalars['String']
  bankAccountType: Scalars['String']
  correspondentCode?: Maybe<Scalars['String']>
  createdAt: Scalars['String']
  id: Scalars['String']
  nickname?: Maybe<Scalars['String']>
  plaidInstitution?: Maybe<Scalars['String']>
  plaidVerificationStatus?: Maybe<Scalars['String']>
  processorToken?: Maybe<Scalars['String']>
  status: Scalars['String']
  updatedAt: Scalars['String']
}

export const AlpacaAgreement = {
  AccountAgreement: 'account_agreement',
  CryptoAgreement: 'crypto_agreement',
  CustomerAgreement: 'customer_agreement',
  MarginAgreement: 'margin_agreement',
} as const

export type AlpacaAgreement =
  (typeof AlpacaAgreement)[keyof typeof AlpacaAgreement]
export type AlpacaAgreementInput = {
  agreement: AlpacaAgreement
  ipAddress: Scalars['String']
  revision?: InputMaybe<Scalars['String']>
  signedAt: Scalars['String']
}

export type AlpacaAgreements = {
  __typename?: 'AlpacaAgreements'
  agreement: AlpacaAgreement
  ipAddress: Scalars['String']
  revision?: Maybe<Scalars['String']>
  signedAt: Scalars['String']
}

export const AlpacaApprovalMethod = {
  Correspondent: 'CORRESPONDENT',
  MicroDeposit: 'MICRO_DEPOSIT',
  Plaid: 'PLAID',
  PlaidMicro: 'PLAID_MICRO',
  PlaidPartner: 'PLAID_PARTNER',
} as const

export type AlpacaApprovalMethod =
  (typeof AlpacaApprovalMethod)[keyof typeof AlpacaApprovalMethod]
export type AlpacaAsset = {
  __typename?: 'AlpacaAsset'
  class: AlpacaAssetClass
  easyToBorrow: Scalars['Boolean']
  exchange: AlpacaAssetExchange
  fractionable: Scalars['Boolean']
  maintenanceMarginRequirement: Scalars['Int']
  marginable: Scalars['Boolean']
  name: Scalars['String']
  shortable: Scalars['Boolean']
  status: AlpacaAssetStatus
  symbol: Scalars['String']
  tradable: Scalars['Boolean']
}

export const AlpacaAssetClass = {
  Crypto: 'crypto',
  UsEquity: 'us_equity',
} as const

export type AlpacaAssetClass =
  (typeof AlpacaAssetClass)[keyof typeof AlpacaAssetClass]
export const AlpacaAssetExchange = {
  Amex: 'AMEX',
  Arca: 'ARCA',
  Bats: 'BATS',
  Nasdaq: 'NASDAQ',
  Nyse: 'NYSE',
  Nysearca: 'NYSEARCA',
  Otc: 'OTC',
} as const

export type AlpacaAssetExchange =
  (typeof AlpacaAssetExchange)[keyof typeof AlpacaAssetExchange]
export const AlpacaAssetStatus = {
  Active: 'active',
  Inactive: 'inactive',
} as const

export type AlpacaAssetStatus =
  (typeof AlpacaAssetStatus)[keyof typeof AlpacaAssetStatus]
export const AlpacaBankAccountType = {
  Checking: 'CHECKING',
  Savings: 'SAVINGS',
} as const

export type AlpacaBankAccountType =
  (typeof AlpacaBankAccountType)[keyof typeof AlpacaBankAccountType]
export const AlpacaBankCodeType = {
  Aba: 'ABA',
  Bic: 'BIC',
} as const

export type AlpacaBankCodeType =
  (typeof AlpacaBankCodeType)[keyof typeof AlpacaBankCodeType]
export type AlpacaBankRelationship = {
  __typename?: 'AlpacaBankRelationship'
  accountId: Scalars['String']
  accountNumber: Scalars['String']
  bankCode: Scalars['String']
  bankCodeType: Scalars['String']
  city?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  createdAt: Scalars['String']
  errorMessage?: Maybe<Scalars['String']>
  id: Scalars['String']
  name: Scalars['String']
  postalCode?: Maybe<Scalars['String']>
  stateProvince?: Maybe<Scalars['String']>
  status: Scalars['String']
  streetAddress?: Maybe<Scalars['String']>
  updatedAt: Scalars['String']
}

export const AlpacaBankRelationshipStatus = {
  Approved: 'APPROVED',
  Canceled: 'CANCELED',
  Queued: 'QUEUED',
  SentToClearing: 'SENT_TO_CLEARING',
} as const

export type AlpacaBankRelationshipStatus =
  (typeof AlpacaBankRelationshipStatus)[keyof typeof AlpacaBankRelationshipStatus]
export type AlpacaCompliance = {
  __typename?: 'AlpacaCompliance'
  compliantPercentage: Scalars['Float']
  compliantPercentageWithFunds: Scalars['Float']
  nonCompliantPercentage: Scalars['Float']
  nonCompliantPercentageWithFunds: Scalars['Float']
  positions: Array<PositionWithCompliance>
  questionablePercentage: Scalars['Float']
  questionablePercentageWithFunds: Scalars['Float']
  unratedPercentage: Scalars['Float']
  unratedPercentageWithFunds: Scalars['Float']
}

export type AlpacaContact = {
  __typename?: 'AlpacaContact'
  city: Scalars['String']
  emailAddress: Scalars['String']
  phoneNumber: Scalars['String']
  postalCode?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  streetAddress: Array<Scalars['String']>
  unit?: Maybe<Scalars['String']>
}

export type AlpacaContactInput = {
  city: Scalars['String']
  emailAddress: Scalars['String']
  phoneNumber: Scalars['String']
  postalCode?: InputMaybe<Scalars['String']>
  state?: InputMaybe<Scalars['String']>
  streetAddress: Array<Scalars['String']>
  unit?: InputMaybe<Scalars['String']>
}

export const AlpacaContextType = {
  AffiliateFirm: 'AFFILIATE_FIRM',
  ControlledFirm: 'CONTROLLED_FIRM',
  ImmediateFamilyExposed: 'IMMEDIATE_FAMILY_EXPOSED',
} as const

export type AlpacaContextType =
  (typeof AlpacaContextType)[keyof typeof AlpacaContextType]
export type AlpacaDisclosures = {
  __typename?: 'AlpacaDisclosures'
  context?: Maybe<Array<Maybe<AlpacaDisclosuresContext>>>
  employerAddress?: Maybe<Scalars['String']>
  employerName?: Maybe<Scalars['String']>
  employmentPosition?: Maybe<Scalars['String']>
  employmentStatus?: Maybe<Scalars['String']>
  immediateFamilyExposed: Scalars['Boolean']
  isAffiliatedExchangeOrFinra: Scalars['Boolean']
  isControlPerson: Scalars['Boolean']
  isPoliticallyExposed: Scalars['Boolean']
}

export type AlpacaDisclosuresContext = {
  __typename?: 'AlpacaDisclosuresContext'
  companyCity?: Maybe<Scalars['String']>
  companyComplianceEmail?: Maybe<Scalars['String']>
  companyCountry?: Maybe<Scalars['String']>
  companyName?: Maybe<Scalars['String']>
  companyState?: Maybe<Scalars['String']>
  companyStreetAddress?: Maybe<Scalars['String']>
  contextType: AlpacaContextType
  familyName?: Maybe<Scalars['String']>
  givenName?: Maybe<Scalars['String']>
}

export type AlpacaDisclosuresContextInput = {
  companyCity?: InputMaybe<Scalars['String']>
  companyComplianceEmail?: InputMaybe<Scalars['String']>
  companyCountry?: InputMaybe<Scalars['String']>
  companyName?: InputMaybe<Scalars['String']>
  companyState?: InputMaybe<Scalars['String']>
  companyStreetAddress?: InputMaybe<Scalars['String']>
  contextType: AlpacaContextType
  familyName?: InputMaybe<Scalars['String']>
  givenName?: InputMaybe<Scalars['String']>
}

export type AlpacaDisclosuresInput = {
  context?: InputMaybe<Array<InputMaybe<AlpacaDisclosuresContextInput>>>
  employerAddress?: InputMaybe<Scalars['String']>
  employerName?: InputMaybe<Scalars['String']>
  employmentPosition?: InputMaybe<Scalars['String']>
  employmentStatus?: InputMaybe<AlpacaEmploymentStatus>
  immediateFamilyExposed: Scalars['Boolean']
  isAffiliatedExchangeOrFinra: Scalars['Boolean']
  isControlPerson: Scalars['Boolean']
  isPoliticallyExposed: Scalars['Boolean']
}

export type AlpacaDocument = {
  __typename?: 'AlpacaDocument'
  content: Scalars['String']
  createdAt: Scalars['String']
  documentSubType?: Maybe<Scalars['String']>
  documentType: AlpacaDocumentType
  id?: Maybe<Scalars['String']>
  mimeType: Scalars['String']
}

export type AlpacaDocumentMetadata = {
  __typename?: 'AlpacaDocumentMetadata'
  date: Scalars['String']
  id: Scalars['String']
  name: Scalars['String']
  subType: Scalars['String']
  type: Scalars['String']
}

export const AlpacaDocumentType = {
  AccountApprovalLetter: 'account_approval_letter',
  AddressVerification: 'address_verification',
  DateOfBirthVerification: 'date_of_birth_verification',
  IdentityVerification: 'identity_verification',
  LimitedTradingAuthorization: 'limited_trading_authorization',
  TaxIdVerification: 'tax_id_verification',
  W8ben: 'w8ben',
  W9: 'w9',
} as const

export type AlpacaDocumentType =
  (typeof AlpacaDocumentType)[keyof typeof AlpacaDocumentType]
export type AlpacaDocumentUploadInput = {
  content: Scalars['String']
  documentSubType?: InputMaybe<Scalars['String']>
  documentType: AlpacaDocumentType
  mimeType: Scalars['String']
  objectKey?: InputMaybe<Scalars['String']>
}

export const AlpacaEmploymentStatus = {
  Employed: 'employed',
  Retired: 'retired',
  Student: 'student',
  Unemployed: 'unemployed',
} as const

export type AlpacaEmploymentStatus =
  (typeof AlpacaEmploymentStatus)[keyof typeof AlpacaEmploymentStatus]
export type AlpacaFundTransfer = {
  __typename?: 'AlpacaFundTransfer'
  accountId: Scalars['String']
  additionalInformation?: Maybe<Scalars['String']>
  amount: Scalars['String']
  createdAt: Scalars['String']
  direction: Scalars['String']
  expiresAt: Scalars['String']
  fee?: Maybe<Scalars['String']>
  feePaymentMethod?: Maybe<Scalars['String']>
  id: Scalars['String']
  reason?: Maybe<Scalars['String']>
  relationshipId: Scalars['String']
  requestedAmount?: Maybe<Scalars['String']>
  status: Scalars['String']
  type: Scalars['String']
  updatedAt?: Maybe<Scalars['String']>
}

export const AlpacaFundTransferDirection = {
  Incoming: 'INCOMING',
  Outgoing: 'OUTGOING',
} as const

export type AlpacaFundTransferDirection =
  (typeof AlpacaFundTransferDirection)[keyof typeof AlpacaFundTransferDirection]
export const AlpacaFundTransferFeePaymentMethod = {
  Invoice: 'invoice',
  User: 'user',
} as const

export type AlpacaFundTransferFeePaymentMethod =
  (typeof AlpacaFundTransferFeePaymentMethod)[keyof typeof AlpacaFundTransferFeePaymentMethod]
export const AlpacaFundTransferStatus = {
  ApprovalPending: 'APPROVAL_PENDING',
  Approved: 'APPROVED',
  Canceled: 'CANCELED',
  Complete: 'COMPLETE',
  Pending: 'PENDING',
  Queued: 'QUEUED',
  Rejected: 'REJECTED',
  Returned: 'RETURNED',
  SentToClearing: 'SENT_TO_CLEARING',
} as const

export type AlpacaFundTransferStatus =
  (typeof AlpacaFundTransferStatus)[keyof typeof AlpacaFundTransferStatus]
export const AlpacaFundTransferTiming = {
  Immediate: 'immediate',
} as const

export type AlpacaFundTransferTiming =
  (typeof AlpacaFundTransferTiming)[keyof typeof AlpacaFundTransferTiming]
/**   Alpaca fund transfer */
export const AlpacaFundTransferType = {
  Ach: 'ach',
  Wire: 'wire',
} as const

export type AlpacaFundTransferType =
  (typeof AlpacaFundTransferType)[keyof typeof AlpacaFundTransferType]
export const AlpacaFundingSource = {
  BusinessIncome: 'business_income',
  EmploymentIncome: 'employment_income',
  Family: 'family',
  Inheritance: 'inheritance',
  Investments: 'investments',
  Savings: 'savings',
} as const

export type AlpacaFundingSource =
  (typeof AlpacaFundingSource)[keyof typeof AlpacaFundingSource]
export type AlpacaIdentity = {
  __typename?: 'AlpacaIdentity'
  annualIncomeMax?: Maybe<Scalars['String']>
  annualIncomeMin?: Maybe<Scalars['String']>
  countryOfBirth?: Maybe<Scalars['String']>
  countryOfCitizenship?: Maybe<Scalars['String']>
  countryOfTaxResidence: Scalars['String']
  dateOfBirth: Scalars['String']
  dateOfDepartureFromUsa?: Maybe<Scalars['String']>
  extra?: Maybe<Scalars['AWSJSON']>
  familyName: Scalars['String']
  fundingSource: Array<AlpacaFundingSource>
  givenName: Scalars['String']
  liquidNetWorthMax?: Maybe<Scalars['String']>
  liquidNetWorthMin?: Maybe<Scalars['String']>
  middleName?: Maybe<Scalars['String']>
  permanentResident?: Maybe<Scalars['Boolean']>
  taxId?: Maybe<Scalars['String']>
  taxIdType?: Maybe<AlpacaTaxIdType>
  totalNetWorthMax?: Maybe<Scalars['String']>
  totalNetWorthMin?: Maybe<Scalars['String']>
  visaExpirationDate?: Maybe<Scalars['String']>
  visaType?: Maybe<AlpacaVisaType>
}

export type AlpacaIdentityInput = {
  annualIncomeMax?: InputMaybe<Scalars['String']>
  annualIncomeMin?: InputMaybe<Scalars['String']>
  countryOfBirth?: InputMaybe<Scalars['String']>
  countryOfCitizenship?: InputMaybe<Scalars['String']>
  countryOfTaxResidence: Scalars['String']
  dateOfBirth: Scalars['String']
  dateOfDepartureFromUsa?: InputMaybe<Scalars['String']>
  extra?: InputMaybe<Scalars['AWSJSON']>
  familyName: Scalars['String']
  fundingSource: Array<AlpacaFundingSource>
  givenName: Scalars['String']
  liquidNetWorthMax?: InputMaybe<Scalars['String']>
  liquidNetWorthMin?: InputMaybe<Scalars['String']>
  middleName?: InputMaybe<Scalars['String']>
  permanentResident?: InputMaybe<Scalars['Boolean']>
  taxId?: InputMaybe<Scalars['String']>
  taxIdType?: InputMaybe<AlpacaTaxIdType>
  totalNetWorthMax?: InputMaybe<Scalars['String']>
  totalNetWorthMin?: InputMaybe<Scalars['String']>
  visaExpirationDate?: InputMaybe<Scalars['String']>
  visaType?: InputMaybe<AlpacaVisaType>
}

export type AlpacaMarketBar = {
  __typename?: 'AlpacaMarketBar'
  c: Scalars['Float']
  h: Scalars['Float']
  l: Scalars['Float']
  n: Scalars['Int']
  o: Scalars['Float']
  t: Scalars['String']
  v: Scalars['Int']
  vw: Scalars['Float']
}

export type AlpacaMarketBarResponse = {
  __typename?: 'AlpacaMarketBarResponse'
  bars?: Maybe<Array<AlpacaMarketBar>>
  nextPageToken?: Maybe<Scalars['String']>
  symbol: Scalars['String']
}

export type AlpacaMarketData = {
  __typename?: 'AlpacaMarketData'
  bars?: Maybe<AlpacaMarketBarResponse>
  latestBar?: Maybe<AlpacaMarketLatestBarResponse>
  latestQuote?: Maybe<AlpacaMarketLatestQuoteResponse>
  latestTrade?: Maybe<AlpacaMarketLatestTradeResponse>
  multiBars?: Maybe<AlpacaMarketMultiBarResponse>
  multiLatestBar?: Maybe<AlpacaMarketMultiLatestBarResponse>
  multiLatestQuote?: Maybe<AlpacaMarketMultiLatestQuoteResponse>
  multiLatestTrade?: Maybe<AlpacaMarketMultiLatestTradeResponse>
  multiQuotes?: Maybe<AlpacaMarketMultiQuoteResponse>
  multiTrades?: Maybe<AlpacaMarketMultiTradeResponse>
  quotes?: Maybe<AlpacaMarketQuoteResponse>
  trades?: Maybe<AlpacaMarketTradeResponse>
}

export type AlpacaMarketDataBarsArgs = {
  adjustment?: InputMaybe<Scalars['String']>
  asof?: InputMaybe<Scalars['String']>
  currency?: InputMaybe<Scalars['String']>
  end?: InputMaybe<Scalars['String']>
  feed?: InputMaybe<AlpacaMarketFeed>
  limit?: InputMaybe<Scalars['Int']>
  pageToken?: InputMaybe<Scalars['String']>
  start?: InputMaybe<Scalars['String']>
  symbol: Scalars['String']
  timeframe: Scalars['String']
}

export type AlpacaMarketDataLatestBarArgs = {
  currency?: InputMaybe<Scalars['String']>
  feed?: InputMaybe<AlpacaMarketFeed>
  symbol: Scalars['String']
}

export type AlpacaMarketDataLatestQuoteArgs = {
  currency?: InputMaybe<Scalars['String']>
  feed?: InputMaybe<AlpacaMarketFeed>
  symbol: Scalars['String']
}

export type AlpacaMarketDataLatestTradeArgs = {
  currency?: InputMaybe<Scalars['String']>
  feed?: InputMaybe<AlpacaMarketFeed>
  symbol: Scalars['String']
}

export type AlpacaMarketDataMultiBarsArgs = {
  adjustment?: InputMaybe<Scalars['String']>
  asof?: InputMaybe<Scalars['String']>
  currency?: InputMaybe<Scalars['String']>
  end?: InputMaybe<Scalars['String']>
  feed?: InputMaybe<AlpacaMarketFeed>
  limit?: InputMaybe<Scalars['Int']>
  pageToken?: InputMaybe<Scalars['String']>
  start?: InputMaybe<Scalars['String']>
  symbols: Scalars['String']
  timeframe: Scalars['String']
}

export type AlpacaMarketDataMultiLatestBarArgs = {
  currency?: InputMaybe<Scalars['String']>
  feed?: InputMaybe<AlpacaMarketFeed>
  symbols: Scalars['String']
}

export type AlpacaMarketDataMultiLatestQuoteArgs = {
  currency?: InputMaybe<Scalars['String']>
  feed?: InputMaybe<AlpacaMarketFeed>
  symbols: Scalars['String']
}

export type AlpacaMarketDataMultiLatestTradeArgs = {
  currency?: InputMaybe<Scalars['String']>
  feed?: InputMaybe<AlpacaMarketFeed>
  symbols: Scalars['String']
}

export type AlpacaMarketDataMultiQuotesArgs = {
  asof?: InputMaybe<Scalars['String']>
  currency?: InputMaybe<Scalars['String']>
  end?: InputMaybe<Scalars['String']>
  feed?: InputMaybe<AlpacaMarketFeed>
  limit?: InputMaybe<Scalars['Int']>
  pageToken?: InputMaybe<Scalars['String']>
  start?: InputMaybe<Scalars['String']>
  symbols: Scalars['String']
}

export type AlpacaMarketDataMultiTradesArgs = {
  asof?: InputMaybe<Scalars['String']>
  currency?: InputMaybe<Scalars['String']>
  end?: InputMaybe<Scalars['String']>
  feed?: InputMaybe<AlpacaMarketFeed>
  limit?: InputMaybe<Scalars['Int']>
  pageToken?: InputMaybe<Scalars['String']>
  start?: InputMaybe<Scalars['String']>
  symbols: Scalars['String']
}

export type AlpacaMarketDataQuotesArgs = {
  asof?: InputMaybe<Scalars['String']>
  currency?: InputMaybe<Scalars['String']>
  end?: InputMaybe<Scalars['String']>
  feed?: InputMaybe<AlpacaMarketFeed>
  limit?: InputMaybe<Scalars['Int']>
  pageToken?: InputMaybe<Scalars['String']>
  start?: InputMaybe<Scalars['String']>
  symbol: Scalars['String']
}

export type AlpacaMarketDataTradesArgs = {
  asof?: InputMaybe<Scalars['String']>
  currency?: InputMaybe<Scalars['String']>
  end?: InputMaybe<Scalars['String']>
  feed?: InputMaybe<AlpacaMarketFeed>
  limit?: InputMaybe<Scalars['Int']>
  pageToken?: InputMaybe<Scalars['String']>
  start?: InputMaybe<Scalars['String']>
  symbol: Scalars['String']
}

export const AlpacaMarketFeed = {
  Iex: 'iex',
  Otc: 'otc',
  Sip: 'sip',
} as const

export type AlpacaMarketFeed =
  (typeof AlpacaMarketFeed)[keyof typeof AlpacaMarketFeed]
export type AlpacaMarketLatestBarResponse = {
  __typename?: 'AlpacaMarketLatestBarResponse'
  bar: AlpacaMarketBar
  symbol: Scalars['String']
}

export type AlpacaMarketLatestQuoteResponse = {
  __typename?: 'AlpacaMarketLatestQuoteResponse'
  quote: AlpacaMarketQuote
  symbol: Scalars['String']
}

export type AlpacaMarketLatestTradeResponse = {
  __typename?: 'AlpacaMarketLatestTradeResponse'
  symbol: Scalars['String']
  trade: AlpacaMarketTrade
}

export type AlpacaMarketMultiBarResponse = {
  __typename?: 'AlpacaMarketMultiBarResponse'
  bars?: Maybe<Scalars['AWSJSON']>
  nextPageToken?: Maybe<Scalars['String']>
}

export type AlpacaMarketMultiLatestBarResponse = {
  __typename?: 'AlpacaMarketMultiLatestBarResponse'
  bars?: Maybe<Scalars['AWSJSON']>
}

export type AlpacaMarketMultiLatestQuoteResponse = {
  __typename?: 'AlpacaMarketMultiLatestQuoteResponse'
  quotes?: Maybe<Scalars['AWSJSON']>
}

export type AlpacaMarketMultiLatestTradeResponse = {
  __typename?: 'AlpacaMarketMultiLatestTradeResponse'
  trades?: Maybe<Scalars['AWSJSON']>
}

export type AlpacaMarketMultiQuoteResponse = {
  __typename?: 'AlpacaMarketMultiQuoteResponse'
  nextPageToken?: Maybe<Scalars['String']>
  quotes?: Maybe<Scalars['AWSJSON']>
}

export type AlpacaMarketMultiTradeResponse = {
  __typename?: 'AlpacaMarketMultiTradeResponse'
  nextPageToken?: Maybe<Scalars['String']>
  trades?: Maybe<Scalars['AWSJSON']>
}

export type AlpacaMarketQuote = {
  __typename?: 'AlpacaMarketQuote'
  ap: Scalars['Float']
  as: Scalars['Int']
  ax: Scalars['String']
  bp: Scalars['Float']
  bs: Scalars['Int']
  bx: Scalars['String']
  t: Scalars['String']
}

export type AlpacaMarketQuoteResponse = {
  __typename?: 'AlpacaMarketQuoteResponse'
  nextPageToken?: Maybe<Scalars['String']>
  quotes?: Maybe<Array<AlpacaMarketQuote>>
  symbol: Scalars['String']
}

export type AlpacaMarketTrade = {
  __typename?: 'AlpacaMarketTrade'
  p: Scalars['Float']
  s: Scalars['Int']
  t: Scalars['String']
  x: Scalars['String']
}

export type AlpacaMarketTradeResponse = {
  __typename?: 'AlpacaMarketTradeResponse'
  nextPageToken?: Maybe<Scalars['String']>
  symbol: Scalars['String']
  trades?: Maybe<Array<AlpacaMarketTrade>>
}

export type AlpacaOrder = {
  __typename?: 'AlpacaOrder'
  assetClass?: Maybe<Scalars['String']>
  assetId: Scalars['String']
  canceledAt?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  expiredAt?: Maybe<Scalars['String']>
  extendedHours?: Maybe<Scalars['Boolean']>
  failedAt?: Maybe<Scalars['String']>
  filledAt?: Maybe<Scalars['String']>
  filledAvgPrice?: Maybe<Scalars['String']>
  filledQty?: Maybe<Scalars['String']>
  id: Scalars['String']
  limitPrice?: Maybe<Scalars['String']>
  notional?: Maybe<Scalars['String']>
  qty?: Maybe<Scalars['String']>
  replacedAt?: Maybe<Scalars['String']>
  replacedBy?: Maybe<Scalars['String']>
  replaces?: Maybe<Scalars['String']>
  side: AlpacaOrderSide
  status: AlpacaOrderStatus
  submittedAt?: Maybe<Scalars['String']>
  symbol: Scalars['String']
  timeInForce: AlpacaOrderTimeInForce
  type: AlpacaOrderType
  updatedAt?: Maybe<Scalars['String']>
}

/**   The enum value format is inconsistent(ie: lowercase and uppercase) because we are mirroring exactly what Alpaca is returning. */
export const AlpacaOrderSide = {
  Buy: 'buy',
  Sell: 'sell',
} as const

export type AlpacaOrderSide =
  (typeof AlpacaOrderSide)[keyof typeof AlpacaOrderSide]
/**   The enum value format is inconsistent(ie: lowercase and uppercase) because we are mirroring exactly what Alpaca is returning. */
export const AlpacaOrderStatus = {
  Accepted: 'accepted',
  AcceptedForBidding: 'accepted_for_bidding',
  Calculated: 'calculated',
  Canceled: 'canceled',
  DoneForDay: 'done_for_day',
  Expired: 'expired',
  Filled: 'filled',
  New: 'new',
  PartiallyFilled: 'partially_filled',
  PendingCancel: 'pending_cancel',
  PendingNew: 'pending_new',
  PendingReplace: 'pending_replace',
  Rejected: 'rejected',
  Replaced: 'replaced',
  Stopped: 'stopped',
  Suspended: 'suspended',
} as const

export type AlpacaOrderStatus =
  (typeof AlpacaOrderStatus)[keyof typeof AlpacaOrderStatus]
/**   The enum value format is inconsistent(ie: lowercase and uppercase) because we are mirroring exactly what Alpaca is returning. */
export const AlpacaOrderTimeInForce = {
  Day: 'day',
  Gtc: 'gtc',
} as const

export type AlpacaOrderTimeInForce =
  (typeof AlpacaOrderTimeInForce)[keyof typeof AlpacaOrderTimeInForce]
/**   The enum value format is inconsistent(ie: lowercase and uppercase) because we are mirroring exactly what Alpaca is returning. */
export const AlpacaOrderType = {
  Limit: 'limit',
  Market: 'market',
} as const

export type AlpacaOrderType =
  (typeof AlpacaOrderType)[keyof typeof AlpacaOrderType]
export const AlpacaPlaidVerificationStatus = {
  AutomaticallyVerified: 'automatically_verified',
  ManuallyVerified: 'manually_verified',
  PendingAutomaticVerification: 'pending_automatic_verification',
  PendingManualVerification: 'pending_manual_verification',
  VerificationExpired: 'verification_expired',
} as const

export type AlpacaPlaidVerificationStatus =
  (typeof AlpacaPlaidVerificationStatus)[keyof typeof AlpacaPlaidVerificationStatus]
export type AlpacaPortfolioHistory = {
  __typename?: 'AlpacaPortfolioHistory'
  baseValue: Scalars['Float']
  equity: Array<Maybe<Scalars['Float']>>
  profitLoss: Array<Maybe<Scalars['Float']>>
  profitLossPct: Array<Maybe<Scalars['Float']>>
  timeframe: Scalars['String']
  timestamp: Array<Scalars['Int']>
}

/**  Alpaca positions */
export type AlpacaPosition = {
  __typename?: 'AlpacaPosition'
  assetClass: Scalars['String']
  avgEntityPrice?: Maybe<Scalars['String']>
  avgEntrySwapRate?: Maybe<Scalars['String']>
  changeToday: Scalars['String']
  complianceStatus: ComplianceStatus
  costBasis: Scalars['String']
  currentPrice: Scalars['String']
  exchange: Scalars['String']
  lastdayPrice: Scalars['String']
  marketValue: Scalars['String']
  name?: Maybe<Scalars['String']>
  qty: Scalars['String']
  side: Scalars['String']
  swapRate?: Maybe<Scalars['String']>
  symbol: Scalars['String']
  type?: Maybe<Scalars['String']>
  unrealizedIntradayPl?: Maybe<Scalars['String']>
  unrealizedIntradayPlpc?: Maybe<Scalars['String']>
  unrealizedPl: Scalars['String']
  unrealizedPlpc: Scalars['String']
  usd?: Maybe<Scalars['String']>
}

export const AlpacaTaxIdType = {
  ArgArCuit: 'ARG_AR_CUIT',
  AusAbn: 'AUS_ABN',
  AusTfn: 'AUS_TFN',
  BolNit: 'BOL_NIT',
  BraCpf: 'BRA_CPF',
  ChlRut: 'CHL_RUT',
  ColNit: 'COL_NIT',
  CriNite: 'CRI_NITE',
  DeuTaxId: 'DEU_TAX_ID',
  DomRnc: 'DOM_RNC',
  EcuRuc: 'ECU_RUC',
  FraSpi: 'FRA_SPI',
  GbrNino: 'GBR_NINO',
  GbrUtr: 'GBR_UTR',
  GtmNit: 'GTM_NIT',
  HndRtn: 'HND_RTN',
  HunTin: 'HUN_TIN',
  IdnKtp: 'IDN_KTP',
  IndPan: 'IND_PAN',
  IsrTaxId: 'ISR_TAX_ID',
  ItaTaxId: 'ITA_TAX_ID',
  JpnTaxId: 'JPN_TAX_ID',
  MexRfc: 'MEX_RFC',
  NicRuc: 'NIC_RUC',
  NldTin: 'NLD_TIN',
  NotSpecified: 'NOT_SPECIFIED',
  PanRuc: 'PAN_RUC',
  PerRuc: 'PER_RUC',
  PryRuc: 'PRY_RUC',
  SgpAsgd: 'SGP_ASGD',
  SgpFin: 'SGP_FIN',
  SgpItr: 'SGP_ITR',
  SgpNric: 'SGP_NRIC',
  SlvNit: 'SLV_NIT',
  SweTaxId: 'SWE_TAX_ID',
  UryRut: 'URY_RUT',
  UsaSsn: 'USA_SSN',
  VenRif: 'VEN_RIF',
} as const

export type AlpacaTaxIdType =
  (typeof AlpacaTaxIdType)[keyof typeof AlpacaTaxIdType]
export type AlpacaTradingConfigurations = {
  __typename?: 'AlpacaTradingConfigurations'
  dtbpCheck: Scalars['String']
  fractionalTrading: Scalars['Boolean']
  maxMarginMultiplier: Scalars['String']
  noShorting: Scalars['Boolean']
  pdtCheck: Scalars['String']
  suspendTrade: Scalars['Boolean']
  tradeConfirmEmail: Scalars['String']
}

export type AlpacaTradingConfigurationsInput = {
  dtbpCheck: Scalars['String']
  fractionalTrading: Scalars['Boolean']
  maxMarginMultiplier: Scalars['String']
  noShorting: Scalars['Boolean']
  pdtCheck: Scalars['String']
  suspendTrade: Scalars['Boolean']
  tradeConfirmEmail: Scalars['String']
}

export type AlpacaTrustedContact = {
  __typename?: 'AlpacaTrustedContact'
  city?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  emailAddress?: Maybe<Scalars['String']>
  familyName: Scalars['String']
  givenName: Scalars['String']
  phoneNumber?: Maybe<Scalars['String']>
  postalCode?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  streetAddress?: Maybe<Scalars['String']>
}

export type AlpacaTrustedContactInput = {
  city?: InputMaybe<Scalars['String']>
  country?: InputMaybe<Scalars['String']>
  emailAddress?: InputMaybe<Scalars['String']>
  familyName: Scalars['String']
  givenName: Scalars['String']
  phoneNumber?: InputMaybe<Scalars['String']>
  postalCode?: InputMaybe<Scalars['String']>
  state?: InputMaybe<Scalars['String']>
  streetAddress?: InputMaybe<Scalars['String']>
}

export const AlpacaVisaType = {
  B1: 'B1',
  B2: 'B2',
  Daca: 'DACA',
  E1: 'E1',
  E2: 'E2',
  E3: 'E3',
  F1: 'F1',
  G4: 'G4',
  H1B: 'H1B',
  J1: 'J1',
  L1: 'L1',
  O1: 'O1',
  Other: 'OTHER',
  Tn1: 'TN1',
} as const

export type AlpacaVisaType =
  (typeof AlpacaVisaType)[keyof typeof AlpacaVisaType]
export type AnalystRatings = {
  __typename?: 'AnalystRatings'
  buy: Scalars['Int']
  hold: Scalars['Int']
  rating: Scalars['Float']
  sell: Scalars['Int']
  sentiment: AnalystRatingsSentiment
  strongBuy: Scalars['Int']
  strongSell: Scalars['Int']
  symbol: Scalars['String']
  targetPrice?: Maybe<Scalars['Float']>
}

export const AnalystRatingsSentiment = {
  Buy: 'Buy',
  Hold: 'Hold',
  Mixed: 'Mixed',
  Sell: 'Sell',
} as const

export type AnalystRatingsSentiment =
  (typeof AnalystRatingsSentiment)[keyof typeof AnalystRatingsSentiment]
export type Api = {
  __typename?: 'Api'
  name: Scalars['String']
}

export const AssetType = {
  Etf: 'ETF',
  MutualFund: 'MutualFund',
  Stock: 'Stock',
} as const

export type AssetType = (typeof AssetType)[keyof typeof AssetType]
export type Basket = {
  __typename?: 'Basket'
  basketSourceRefId?: Maybe<Scalars['String']>
  createdAt: Scalars['String']
  id: Scalars['ID']
  name: Scalars['String']
  numOfTransactions: Scalars['Int']
  status: BasketStatus
  submittedValue: Scalars['Float']
  targetHoldings: Array<BasketHolding>
  transactions: BasketTransactionPage
  type: BasketType
  updatedAt: Scalars['String']
}

export type BasketTransactionsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  nextToken?: InputMaybe<Scalars['String']>
}

export type BasketHolding = {
  __typename?: 'BasketHolding'
  complianceStatus: ComplianceStatus
  currencyCode: Scalars['String']
  name: Scalars['String']
  symbol: Scalars['String']
  targetWeight: Scalars['Float']
  type: AssetType
}

export type BasketIdea = {
  __typename?: 'BasketIdea'
  category: Scalars['String']
  count: Scalars['Int']
  description: Scalars['String']
  enabled: Scalars['Boolean']
  id: Scalars['ID']
  paywall: Scalars['Boolean']
  previewLogoUrls: Array<Scalars['String']>
  title: Scalars['String']
  updatedAt: Scalars['String']
}

export type BasketIdeaHolding = {
  __typename?: 'BasketIdeaHolding'
  complianceStatus: ComplianceStatus
  logoUrl: Scalars['String']
  name: Scalars['String']
  symbol: Scalars['String']
  type: Scalars['String']
  weight: Scalars['Float']
}

export type BasketIdeaOrder = {
  __typename?: 'BasketIdeaOrder'
  basketIdeaId: Scalars['String']
  createdAt: Scalars['String']
  executionId: Scalars['ID']
  status: BasketTransactionStatus
  type: BasketTransactionOrderType
  updatedAt: Scalars['String']
}

export type BasketOrder = {
  __typename?: 'BasketOrder'
  basketId: Scalars['String']
  createdAt: Scalars['String']
  executionId: Scalars['ID']
  status: BasketTransactionStatus
  type: BasketTransactionOrderType
  updatedAt: Scalars['String']
}

export type BasketPage = {
  __typename?: 'BasketPage'
  items: Array<Basket>
  nextToken?: Maybe<Scalars['String']>
}

export type BasketSource = {
  __typename?: 'BasketSource'
  currencyCode: Scalars['String']
  holdings: Array<BasketWeight>
  id: Scalars['ID']
  targetValue: Scalars['Float']
  type: BasketSourceType
}

export type BasketSourceInput = {
  currencyCode: Scalars['String']
  holdings: Array<BasketWeightInput>
  id: Scalars['String']
  targetValue: Scalars['Float']
  type: BasketSourceType
}

export const BasketSourceType = {
  BasketIdea: 'BasketIdea',
  Etf: 'ETF',
  MutualFund: 'MutualFund',
} as const

export type BasketSourceType =
  (typeof BasketSourceType)[keyof typeof BasketSourceType]
export const BasketStatus = {
  Initialized: 'Initialized',
  UpdateCompleted: 'UpdateCompleted',
  UpdateCompletedWithProblems: 'UpdateCompletedWithProblems',
  UpdateInProgress: 'UpdateInProgress',
} as const

export type BasketStatus = (typeof BasketStatus)[keyof typeof BasketStatus]
export type BasketTransaction = {
  __typename?: 'BasketTransaction'
  accountId: Scalars['String']
  accountName?: Maybe<Scalars['String']>
  basketSource: BasketSource
  createdAt: Scalars['String']
  currencyCode: Scalars['String']
  executionId: Scalars['String']
  id: Scalars['ID']
  institution?: Maybe<Scalars['String']>
  orders: Array<BasketTransactionOrder>
  status: BasketTransactionStatus
  submittedValue?: Maybe<Scalars['Float']>
  targetValue: Scalars['Float']
  updatedAt: Scalars['String']
}

export type BasketTransactionOrder = {
  __typename?: 'BasketTransactionOrder'
  accountId: Scalars['String']
  accountName?: Maybe<Scalars['String']>
  complianceStatus: ComplianceStatus
  currencyCode: Scalars['String']
  institution?: Maybe<Scalars['String']>
  name: Scalars['String']
  status?: Maybe<OrderSubmissionStatus>
  symbol: Scalars['String']
  targetValue: Scalars['Float']
  targetWeight: Scalars['Float']
  type: BasketTransactionOrderType
}

export const BasketTransactionOrderType = {
  Buy: 'Buy',
  Sell: 'Sell',
} as const

export type BasketTransactionOrderType =
  (typeof BasketTransactionOrderType)[keyof typeof BasketTransactionOrderType]
export type BasketTransactionPage = {
  __typename?: 'BasketTransactionPage'
  items: Array<BasketTransaction>
  nextToken?: Maybe<Scalars['String']>
}

export const BasketTransactionStatus = {
  Completed: 'Completed',
  CompletedWithProblems: 'CompletedWithProblems',
  InProgress: 'InProgress',
  Queued: 'Queued',
} as const

export type BasketTransactionStatus =
  (typeof BasketTransactionStatus)[keyof typeof BasketTransactionStatus]
export const BasketType = {
  BasketIdea: 'BasketIdea',
  Custom: 'Custom',
  Etf: 'ETF',
  MutualFund: 'MutualFund',
} as const

export type BasketType = (typeof BasketType)[keyof typeof BasketType]
export type BasketWeight = {
  __typename?: 'BasketWeight'
  symbol: Scalars['String']
  weight: Scalars['Float']
}

export type BasketWeightInput = {
  symbol: Scalars['String']
  weight: Scalars['Float']
}

export const ComplianceStatus = {
  Compliant: 'Compliant',
  NonCompliant: 'NonCompliant',
  Questionable: 'Questionable',
  Unrated: 'Unrated',
} as const

export type ComplianceStatus =
  (typeof ComplianceStatus)[keyof typeof ComplianceStatus]
export type CreateBasketInput = {
  basketSourceRefId?: InputMaybe<Scalars['String']>
  holdings: Array<BasketWeightInput>
  name: Scalars['String']
  type: BasketType
}

export type CreateSnaptradeConnectionUrlInput = {
  broker?: InputMaybe<Scalars['String']>
  connectionType: SnaptradeConnectionType
  reconnect?: InputMaybe<Scalars['String']>
}

export type CurrencyTag = {
  __typename?: 'CurrencyTag'
  interestEarnings: Scalars['String']
}

export const Direction = {
  Asc: 'ASC',
  Desc: 'DESC',
} as const

export type Direction = (typeof Direction)[keyof typeof Direction]
export type DocumentUploadUrlResponse = {
  __typename?: 'DocumentUploadUrlResponse'
  objectKey: Scalars['String']
  url: Scalars['String']
}

export type Esg = {
  __typename?: 'ESG'
  adultContent?: Maybe<Scalars['Boolean']>
  alcoholic?: Maybe<Scalars['Boolean']>
  animalTesting?: Maybe<Scalars['Boolean']>
  antitrust?: Maybe<Scalars['String']>
  asianEmployeePercentage?: Maybe<Scalars['Float']>
  asianManagementPercentage?: Maybe<Scalars['Float']>
  blackEmployeePercentage?: Maybe<Scalars['Float']>
  blackManagementPercentage?: Maybe<Scalars['Float']>
  carbonReductionPolicy?: Maybe<Scalars['String']>
  catholic?: Maybe<Scalars['Boolean']>
  climateStrategy?: Maybe<Scalars['String']>
  /**   waterEfficiencyConsumption: unknown */
  co2EmissionScope1?: Maybe<Scalars['Float']>
  co2EmissionScope2?: Maybe<Scalars['Float']>
  co2EmissionScope3?: Maybe<Scalars['Float']>
  co2EmissionTotal?: Maybe<Scalars['Float']>
  coalEnergy?: Maybe<Scalars['Boolean']>
  /**   sustainableForestryPolicy: unknown */
  ecofriendlyPackaging?: Maybe<Scalars['String']>
  environmentScore?: Maybe<Scalars['Float']>
  environmentalReporting?: Maybe<Scalars['Boolean']>
  firearms?: Maybe<Scalars['Boolean']>
  fuelEfficiencyConsumption?: Maybe<Scalars['String']>
  furLeather?: Maybe<Scalars['Boolean']>
  gambling?: Maybe<Scalars['Boolean']>
  gmo?: Maybe<Scalars['Boolean']>
  governanceScore?: Maybe<Scalars['Float']>
  hispanicLatinoEmployeePercentage?: Maybe<Scalars['Float']>
  hispanicLatinoManagementPercentage?: Maybe<Scalars['Float']>
  humanRightsPolicy?: Maybe<Scalars['String']>
  militaryContract?: Maybe<Scalars['Boolean']>
  nuclear?: Maybe<Scalars['Boolean']>
  palmOil?: Maybe<Scalars['Boolean']>
  pesticides?: Maybe<Scalars['Boolean']>
  privacyPolicy?: Maybe<Scalars['String']>
  recallPolicySafety?: Maybe<Scalars['Boolean']>
  recyclingPolicy?: Maybe<Scalars['String']>
  socialScore?: Maybe<Scalars['Float']>
  stakeholderEngagement?: Maybe<Scalars['Boolean']>
  symbol: Scalars['String']
  tobacco?: Maybe<Scalars['Boolean']>
  totalESGScore?: Maybe<Scalars['Float']>
  totalWomenPercentage?: Maybe<Scalars['Float']>
  weapons?: Maybe<Scalars['Boolean']>
  whiteEmployeePercentage?: Maybe<Scalars['Float']>
  whiteManagementPercentage?: Maybe<Scalars['Float']>
  womenManagementPercentage?: Maybe<Scalars['Float']>
  /**   hazardousSubstances: unknown */
  workplaceHealthSafety?: Maybe<Scalars['String']>
}

export type Etf = {
  __typename?: 'ETF'
  aum: Scalars['Float']
  complianceStatus?: Maybe<ComplianceStatus>
  compliantHoldingsCount: Scalars['Int']
  compliantPercentage: Scalars['Float']
  description: Scalars['String']
  divYield: Scalars['Float']
  exchange: Scalars['String']
  expenseRatio: Scalars['Float']
  holdings: FundHoldingConnection
  holdingsAsOfDate: Scalars['String']
  holdingsCount: Scalars['Int']
  issueType: Scalars['String']
  name: Scalars['String']
  nonCompliantHoldingsCount: Scalars['Int']
  nonCompliantPercentage: Scalars['Float']
  questionableHoldingsCount: Scalars['Int']
  questionablePercentage: Scalars['Float']
  quote: IexQuote
  region: Scalars['String']
  symbol: Scalars['ID']
  tags: FundTags
  unratedHoldingsCount: Scalars['Int']
  unratedPercentage: Scalars['Float']
  version: Scalars['String']
}

export type EtfHoldingsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<FundHoldingFilter>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type EtfConnection = {
  __typename?: 'ETFConnection'
  edges?: Maybe<Array<EtfEdge>>
  pageInfo: PageInfo
}

export type EtfEdge = {
  __typename?: 'ETFEdge'
  cursor?: Maybe<Scalars['String']>
  node: Etf
}

export type Eod = {
  __typename?: 'Eod'
  realtime: Array<Maybe<EodRealTimeResponse>>
}

export type EodRealtimeArgs = {
  symbols: Array<InputMaybe<Scalars['ID']>>
}

export type EodRealTimeResponse = {
  __typename?: 'EodRealTimeResponse'
  change: Scalars['Float']
  change_p: Scalars['Float']
  close: Scalars['Float']
  code: Scalars['String']
  gmtoffset: Scalars['Int']
  high: Scalars['Float']
  low: Scalars['Float']
  open: Scalars['Float']
  previousClose: Scalars['Float']
  timestamp: Scalars['Int']
  volume: Scalars['Int']
}

export type ExchangeRates = {
  __typename?: 'ExchangeRates'
  AUD?: Maybe<Scalars['Float']>
  BGN?: Maybe<Scalars['Float']>
  BTC?: Maybe<Scalars['Float']>
  CAD?: Maybe<Scalars['Float']>
  CZK?: Maybe<Scalars['Float']>
  DKK?: Maybe<Scalars['Float']>
  EUR?: Maybe<Scalars['Float']>
  GBP?: Maybe<Scalars['Float']>
  HUF?: Maybe<Scalars['Float']>
  INR?: Maybe<Scalars['Float']>
  NOK?: Maybe<Scalars['Float']>
  PLN?: Maybe<Scalars['Float']>
  SEK?: Maybe<Scalars['Float']>
  USD?: Maybe<Scalars['Float']>
}

export type ExternallyScreenedTag = {
  __typename?: 'ExternallyScreenedTag'
  factsheetURL: Scalars['String']
  productURL: Scalars['String']
  shariahCertificateURL?: Maybe<Scalars['String']>
  shariahCertifier: Scalars['String']
  shariahCertifierURL: Scalars['String']
}

export type FundHolding = {
  __typename?: 'FundHolding'
  asOfDate?: Maybe<Scalars['String']>
  complianceStatus: ComplianceStatus
  cusip?: Maybe<Scalars['String']>
  exchange?: Maybe<Scalars['String']>
  isin?: Maybe<Scalars['String']>
  name: Scalars['String']
  region?: Maybe<Scalars['String']>
  symbol?: Maybe<Scalars['String']>
  weightPercentage: Scalars['Float']
}

export type FundHoldingConnection = {
  __typename?: 'FundHoldingConnection'
  edges?: Maybe<Array<FundHoldingEdge>>
  pageInfo: PageInfo
}

export type FundHoldingEdge = {
  __typename?: 'FundHoldingEdge'
  cursor?: Maybe<Scalars['String']>
  node: FundHolding
}

export type FundHoldingFilter = {
  complianceStatus?: InputMaybe<ComplianceStatus>
}

export type FundTags = {
  __typename?: 'FundTags'
  currency?: Maybe<CurrencyTag>
  externallyScreened?: Maybe<ExternallyScreenedTag>
}

export type FuseInstitution = {
  __typename?: 'FuseInstitution'
  countryCodes: Array<Scalars['String']>
  id: Scalars['String']
  logo?: Maybe<FuseInstitutionLogo>
  name: Scalars['String']
  website?: Maybe<Scalars['String']>
}

export type FuseInstitutionLogo = {
  __typename?: 'FuseInstitutionLogo'
  format?: Maybe<Scalars['String']>
  image: Scalars['String']
  type: Scalars['String']
}

export type FuseMutations = {
  __typename?: 'FuseMutations'
  createFuseSession: FuseSession
  createLinkToken: Scalars['String']
  relinkSession: FuseSession
  saveLinkedAccounts: SuccessResponse
}

export type FuseMutationsCreateFuseSessionArgs = {
  countryCodes?: InputMaybe<Array<InputMaybe<FuseSupportedCountryCodes>>>
}

export type FuseMutationsCreateLinkTokenArgs = {
  connectionType?: InputMaybe<FuseSnapTradeConnectionType>
  countryCodes?: InputMaybe<Array<InputMaybe<FuseSupportedCountryCodes>>>
  institutionId: Scalars['String']
  sessionClientSecret: Scalars['String']
}

export type FuseMutationsRelinkSessionArgs = {
  entityId: Scalars['String']
}

export type FuseMutationsSaveLinkedAccountsArgs = {
  publicToken: Scalars['String']
}

export type FuseSession = {
  __typename?: 'FuseSession'
  expiration: Scalars['String']
  sessionClientSecret: Scalars['String']
}

export const FuseSnapTradeConnectionType = {
  Read: 'read',
  Trade: 'trade',
} as const

export type FuseSnapTradeConnectionType =
  (typeof FuseSnapTradeConnectionType)[keyof typeof FuseSnapTradeConnectionType]
export const FuseSupportedCountryCodes = {
  Au: 'AU',
  Ca: 'CA',
  Gb: 'GB',
  In: 'IN',
  Nl: 'NL',
  Us: 'US',
} as const

export type FuseSupportedCountryCodes =
  (typeof FuseSupportedCountryCodes)[keyof typeof FuseSupportedCountryCodes]
export const GetOrderImpactAction = {
  Buy: 'BUY',
  Sell: 'SELL',
} as const

export type GetOrderImpactAction =
  (typeof GetOrderImpactAction)[keyof typeof GetOrderImpactAction]
export const GetOrderImpactOrderType = {
  Limit: 'Limit',
  Market: 'Market',
  StopLimit: 'StopLimit',
  StopLost: 'StopLost',
} as const

export type GetOrderImpactOrderType =
  (typeof GetOrderImpactOrderType)[keyof typeof GetOrderImpactOrderType]
export type GetOrderImpactResponse = {
  __typename?: 'GetOrderImpactResponse'
  action?: Maybe<Scalars['String']>
  balanceCash?: Maybe<Scalars['Float']>
  currencyCode?: Maybe<Scalars['String']>
  currencyName?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  estimatedCommissions?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['String']>
  orderType?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Float']>
  symbol?: Maybe<Scalars['String']>
  units?: Maybe<Scalars['Float']>
}

export type IexQuote = {
  __typename?: 'IexQuote'
  change: Scalars['Float']
  changePercent: Scalars['Float']
  companyName: Scalars['String']
  currency: Scalars['String']
  latestPrice: Scalars['Float']
  primaryExchange: Scalars['String']
}

export type InstitutionHealth = {
  __typename?: 'InstitutionHealth'
  healthStatus: InstitutionHealthStatus
  plaidHealth?: Maybe<PlaidInstitutionHealth>
}

export const InstitutionHealthStatus = {
  Degraded: 'Degraded',
  Healthy: 'Healthy',
} as const

export type InstitutionHealthStatus =
  (typeof InstitutionHealthStatus)[keyof typeof InstitutionHealthStatus]
/**   Invest */
export type Invest = {
  __typename?: 'Invest'
  account?: Maybe<AlpacaAccount>
  accountTradingInfo?: Maybe<AlpacaAccountTradingInfo>
  achRelationships?: Maybe<Array<Maybe<AlpacaAchRelationship>>>
  activities?: Maybe<Array<AlpacaAccountActivity>>
  asset?: Maybe<AlpacaAsset>
  bankRelationships?: Maybe<Array<AlpacaBankRelationship>>
  compliance?: Maybe<AlpacaCompliance>
  documentDownloadLink: Scalars['String']
  documents: Array<AlpacaDocumentMetadata>
  estimateOrder: AlpacaOrder
  fundTransfers?: Maybe<Array<AlpacaFundTransfer>>
  order?: Maybe<AlpacaOrder>
  orders?: Maybe<Array<AlpacaOrder>>
  portfolioHistory?: Maybe<AlpacaPortfolioHistory>
  position?: Maybe<Position>
  positions?: Maybe<Array<AlpacaPosition>>
}

/**   Invest */
export type InvestActivitiesArgs = {
  activityType?: InputMaybe<AlpacaAccountActivityType>
  after?: InputMaybe<Scalars['String']>
  date?: InputMaybe<Scalars['String']>
  direction?: InputMaybe<Scalars['String']>
  pageSize?: InputMaybe<Scalars['Int']>
  pageToken?: InputMaybe<Scalars['String']>
  until?: InputMaybe<Scalars['String']>
}

/**   Invest */
export type InvestAssetArgs = {
  symbol: Scalars['String']
}

/**   Invest */
export type InvestDocumentDownloadLinkArgs = {
  documentId: Scalars['String']
}

/**   Invest */
export type InvestDocumentsArgs = {
  end?: InputMaybe<Scalars['String']>
  start?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Scalars['String']>
}

/**   Invest */
export type InvestEstimateOrderArgs = {
  extendedHours?: InputMaybe<Scalars['Boolean']>
  limitPrice?: InputMaybe<Scalars['String']>
  notional?: InputMaybe<Scalars['String']>
  qty?: InputMaybe<Scalars['String']>
  side: AlpacaOrderSide
  symbol: Scalars['String']
  timeInForce: AlpacaOrderTimeInForce
  type: AlpacaOrderType
}

/**   Invest */
export type InvestFundTransfersArgs = {
  direction?: InputMaybe<AlpacaFundTransferDirection>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

/**   Invest */
export type InvestOrderArgs = {
  id: Scalars['String']
}

/**   Invest */
export type InvestOrdersArgs = {
  after?: InputMaybe<Scalars['String']>
  direction?: InputMaybe<Scalars['String']>
  limit?: InputMaybe<Scalars['Int']>
  status?: InputMaybe<Scalars['String']>
  symbols?: InputMaybe<Scalars['String']>
  until?: InputMaybe<Scalars['String']>
}

/**   Invest */
export type InvestPortfolioHistoryArgs = {
  dateEnd?: InputMaybe<Scalars['String']>
  extendedHours?: InputMaybe<Scalars['Boolean']>
  period?: InputMaybe<Scalars['String']>
  sinceMidnight?: InputMaybe<Scalars['Boolean']>
  timeframe?: InputMaybe<Scalars['String']>
}

/**   Invest */
export type InvestPositionArgs = {
  symbol: Scalars['String']
}

/**   Invest */
export type InvestPositionsArgs = {
  symbol?: InputMaybe<Scalars['String']>
}

export type InvestMutations = {
  __typename?: 'InvestMutations'
  cancelAllOpenAlpacaOrders: SuccessResponse
  cancelAlpacaOrder: SuccessResponse
  closeAlpacaPosition: AlpacaOrder
  createAlpacaAccount: AlpacaAccount
  createAlpacaAchRelationship: AlpacaAchRelationship
  createAlpacaBankRelationship: AlpacaBankRelationship
  createAlpacaFundTransferEntity: AlpacaFundTransfer
  createDocumentUploadUrl: DocumentUploadUrlResponse
  deleteAlpacaAchRelationship: SuccessResponse
  deleteAlpacaBankRelationship: SuccessResponse
  deleteAlpacaFundTransfer: SuccessResponse
  deleteAlpacaWireTransfer: SuccessResponse
  placeAlpacaOrder: AlpacaOrder
  replaceAlpacaOrder: AlpacaOrder
  updateAlpacaAccount: AlpacaAccount
  uploadAlpacaDocuments: SuccessResponse
}

export type InvestMutationsCancelAlpacaOrderArgs = {
  orderId: Scalars['String']
}

export type InvestMutationsCloseAlpacaPositionArgs = {
  percentage?: InputMaybe<Scalars['String']>
  qty?: InputMaybe<Scalars['String']>
  symbol: Scalars['String']
}

export type InvestMutationsCreateAlpacaAccountArgs = {
  agreements: Array<AlpacaAgreementInput>
  contact: AlpacaContactInput
  disclosures: AlpacaDisclosuresInput
  documents?: InputMaybe<Array<InputMaybe<AlpacaDocumentUploadInput>>>
  enabledAssets: Array<InputMaybe<AlpacaAssetClass>>
  identity: AlpacaIdentityInput
  trustedContact?: InputMaybe<AlpacaTrustedContactInput>
}

export type InvestMutationsCreateAlpacaAchRelationshipArgs = {
  accountOwnerName: Scalars['String']
  bankAccountNumber: Scalars['String']
  bankAccountType: AlpacaBankAccountType
  bankRoutingNumber: Scalars['String']
  instant?: InputMaybe<Scalars['Boolean']>
  nickname?: InputMaybe<Scalars['String']>
  processorToken?: InputMaybe<Scalars['String']>
}

export type InvestMutationsCreateAlpacaBankRelationshipArgs = {
  accountNumber: Scalars['String']
  bankCode: Scalars['String']
  bankCodeType: AlpacaBankCodeType
  city?: InputMaybe<Scalars['String']>
  country?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  postalCode?: InputMaybe<Scalars['String']>
  stateProvince?: InputMaybe<Scalars['String']>
  streetAddress?: InputMaybe<Scalars['String']>
}

export type InvestMutationsCreateAlpacaFundTransferEntityArgs = {
  additional_information?: InputMaybe<Scalars['String']>
  amount: Scalars['String']
  bankId?: InputMaybe<Scalars['String']>
  direction: AlpacaFundTransferDirection
  fee_payment_method?: InputMaybe<AlpacaFundTransferFeePaymentMethod>
  relationshipId?: InputMaybe<Scalars['String']>
  timing: AlpacaFundTransferTiming
  transferType: AlpacaFundTransferType
}

export type InvestMutationsCreateDocumentUploadUrlArgs = {
  fileName: Scalars['String']
}

export type InvestMutationsDeleteAlpacaAchRelationshipArgs = {
  relationshipId: Scalars['String']
}

export type InvestMutationsDeleteAlpacaBankRelationshipArgs = {
  bankId: Scalars['String']
}

export type InvestMutationsDeleteAlpacaFundTransferArgs = {
  transferId: Scalars['String']
}

export type InvestMutationsDeleteAlpacaWireTransferArgs = {
  transferId: Scalars['String']
}

export type InvestMutationsPlaceAlpacaOrderArgs = {
  extendedHours?: InputMaybe<Scalars['Boolean']>
  limitPrice?: InputMaybe<Scalars['String']>
  notional?: InputMaybe<Scalars['String']>
  qty?: InputMaybe<Scalars['String']>
  side: AlpacaOrderSide
  symbol: Scalars['String']
  timeInForce: AlpacaOrderTimeInForce
  type: AlpacaOrderType
}

export type InvestMutationsReplaceAlpacaOrderArgs = {
  limitPrice?: InputMaybe<Scalars['String']>
  orderId: Scalars['String']
  qty: Scalars['String']
  timeInForce: AlpacaOrderTimeInForce
}

export type InvestMutationsUpdateAlpacaAccountArgs = {
  agreements?: InputMaybe<Array<AlpacaAgreementInput>>
  contact?: InputMaybe<UpdateAlpacaContactInput>
  disclosures?: InputMaybe<UpdateAlpacaDisclosuresInput>
  enabledAssets?: InputMaybe<Array<InputMaybe<AlpacaAssetClass>>>
  identity?: InputMaybe<UpdateAlpacaIdentityInput>
  trustedContact?: InputMaybe<AlpacaTrustedContactInput>
}

export type InvestMutationsUploadAlpacaDocumentsArgs = {
  documents: Array<AlpacaDocumentUploadInput>
}

export type Market = {
  __typename?: 'Market'
  alpaca?: Maybe<AlpacaMarketData>
}

export type Mutation = {
  __typename?: 'Mutation'
  account?: Maybe<AccountMutations>
  invest?: Maybe<InvestMutations>
  notifications?: Maybe<NotificationMutations>
  plaid?: Maybe<PlaidMutations>
  portfolio?: Maybe<PortfolioMutations>
  signupForWaitlist: WaitlistRegistrationResponse
  zakat?: Maybe<ZakatMutations>
}

export type MutationSignupForWaitlistArgs = {
  referralCode?: InputMaybe<Scalars['String']>
  referralSource?: InputMaybe<Scalars['String']>
}

export type MutualFund = {
  __typename?: 'MutualFund'
  complianceStatus?: Maybe<ComplianceStatus>
  compliantHoldingsCount: Scalars['Int']
  compliantPercentage: Scalars['Float']
  description: Scalars['String']
  expenseRatio: Scalars['Float']
  holdings: FundHoldingConnection
  holdingsAsOfDate: Scalars['String']
  holdingsCount: Scalars['Int']
  inceptionDate?: Maybe<Scalars['String']>
  investmentSegment?: Maybe<Scalars['String']>
  issueType: Scalars['String']
  name: Scalars['String']
  nonCompliantHoldingsCount: Scalars['Int']
  nonCompliantPercentage: Scalars['Float']
  questionableHoldingsCount: Scalars['Int']
  questionablePercentage: Scalars['Float']
  region: Scalars['String']
  symbol: Scalars['ID']
  tags: FundTags
  unratedHoldingsCount: Scalars['Int']
  unratedPercentage: Scalars['Float']
  version: Scalars['String']
}

export type MutualFundHoldingsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<FundHoldingFilter>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type MutualFundConnection = {
  __typename?: 'MutualFundConnection'
  edges?: Maybe<Array<MutualFundEdge>>
  pageInfo: PageInfo
}

export type MutualFundEdge = {
  __typename?: 'MutualFundEdge'
  cursor?: Maybe<Scalars['String']>
  node: MutualFund
}

export type NotificationMutations = {
  __typename?: 'NotificationMutations'
  registerDevice: SuccessResponse
  togglePushNotifications: TogglePushNotificationsResponse
  unregisterDevice: SuccessResponse
}

export type NotificationMutationsRegisterDeviceArgs = {
  token: Scalars['String']
}

export type NotificationMutationsTogglePushNotificationsArgs = {
  enabled: Scalars['Boolean']
  token: Scalars['String']
}

export type NotificationMutationsUnregisterDeviceArgs = {
  token: Scalars['String']
}

export const OrderSubmissionStatus = {
  Completed: 'Completed',
  Failed: 'Failed',
  Pending: 'Pending',
} as const

export type OrderSubmissionStatus =
  (typeof OrderSubmissionStatus)[keyof typeof OrderSubmissionStatus]
export type PageInfo = {
  __typename?: 'PageInfo'
  endCursor?: Maybe<Scalars['String']>
  hasNextPage: Scalars['Boolean']
  hasPreviousPage: Scalars['Boolean']
  startCursor?: Maybe<Scalars['String']>
}

export type PlaidAccountMeta = {
  id: Scalars['String']
  mask: Scalars['String']
  name: Scalars['String']
  subtype: Scalars['String']
  type: Scalars['String']
}

export type PlaidInstitution = {
  id: Scalars['String']
  name?: InputMaybe<Scalars['String']>
}

export type PlaidInstitutionHealth = {
  __typename?: 'PlaidInstitutionHealth'
  errorInstitution: Scalars['Float']
  errorPlaid: Scalars['Float']
  lastStatusChange: Scalars['String']
  success: Scalars['Float']
}

export type PlaidLinkToken = {
  __typename?: 'PlaidLinkToken'
  linkToken: Scalars['String']
}

export type PlaidMutations = {
  __typename?: 'PlaidMutations'
  createLinkToken: PlaidLinkToken
  relinkToken: SuccessResponse
  saveLinkedAccount: SuccessResponse
}

export type PlaidMutationsCreateLinkTokenArgs = {
  accountId?: InputMaybe<Scalars['String']>
  countryCodes?: InputMaybe<Array<InputMaybe<SupportedCountryCodes>>>
}

export type PlaidMutationsRelinkTokenArgs = {
  accountId: Scalars['String']
}

export type PlaidMutationsSaveLinkedAccountArgs = {
  accounts: Array<PlaidAccountMeta>
  institution: PlaidInstitution
  publicToken: Scalars['String']
}

export type Portfolio = {
  __typename?: 'Portfolio'
  account?: Maybe<PortfolioAccount>
  accounts?: Maybe<PortfolioAccountConnection>
  basket?: Maybe<Basket>
  basketIdeaHoldings: Array<BasketIdeaHolding>
  basketIdeas: Array<BasketIdea>
  basketTransaction: BasketTransaction
  baskets: BasketPage
  fuseInstitution: FuseInstitution
  holdings?: Maybe<PortfolioHoldingConnection>
  institution: PortfolioGetInstitutionData
  institutions?: Maybe<PortfolioInstitutionConnection>
  position?: Maybe<Position>
  relatedBasketIdeas: Array<BasketIdea>
  snaptrade: SnapTrade
  transactions?: Maybe<PortfolioTransactionConnection>
}

export type PortfolioAccountArgs = {
  accountId?: InputMaybe<Scalars['String']>
}

export type PortfolioAccountsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type PortfolioBasketArgs = {
  basketId: Scalars['ID']
}

export type PortfolioBasketIdeaHoldingsArgs = {
  basketIdea: Scalars['ID']
}

export type PortfolioBasketTransactionArgs = {
  id: Scalars['ID']
}

export type PortfolioBasketsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  nextToken?: InputMaybe<Scalars['String']>
}

export type PortfolioFuseInstitutionArgs = {
  institutionId: Scalars['String']
}

export type PortfolioHoldingsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<PortfolioHoldingFilter>
  first?: InputMaybe<Scalars['Int']>
  holdingsId?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<PortfolioHoldingSort>>
}

export type PortfolioInstitutionArgs = {
  countryCodes?: InputMaybe<Array<InputMaybe<SupportedCountryCodes>>>
  institutionId: Scalars['String']
}

export type PortfolioInstitutionsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type PortfolioPositionArgs = {
  symbol: Scalars['String']
}

export type PortfolioRelatedBasketIdeasArgs = {
  symbol: Scalars['ID']
}

export type PortfolioTransactionsArgs = {
  accountId: Scalars['String']
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type PortfolioAccount = {
  __typename?: 'PortfolioAccount'
  accountHidden?: Maybe<Scalars['Boolean']>
  accountId?: Maybe<Scalars['String']>
  aggregator?: Maybe<Scalars['String']>
  allTimeChange?: Maybe<Scalars['Float']>
  allTimeChangePercent?: Maybe<Scalars['Float']>
  balance?: Maybe<Scalars['Float']>
  buyingPower?: Maybe<Scalars['Float']>
  cash?: Maybe<Array<Maybe<PortfolioAccountCash>>>
  compliantPercentage?: Maybe<Scalars['Float']>
  compliantPercentageWithFunds?: Maybe<Scalars['Float']>
  currencyCode?: Maybe<Scalars['String']>
  groupId?: Maybe<Scalars['String']>
  holdingSyncStatus?: Maybe<SyncStatus>
  holdingsId?: Maybe<Scalars['String']>
  holdingsLastSyncTime?: Maybe<Scalars['String']>
  institution?: Maybe<Scalars['String']>
  institutionId?: Maybe<Scalars['String']>
  lastSyncTime?: Maybe<Scalars['String']>
  latestChange?: Maybe<Scalars['Float']>
  latestChangePercent?: Maybe<Scalars['Float']>
  latestPrice?: Maybe<Scalars['Float']>
  linkStatus?: Maybe<Scalars['String']>
  mask?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  nickname?: Maybe<Scalars['String']>
  nonCompliantPercentage?: Maybe<Scalars['Float']>
  nonCompliantPercentageWithFunds?: Maybe<Scalars['Float']>
  priceAllTime?: Maybe<Scalars['Float']>
  questionablePercentage?: Maybe<Scalars['Float']>
  questionablePercentageWithFunds?: Maybe<Scalars['Float']>
  subtype?: Maybe<Scalars['String']>
  tradingEnabled?: Maybe<Scalars['Boolean']>
  transactionsLastSyncTime?: Maybe<Scalars['String']>
  transactionsSyncStatus?: Maybe<SyncStatus>
  type?: Maybe<Scalars['String']>
  unratedPercentage?: Maybe<Scalars['Float']>
  unratedPercentageWithFunds?: Maybe<Scalars['Float']>
  vendor?: Maybe<Scalars['String']>
}

export type PortfolioAccountCash = {
  __typename?: 'PortfolioAccountCash'
  available?: Maybe<Scalars['Float']>
  currency?: Maybe<Scalars['String']>
  current?: Maybe<Scalars['Float']>
  lastUpdatedDate?: Maybe<Scalars['String']>
}

export type PortfolioAccountConnection = {
  __typename?: 'PortfolioAccountConnection'
  edges?: Maybe<Array<PortfolioAccountEdge>>
  pageInfo: PageInfo
}

export type PortfolioAccountEdge = {
  __typename?: 'PortfolioAccountEdge'
  cursor?: Maybe<Scalars['String']>
  node: PortfolioAccount
}

export type PortfolioAccountFilter = {
  complianceStatus?: InputMaybe<ComplianceStatus>
}

export type PortfolioGetInstitutionData = {
  __typename?: 'PortfolioGetInstitutionData'
  institution?: Maybe<PortfolioInstitutionData>
}

export type PortfolioHolding = {
  __typename?: 'PortfolioHolding'
  allTimeChange?: Maybe<Scalars['Float']>
  allTimeChangePercent?: Maybe<Scalars['Float']>
  change?: Maybe<Scalars['Float']>
  changePercent?: Maybe<Scalars['Float']>
  complianceStatus?: Maybe<ComplianceStatus>
  costBasis?: Maybe<Scalars['Float']>
  currencyCode?: Maybe<Scalars['String']>
  iexSymbol?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Float']>
  quantity?: Maybe<Scalars['Float']>
  rawCurrencyCode?: Maybe<Scalars['String']>
  rawValue?: Maybe<Scalars['Float']>
  symbol?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['Float']>
  weight?: Maybe<Scalars['Float']>
}

export type PortfolioHoldingConnection = {
  __typename?: 'PortfolioHoldingConnection'
  edges?: Maybe<Array<PortfolioHoldingEdge>>
  pageInfo: PageInfo
}

export type PortfolioHoldingEdge = {
  __typename?: 'PortfolioHoldingEdge'
  cursor?: Maybe<Scalars['String']>
  node: PortfolioHolding
}

export type PortfolioHoldingFilter = {
  complianceStatus?: InputMaybe<ComplianceStatus>
}

export type PortfolioHoldingSort = {
  direction: Direction
  sort: PortfolioHoldingSortDimension
}

export const PortfolioHoldingSortDimension = {
  Name: 'NAME',
  Weight: 'WEIGHT',
} as const

export type PortfolioHoldingSortDimension =
  (typeof PortfolioHoldingSortDimension)[keyof typeof PortfolioHoldingSortDimension]
export type PortfolioInstitution = {
  __typename?: 'PortfolioInstitution'
  accounts?: Maybe<PortfolioAccountConnection>
  institutionId?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type PortfolioInstitutionAccountsArgs = {
  accountId?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<PortfolioAccountFilter>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type PortfolioInstitutionConnection = {
  __typename?: 'PortfolioInstitutionConnection'
  edges?: Maybe<Array<PortfolioInstitutionEdge>>
  pageInfo: PageInfo
}

export type PortfolioInstitutionData = {
  __typename?: 'PortfolioInstitutionData'
  countryCodes: Array<Scalars['String']>
  institutionId: Scalars['String']
  investmentUpdateHealth: InstitutionHealth
  logo?: Maybe<Scalars['String']>
  name: Scalars['String']
  primaryColor?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
}

export type PortfolioInstitutionEdge = {
  __typename?: 'PortfolioInstitutionEdge'
  cursor?: Maybe<Scalars['String']>
  node: PortfolioInstitution
}

export type PortfolioMutations = {
  __typename?: 'PortfolioMutations'
  buyBasket?: Maybe<BasketOrder>
  createAndBuyBasket?: Maybe<BasketOrder>
  createBasket?: Maybe<Basket>
  deleteBasket?: Maybe<SuccessResponse>
  fuse: FuseMutations
  renameBasket?: Maybe<SuccessResponse>
  snaptrade: SnapTradeMutations
  syncAccount: SuccessResponse
  syncAccounts: SuccessResponse
  unlinkAccount: SuccessResponse
  updateLinkedAccount?: Maybe<UpdateLinkedAccountResponse>
}

export type PortfolioMutationsBuyBasketArgs = {
  basketId: Scalars['ID']
  linkedAccountId: Scalars['ID']
  targetValue: Scalars['Float']
}

export type PortfolioMutationsCreateAndBuyBasketArgs = {
  basket: CreateBasketInput
  linkedAccountId: Scalars['ID']
  targetValue: Scalars['Float']
}

export type PortfolioMutationsCreateBasketArgs = {
  basket: CreateBasketInput
}

export type PortfolioMutationsDeleteBasketArgs = {
  basketId: Scalars['ID']
}

export type PortfolioMutationsRenameBasketArgs = {
  basketId: Scalars['ID']
  name: Scalars['String']
}

export type PortfolioMutationsSyncAccountArgs = {
  accountId: Scalars['ID']
}

export type PortfolioMutationsSyncAccountsArgs = {
  accountIds?: InputMaybe<Array<Scalars['String']>>
}

export type PortfolioMutationsUnlinkAccountArgs = {
  accountId: Scalars['String']
}

export type PortfolioMutationsUpdateLinkedAccountArgs = {
  accountHidden?: InputMaybe<Scalars['Boolean']>
  accountId: Scalars['ID']
  nickname?: InputMaybe<Scalars['String']>
}

export type PortfolioTransaction = {
  __typename?: 'PortfolioTransaction'
  accountId?: Maybe<Scalars['String']>
  amount?: Maybe<Scalars['Float']>
  currencyCode?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['String']>
  externalTransactionId?: Maybe<Scalars['String']>
  fees?: Maybe<Scalars['Float']>
  name?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Float']>
  quantity?: Maybe<Scalars['Float']>
  securityName?: Maybe<Scalars['String']>
  securitySymbol?: Maybe<Scalars['String']>
  subtype?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

export type PortfolioTransactionConnection = {
  __typename?: 'PortfolioTransactionConnection'
  edges?: Maybe<Array<PortfolioTransactionEdge>>
  pageInfo: PageInfo
}

export type PortfolioTransactionEdge = {
  __typename?: 'PortfolioTransactionEdge'
  cursor?: Maybe<Scalars['String']>
  node: PortfolioTransaction
}

export type Position = {
  __typename?: 'Position'
  costBasis: Scalars['String']
  marketValue: Scalars['String']
  portfolioWeight: Scalars['String']
  quantity: Scalars['String']
  symbol: Scalars['String']
  unrealizedDayProfitLoss?: Maybe<Scalars['String']>
  unrealizedDayProfitLossPercent?: Maybe<Scalars['String']>
  unrealizedProfitLoss: Scalars['String']
  unrealizedProfitLossPercent: Scalars['String']
}

export type PositionWithCompliance = {
  __typename?: 'PositionWithCompliance'
  compliance: ComplianceStatus
  position: Position
}

export type ProxyResponse = {
  __typename?: 'ProxyResponse'
  response: Scalars['AWSJSON']
  status: Scalars['Int']
}

export type Query = {
  __typename?: 'Query'
  api: Api
  eod: Eod
  esg?: Maybe<Esg>
  etf?: Maybe<Etf>
  etfHoldings: FundHoldingConnection
  etfs: EtfConnection
  iexQuote: IexQuote
  invest?: Maybe<Invest>
  isMarketOpen: IsMarketOpen
  mapSymbols?: Maybe<Array<Maybe<Array<Maybe<SymbolMapperResult>>>>>
  market?: Maybe<Market>
  mutualFund?: Maybe<MutualFund>
  mutualFundHoldings: FundHoldingConnection
  mutualFunds: MutualFundConnection
  portfolio?: Maybe<Portfolio>
  stock: Stock
  stocks?: Maybe<StockConnection>
  symbology: Symbology
  /** @deprecated temp_clock is for testing purposes only. */
  temp_clock?: Maybe<TempClock>
  userAccountDeletionStatus?: Maybe<UserAccountDeletionStatus>
  waitlistRank: WaitlistRank
  zakat?: Maybe<Zakat>
}

export type QueryEsgArgs = {
  region: Scalars['String']
  symbol: Scalars['ID']
}

export type QueryEtfArgs = {
  region: Scalars['String']
  symbol: Scalars['ID']
}

export type QueryEtfHoldingsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<FundHoldingFilter>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  region: Scalars['String']
  symbol: Scalars['ID']
  version: Scalars['String']
}

export type QueryEtfsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryIexQuoteArgs = {
  symbol: Scalars['ID']
}

export type QueryMapSymbolsArgs = {
  symbols?: InputMaybe<Array<InputMaybe<SymbolMapperItem>>>
}

export type QueryMutualFundArgs = {
  region: Scalars['String']
  symbol: Scalars['ID']
}

export type QueryMutualFundHoldingsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<FundHoldingFilter>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  region: Scalars['String']
  symbol: Scalars['ID']
  version: Scalars['String']
}

export type QueryMutualFundsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryStockArgs = {
  region: Scalars['String']
  symbol: Scalars['ID']
}

export type QueryStocksArgs = {
  after?: InputMaybe<Scalars['ID']>
  first?: InputMaybe<Scalars['Int']>
}

export type SnapTrade = {
  __typename?: 'SnapTrade'
  accountBalances: Array<SnapTradeAccountBalance>
  brokerageSymbol?: Maybe<SnapTradeBrokerageSymbolResponse>
  brokerages: Array<SnapTradeBrokerage>
}

export type SnapTradeAccountBalancesArgs = {
  entityId: Scalars['String']
}

export type SnapTradeBrokerageSymbolArgs = {
  entityId: Scalars['String']
  symbol: Scalars['String']
}

export type SnapTradeAccountBalance = {
  __typename?: 'SnapTradeAccountBalance'
  buyingPower?: Maybe<Scalars['Float']>
  cash: Scalars['Float']
  currency: SnapTradeAccountBalanceCurrency
  id: Scalars['String']
}

export type SnapTradeAccountBalanceCurrency = {
  __typename?: 'SnapTradeAccountBalanceCurrency'
  code?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type SnapTradeBrokerage = {
  __typename?: 'SnapTradeBrokerage'
  allowsCryptocurrencyAndRegularSecurities?: Maybe<Scalars['Boolean']>
  allowsCryptocurrencySymbols?: Maybe<Scalars['Boolean']>
  allowsFractionalUnits?: Maybe<Scalars['Boolean']>
  allowsTrading?: Maybe<Scalars['Boolean']>
  allowsTradingThroughSnaptradeApi?: Maybe<Scalars['Boolean']>
  authorizationTypes?: Maybe<Array<SnapTradeBrokerageAuthorizationTypes>>
  brokerageType?: Maybe<SnapTradeBrokerageType>
  defaultCurrency?: Maybe<Scalars['String']>
  description: Scalars['String']
  displayName: Scalars['String']
  enabled: Scalars['Boolean']
  exchanges: Array<Maybe<Scalars['String']>>
  hasOptionPctivitiesSupport?: Maybe<Scalars['Boolean']>
  hasOptionPositionSupport?: Maybe<Scalars['Boolean']>
  hasOptionTradingSupport?: Maybe<Scalars['Boolean']>
  hasReporting?: Maybe<Scalars['Boolean']>
  id: Scalars['String']
  isRealTimeConnection: Scalars['Boolean']
  isScrapingIntegration?: Maybe<Scalars['Boolean']>
  logoUrl: Scalars['String']
  maintenanceMode: Scalars['Boolean']
  maintenanceWindows?: Maybe<Array<SnapTradeBrokerageMaintenanceWindow>>
  name: Scalars['String']
  openUrl?: Maybe<Scalars['String']>
  releaseStage?: Maybe<Scalars['String']>
  slug: Scalars['String']
  squareLogoUrl?: Maybe<Scalars['String']>
  url: Scalars['String']
}

export type SnapTradeBrokerageAuthorizationTypes = {
  __typename?: 'SnapTradeBrokerageAuthorizationTypes'
  authType: Scalars['String']
  type: Scalars['String']
}

export type SnapTradeBrokerageMaintenanceWindow = {
  __typename?: 'SnapTradeBrokerageMaintenanceWindow'
  endTime: Scalars['String']
  startTime: Scalars['String']
  weekdays: Scalars['String']
}

export type SnapTradeBrokerageSymbolResponse = {
  __typename?: 'SnapTradeBrokerageSymbolResponse'
  currencyCode?: Maybe<Scalars['String']>
  currencyName?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  exchangeCloseTime?: Maybe<Scalars['Float']>
  exchangeCode?: Maybe<Scalars['String']>
  exchangeId?: Maybe<Scalars['String']>
  exchangeName?: Maybe<Scalars['String']>
  exchangeStartTime?: Maybe<Scalars['Float']>
  exchangeTimezone?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['String']>
  rawSymbol?: Maybe<Scalars['String']>
  symbol?: Maybe<Scalars['String']>
}

export type SnapTradeBrokerageType = {
  __typename?: 'SnapTradeBrokerageType'
  id: Scalars['String']
  name: Scalars['String']
}

export type SnapTradeForceOrder = {
  action: GetOrderImpactAction
  notionalValue?: InputMaybe<Scalars['Float']>
  orderType: GetOrderImpactOrderType
  price?: InputMaybe<Scalars['Float']>
  stop?: InputMaybe<Scalars['Float']>
  symbolId: Scalars['String']
  timeInForce?: InputMaybe<SnapTradeOrderTimeInForce>
  units?: InputMaybe<Scalars['Float']>
}

export type SnapTradeMutations = {
  __typename?: 'SnapTradeMutations'
  buyBasketIdea?: Maybe<BasketIdeaOrder>
  createBasket?: Maybe<Basket>
  createConnectionUrl?: Maybe<SnaptradeConnectionUrl>
  getOrderImpact: GetOrderImpactResponse
  placeForceOrder: SnapTradePlaceOrderResponse
  placeOrder: SnapTradePlaceOrderResponse
  saveConnection: SnaptradeSaveConnectionResponse
}

export type SnapTradeMutationsBuyBasketIdeaArgs = {
  basketSource: BasketSourceInput
  linkedAccountId: Scalars['String']
}

export type SnapTradeMutationsCreateBasketArgs = {
  basketSource: BasketSourceInput
  linkedAccountId: Scalars['String']
  name: Scalars['String']
}

export type SnapTradeMutationsCreateConnectionUrlArgs = {
  input?: InputMaybe<CreateSnaptradeConnectionUrlInput>
}

export type SnapTradeMutationsGetOrderImpactArgs = {
  action: GetOrderImpactAction
  entityId: Scalars['String']
  notionalValue?: InputMaybe<Scalars['Float']>
  orderType: GetOrderImpactOrderType
  price?: InputMaybe<Scalars['Float']>
  symbolId: Scalars['String']
  units?: InputMaybe<Scalars['Float']>
}

export type SnapTradeMutationsPlaceForceOrderArgs = {
  entityId: Scalars['String']
  forceOrder: SnapTradeForceOrder
}

export type SnapTradeMutationsPlaceOrderArgs = {
  entityId?: InputMaybe<Scalars['String']>
  orderImpactId?: InputMaybe<Scalars['String']>
}

export type SnapTradeMutationsSaveConnectionArgs = {
  brokerageAuthorizationId: Scalars['ID']
}

export const SnapTradeOrderTimeInForce = {
  Day: 'Day',
  Fok: 'FOK',
  Gtc: 'GTC',
} as const

export type SnapTradeOrderTimeInForce =
  (typeof SnapTradeOrderTimeInForce)[keyof typeof SnapTradeOrderTimeInForce]
export type SnapTradePlaceOrderResponse = {
  __typename?: 'SnapTradePlaceOrderResponse'
  action?: Maybe<Scalars['String']>
  canceledQuantity?: Maybe<Scalars['Float']>
  executionPrice?: Maybe<Scalars['Float']>
  filledQuantity?: Maybe<Scalars['Float']>
  limitPrice?: Maybe<Scalars['Float']>
  openQuantity?: Maybe<Scalars['Float']>
  orderId?: Maybe<Scalars['String']>
  orderType?: Maybe<Scalars['String']>
  /**  Keeping status as a string so that it will not break if there is any changes to SnapTradePlaceOrderStatus */
  status?: Maybe<Scalars['String']>
  stopPrice?: Maybe<Scalars['Float']>
  totalQuantity?: Maybe<Scalars['Float']>
}

export const SnapTradePlaceOrderStatus = {
  Accepted: 'ACCEPTED',
  Activated: 'ACTIVATED',
  Canceled: 'CANCELED',
  CancelPending: 'CANCEL_PENDING',
  ContingentOrder: 'CONTINGENT_ORDER',
  Executed: 'EXECUTED',
  Expired: 'EXPIRED',
  Failed: 'FAILED',
  None: 'NONE',
  Partial: 'PARTIAL',
  PartialCanceled: 'PARTIAL_CANCELED',
  Pending: 'PENDING',
  PendingRiskReview: 'PENDING_RISK_REVIEW',
  Queued: 'QUEUED',
  Rejected: 'REJECTED',
  Replaced: 'REPLACED',
  ReplacePending: 'REPLACE_PENDING',
  Stopped: 'STOPPED',
  Suspended: 'SUSPENDED',
  Triggered: 'TRIGGERED',
} as const

export type SnapTradePlaceOrderStatus =
  (typeof SnapTradePlaceOrderStatus)[keyof typeof SnapTradePlaceOrderStatus]
export const SnaptradeConnectionType = {
  Read: 'read',
  Trade: 'trade',
} as const

export type SnaptradeConnectionType =
  (typeof SnaptradeConnectionType)[keyof typeof SnaptradeConnectionType]
export type SnaptradeConnectionUrl = {
  __typename?: 'SnaptradeConnectionUrl'
  redirectUri: Scalars['String']
  sessionId: Scalars['String']
}

export type SnaptradeSaveConnectionResponse = {
  __typename?: 'SnaptradeSaveConnectionResponse'
  brokerageAuthorizationId: Scalars['ID']
}

export type Stock = {
  __typename?: 'Stock'
  complianceStatus: ComplianceStatus
  exchange: Scalars['String']
  name: Scalars['String']
  region: Scalars['String']
  symbol: Scalars['ID']
}

export type StockConnection = {
  __typename?: 'StockConnection'
  items: Array<Stock>
  nextToken?: Maybe<Scalars['String']>
}

export type SuccessResponse = {
  __typename?: 'SuccessResponse'
  success: Scalars['Boolean']
}

export const SupportedCountryCodes = {
  Ca: 'CA',
  Us: 'US',
} as const

export type SupportedCountryCodes =
  (typeof SupportedCountryCodes)[keyof typeof SupportedCountryCodes]
export type SymbolMapperItem = {
  cusip?: InputMaybe<Scalars['String']>
  exchCode?: InputMaybe<Scalars['String']>
  figi?: InputMaybe<Scalars['String']>
  filters?: InputMaybe<SymbolMapperItemFilter>
  iexSymbol?: InputMaybe<Scalars['String']>
  isin?: InputMaybe<Scalars['String']>
  micCode?: InputMaybe<Scalars['String']>
  securityType?: InputMaybe<Scalars['String']>
  sedol?: InputMaybe<Scalars['String']>
  symbol?: InputMaybe<Scalars['String']>
}

export type SymbolMapperItemFilter = {
  currency?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  exchange?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  region?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type SymbolMapperResult = {
  __typename?: 'SymbolMapperResult'
  cik?: Maybe<Scalars['String']>
  currency?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['String']>
  exchange?: Maybe<Scalars['String']>
  exchangeName?: Maybe<Scalars['String']>
  exchangeSegment?: Maybe<Scalars['String']>
  exchangeSegmentName?: Maybe<Scalars['String']>
  exchangeSuffix?: Maybe<Scalars['String']>
  figi?: Maybe<Scalars['String']>
  iexId?: Maybe<Scalars['String']>
  isEnabled?: Maybe<Scalars['Boolean']>
  lei?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  region?: Maybe<Scalars['String']>
  symbol?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

export type Symbology = {
  __typename?: 'Symbology'
  afscReport?: Maybe<AfscReport>
  analystRatings?: Maybe<AnalystRatings>
  controversialSymbols: Array<Scalars['String']>
  exchangeRates: ExchangeRates
  iexProxy: ProxyResponse
}

export type SymbologyAfscReportArgs = {
  symbol: Scalars['String']
}

export type SymbologyAnalystRatingsArgs = {
  symbol: Scalars['String']
}

export type SymbologyExchangeRatesArgs = {
  base: Scalars['String']
}

export type SymbologyIexProxyArgs = {
  request: Scalars['String']
}

export const SyncStatus = {
  Completed: 'Completed',
  Failed: 'Failed',
  InProgress: 'InProgress',
  NotSynced: 'NotSynced',
} as const

export type SyncStatus = (typeof SyncStatus)[keyof typeof SyncStatus]
export type TempClock = {
  __typename?: 'TempClock'
  is_open: Scalars['Boolean']
  next_close: Scalars['String']
  next_open: Scalars['String']
  timestamp: Scalars['String']
}

export type TogglePushNotificationsResponse = {
  __typename?: 'TogglePushNotificationsResponse'
  enabled: Scalars['Boolean']
}

export type UpdateAlpacaContactInput = {
  city?: InputMaybe<Scalars['String']>
  emailAddress?: InputMaybe<Scalars['String']>
  phoneNumber?: InputMaybe<Scalars['String']>
  postalCode?: InputMaybe<Scalars['String']>
  state?: InputMaybe<Scalars['String']>
  streetAddress?: InputMaybe<Array<Scalars['String']>>
  unit?: InputMaybe<Scalars['String']>
}

export type UpdateAlpacaDisclosuresInput = {
  employerAddress?: InputMaybe<Scalars['String']>
  employerName?: InputMaybe<Scalars['String']>
  employmentPosition?: InputMaybe<Scalars['String']>
  employmentStatus?: InputMaybe<AlpacaEmploymentStatus>
  immediateFamilyExposed?: InputMaybe<Scalars['Boolean']>
  isAffiliatedExchangeOrFinra?: InputMaybe<Scalars['Boolean']>
  isControlPerson?: InputMaybe<Scalars['Boolean']>
  isPoliticallyExposed?: InputMaybe<Scalars['Boolean']>
}

export type UpdateAlpacaIdentityInput = {
  annualIncomeMax?: InputMaybe<Scalars['String']>
  annualIncomeMin?: InputMaybe<Scalars['String']>
  dateOfDepartureFromUsa?: InputMaybe<Scalars['String']>
  familyName?: InputMaybe<Scalars['String']>
  fundingSource?: InputMaybe<Array<AlpacaFundingSource>>
  givenName?: InputMaybe<Scalars['String']>
  liquidNetWorthMax?: InputMaybe<Scalars['String']>
  liquidNetWorthMin?: InputMaybe<Scalars['String']>
  middleName?: InputMaybe<Scalars['String']>
  permanentResident?: InputMaybe<Scalars['Boolean']>
  totalNetWorthMax?: InputMaybe<Scalars['String']>
  totalNetWorthMin?: InputMaybe<Scalars['String']>
  visaExpirationDate?: InputMaybe<Scalars['String']>
  visaType?: InputMaybe<AlpacaVisaType>
}

export const UserAccountDeletionStatus = {
  Cancelled: 'Cancelled',
  Completed: 'Completed',
  Failed: 'Failed',
  InProgress: 'InProgress',
} as const

export type UserAccountDeletionStatus =
  (typeof UserAccountDeletionStatus)[keyof typeof UserAccountDeletionStatus]
export type WaitlistRank = {
  __typename?: 'WaitlistRank'
  email: Scalars['String']
  rank: Scalars['Int']
  referralCode: Scalars['String']
  totalReferrals: Scalars['Int']
  totalShares: Scalars['Int']
}

export type WaitlistRegistrationResponse = {
  __typename?: 'WaitlistRegistrationResponse'
  isNew: Scalars['Boolean']
  referralCode: Scalars['String']
}

export type Zakat = {
  __typename?: 'Zakat'
  lastCalculation?: Maybe<ZakatCalculation>
}

export type ZakatCalculation = {
  __typename?: 'ZakatCalculation'
  calculationDate: Scalars['String']
  currency: Scalars['String']
  holdings: Array<ZakatHoldingCalculation>
  zakatDue: Scalars['Float']
  zakatLiableAmount: Scalars['Float']
  zakatableAmount: Scalars['Float']
}

export const ZakatCalculationMethod = {
  Fallback_30: 'FALLBACK_30',
  PassiveInvestment: 'PASSIVE_INVESTMENT',
  TreatAsCash: 'TREAT_AS_CASH',
} as const

export type ZakatCalculationMethod =
  (typeof ZakatCalculationMethod)[keyof typeof ZakatCalculationMethod]
export type ZakatHolding = {
  currency: Scalars['String']
  currentAssets?: InputMaybe<Scalars['Float']>
  groupName?: InputMaybe<Scalars['String']>
  groupingId?: InputMaybe<Scalars['String']>
  iexSymbol?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  outstandingShares?: InputMaybe<Scalars['Float']>
  quantity: Scalars['Float']
  strategy: ZakatHoldingStrategy
  symbol?: InputMaybe<Scalars['String']>
  treatAsCash?: InputMaybe<Scalars['Boolean']>
  unitPrice: Scalars['Float']
}

export type ZakatHoldingCalculation = {
  __typename?: 'ZakatHoldingCalculation'
  calculationMethod: ZakatCalculationMethod
  currency: Scalars['String']
  currentAssets?: Maybe<Scalars['Float']>
  groupName?: Maybe<Scalars['String']>
  groupingId?: Maybe<Scalars['String']>
  iexSymbol?: Maybe<Scalars['String']>
  marketValue: Scalars['Float']
  name?: Maybe<Scalars['String']>
  outstandingShares?: Maybe<Scalars['Float']>
  quantity: Scalars['Float']
  strategy: ZakatHoldingStrategy
  symbol?: Maybe<Scalars['String']>
  treatAsCash?: Maybe<Scalars['Boolean']>
  unitPrice: Scalars['Float']
  zakatDue: Scalars['Float']
  zakatLiableAmount: Scalars['Float']
  zakatableAmount: Scalars['Float']
}

/**  ############# Zakat */
export const ZakatHoldingStrategy = {
  Active: 'ACTIVE',
  Passive: 'PASSIVE',
} as const

export type ZakatHoldingStrategy =
  (typeof ZakatHoldingStrategy)[keyof typeof ZakatHoldingStrategy]
export type ZakatMutations = {
  __typename?: 'ZakatMutations'
  calculate: ZakatCalculation
}

export type ZakatMutationsCalculateArgs = {
  holdings: Array<ZakatHolding>
}

export type IsMarketOpen = {
  __typename?: 'isMarketOpen'
  us: Scalars['Boolean']
}

export type UpdateLinkedAccountResponse = {
  __typename?: 'updateLinkedAccountResponse'
  accountHidden?: Maybe<Scalars['Boolean']>
  nickname?: Maybe<Scalars['String']>
}

export type GetApiQueryVariables = Exact<{ [key: string]: never }>

export type GetApiQuery = {
  __typename?: 'Query'
  api: { __typename?: 'Api'; name: string }
}

export type GetHoldingQuoteQueryVariables = Exact<{
  symbol: Scalars['ID']
}>

export type GetHoldingQuoteQuery = {
  __typename?: 'Query'
  iexQuote: {
    __typename?: 'IexQuote'
    change: number
    changePercent: number
    latestPrice: number
    currency: string
  }
}

export type GetPortfolioInstitutionsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>
}>

export type GetPortfolioInstitutionsQuery = {
  __typename?: 'Query'
  portfolio?: {
    __typename?: 'Portfolio'
    institutions?: {
      __typename: 'PortfolioInstitutionConnection'
      edges?: Array<{
        __typename?: 'PortfolioInstitutionEdge'
        node: {
          __typename?: 'PortfolioInstitution'
          institutionId?: string | null
          name?: string | null
          accounts?: {
            __typename?: 'PortfolioAccountConnection'
            edges?: Array<{
              __typename?: 'PortfolioAccountEdge'
              node: {
                __typename?: 'PortfolioAccount'
                name?: string | null
                nickname?: string | null
                accountHidden?: boolean | null
                balance?: number | null
                lastSyncTime?: string | null
                holdingSyncStatus?: SyncStatus | null
                latestPrice?: number | null
                latestChangePercent?: number | null
                latestChange?: number | null
                allTimeChangePercent?: number | null
                allTimeChange?: number | null
                priceAllTime?: number | null
                compliantPercentage?: number | null
                nonCompliantPercentage?: number | null
                unratedPercentage?: number | null
                questionablePercentage?: number | null
                accountId?: string | null
                mask?: string | null
                type?: string | null
                holdingsId?: string | null
                institution?: string | null
                linkStatus?: string | null
              }
            }> | null
          } | null
        }
      }> | null
      pageInfo: {
        __typename?: 'PageInfo'
        endCursor?: string | null
        hasNextPage: boolean
      }
    } | null
  } | null
}

export type GetPortfolioHoldingsQueryVariables = Exact<{
  holdingsId: Scalars['String']
  after?: InputMaybe<Scalars['String']>
}>

export type GetPortfolioHoldingsQuery = {
  __typename?: 'Query'
  portfolio?: {
    __typename?: 'Portfolio'
    holdings?: {
      __typename: 'PortfolioHoldingConnection'
      edges?: Array<{
        __typename?: 'PortfolioHoldingEdge'
        node: {
          __typename?: 'PortfolioHolding'
          symbol?: string | null
          complianceStatus?: ComplianceStatus | null
          quantity?: number | null
          name?: string | null
          type?: string | null
          change?: number | null
          changePercent?: number | null
          allTimeChange?: number | null
          allTimeChangePercent?: number | null
          value?: number | null
          weight?: number | null
          costBasis?: number | null
        }
      }> | null
      pageInfo: {
        __typename?: 'PageInfo'
        endCursor?: string | null
        hasNextPage: boolean
      }
    } | null
  } | null
}

export type CreateInvestAccountMutationVariables = Exact<{
  enabledAssets:
    | Array<InputMaybe<AlpacaAssetClass>>
    | InputMaybe<AlpacaAssetClass>
  contact: AlpacaContactInput
  identity: AlpacaIdentityInput
  disclosures: AlpacaDisclosuresInput
  documents?: InputMaybe<
    | Array<InputMaybe<AlpacaDocumentUploadInput>>
    | InputMaybe<AlpacaDocumentUploadInput>
  >
  trustedContact?: InputMaybe<AlpacaTrustedContactInput>
  agreements: Array<AlpacaAgreementInput> | AlpacaAgreementInput
}>

export type CreateInvestAccountMutation = {
  __typename?: 'Mutation'
  invest?: {
    __typename?: 'InvestMutations'
    createAlpacaAccount: {
      __typename?: 'AlpacaAccount'
      accountNumber: string
      status: AlpacaAccountStatus
      cryptoStatus: AlpacaAccountStatus
      currency: string
      lastEquity: string
      accountType?: AlpacaAccountType | null
      createdAt: string
    }
  } | null
}

export type CreateAlpacaOrderMutationVariables = Exact<{
  symbol: Scalars['String']
  qty?: InputMaybe<Scalars['String']>
  notional?: InputMaybe<Scalars['String']>
  side: AlpacaOrderSide
  type: AlpacaOrderType
  timeInForce: AlpacaOrderTimeInForce
  limitPrice?: InputMaybe<Scalars['String']>
  extendedHours?: InputMaybe<Scalars['Boolean']>
}>

export type CreateAlpacaOrderMutation = {
  __typename?: 'Mutation'
  invest?: {
    __typename?: 'InvestMutations'
    placeAlpacaOrder: {
      __typename?: 'AlpacaOrder'
      assetClass?: string | null
      assetId: string
      canceledAt?: string | null
      createdAt?: string | null
      extendedHours?: boolean | null
      expiredAt?: string | null
      failedAt?: string | null
      filledAt?: string | null
      filledAvgPrice?: string | null
      filledQty?: string | null
      id: string
      limitPrice?: string | null
      notional?: string | null
      replacedAt?: string | null
      qty?: string | null
      replacedBy?: string | null
      replaces?: string | null
      side: AlpacaOrderSide
      status: AlpacaOrderStatus
      submittedAt?: string | null
      symbol: string
      timeInForce: AlpacaOrderTimeInForce
      type: AlpacaOrderType
      updatedAt?: string | null
    }
  } | null
}

export type GetInvestAccountQueryVariables = Exact<{ [key: string]: never }>

export type GetInvestAccountQuery = {
  __typename?: 'Query'
  invest?: {
    __typename?: 'Invest'
    account?: {
      __typename?: 'AlpacaAccount'
      accountNumber: string
      accountType?: AlpacaAccountType | null
      createdAt: string
      cryptoStatus: AlpacaAccountStatus
      currency: string
      enabledAssets: Array<AlpacaAssetClass>
      lastEquity: string
      status: AlpacaAccountStatus
      contact: {
        __typename?: 'AlpacaContact'
        city: string
        unit?: string | null
        emailAddress: string
        phoneNumber: string
        postalCode?: string | null
        state?: string | null
        streetAddress: Array<string>
      }
      agreements: Array<{
        __typename?: 'AlpacaAgreements'
        revision?: string | null
        agreement: AlpacaAgreement
        ipAddress: string
        signedAt: string
      }>
      disclosures: {
        __typename?: 'AlpacaDisclosures'
        employerAddress?: string | null
        employerName?: string | null
        employmentPosition?: string | null
        employmentStatus?: string | null
        immediateFamilyExposed: boolean
        isAffiliatedExchangeOrFinra: boolean
        isControlPerson: boolean
        isPoliticallyExposed: boolean
      }
      identity: {
        __typename?: 'AlpacaIdentity'
        countryOfTaxResidence: string
        dateOfBirth: string
        extra?: any | null
        familyName: string
        givenName: string
        taxId?: string | null
        taxIdType?: AlpacaTaxIdType | null
      }
      trustedContact?: {
        __typename?: 'AlpacaTrustedContact'
        givenName: string
        familyName: string
        emailAddress?: string | null
        phoneNumber?: string | null
        streetAddress?: string | null
        city?: string | null
        state?: string | null
        postalCode?: string | null
        country?: string | null
      } | null
    } | null
  } | null
}

export type GetInvestAccountActivitiesQueryVariables = Exact<{
  activityType?: InputMaybe<AlpacaAccountActivityType>
  date?: InputMaybe<Scalars['String']>
  until?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  direction?: InputMaybe<Scalars['String']>
  pageSize?: InputMaybe<Scalars['Int']>
  pageToken?: InputMaybe<Scalars['String']>
}>

export type GetInvestAccountActivitiesQuery = {
  __typename?: 'Query'
  invest?: {
    __typename?: 'Invest'
    activities?: Array<
      | {
          __typename?: 'AlpacaAccountNonTradingActivity'
          id: string
          accountId: string
          activityType?: string | null
          date?: string | null
          description?: string | null
          netAmount?: string | null
          perShareAmount?: string | null
          qty?: string | null
          status: string
          symbol?: string | null
        }
      | {
          __typename?: 'AlpacaAccountTradingActivity'
          id: string
          accountId: string
          activityType?: string | null
          cumQty?: string | null
          leavesQty?: string | null
          orderId?: string | null
          orderStatus?: string | null
          price?: string | null
          qty?: string | null
          side?: string | null
          symbol?: string | null
          transactionTime?: string | null
          type?: string | null
        }
    > | null
  } | null
}

export type GetAchRelationshipsQueryVariables = Exact<{ [key: string]: never }>

export type GetAchRelationshipsQuery = {
  __typename?: 'Query'
  invest?: {
    __typename?: 'Invest'
    achRelationships?: Array<{
      __typename?: 'AlpacaAchRelationship'
      id: string
      accountId: string
      createdAt: string
      updatedAt: string
      status: string
      accountOwnerName: string
      bankAccountType: string
      bankAccountNumber: string
      nickname?: string | null
      processorToken?: string | null
      correspondentCode?: string | null
      approvalMethod?: string | null
      plaidInstitution?: string | null
      plaidVerificationStatus?: string | null
      accountMask?: string | null
    } | null> | null
  } | null
}

export type CreateAchRelationshipMutationVariables = Exact<{
  accountOwnerName: Scalars['String']
  bankAccountType: AlpacaBankAccountType
  bankAccountNumber: Scalars['String']
  bankRoutingNumber: Scalars['String']
  nickname?: InputMaybe<Scalars['String']>
  processorToken?: InputMaybe<Scalars['String']>
  instant?: InputMaybe<Scalars['Boolean']>
}>

export type CreateAchRelationshipMutation = {
  __typename?: 'Mutation'
  invest?: {
    __typename?: 'InvestMutations'
    createAlpacaAchRelationship: {
      __typename?: 'AlpacaAchRelationship'
      id: string
      accountId: string
      createdAt: string
      updatedAt: string
      status: string
      accountOwnerName: string
      bankAccountType: string
      bankAccountNumber: string
      nickname?: string | null
      processorToken?: string | null
      correspondentCode?: string | null
      approvalMethod?: string | null
      plaidInstitution?: string | null
      plaidVerificationStatus?: string | null
      accountMask?: string | null
    }
  } | null
}

export type DeleteAchRelationshipMutationVariables = Exact<{
  relationshipId: Scalars['String']
}>

export type DeleteAchRelationshipMutation = {
  __typename?: 'Mutation'
  invest?: {
    __typename?: 'InvestMutations'
    deleteAlpacaAchRelationship: {
      __typename?: 'SuccessResponse'
      success: boolean
    }
  } | null
}

export type GetTransfersQueryVariables = Exact<{
  direction?: InputMaybe<AlpacaFundTransferDirection>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}>

export type GetTransfersQuery = {
  __typename?: 'Query'
  invest?: {
    __typename?: 'Invest'
    fundTransfers?: Array<{
      __typename?: 'AlpacaFundTransfer'
      id: string
      relationshipId: string
      accountId: string
      type: string
      status: string
      reason?: string | null
      amount: string
      direction: string
      createdAt: string
      updatedAt?: string | null
      expiresAt: string
      additionalInformation?: string | null
      requestedAmount?: string | null
      fee?: string | null
      feePaymentMethod?: string | null
    }> | null
  } | null
}

export type CancelTransferMutationVariables = Exact<{
  transferId: Scalars['String']
}>

export type CancelTransferMutation = {
  __typename?: 'Mutation'
  invest?: {
    __typename?: 'InvestMutations'
    cancelTransfer: { __typename?: 'SuccessResponse'; success: boolean }
  } | null
}

export type CreateTransferMutationVariables = Exact<{
  transferType: AlpacaFundTransferType
  relationshipId?: InputMaybe<Scalars['String']>
  bankId?: InputMaybe<Scalars['String']>
  amount: Scalars['String']
  direction: AlpacaFundTransferDirection
  timing: AlpacaFundTransferTiming
}>

export type CreateTransferMutation = {
  __typename?: 'Mutation'
  invest?: {
    __typename?: 'InvestMutations'
    createTransfer: {
      __typename?: 'AlpacaFundTransfer'
      id: string
      relationshipId: string
      accountId: string
      type: string
      status: string
      reason?: string | null
      amount: string
      direction: string
      createdAt: string
      updatedAt?: string | null
      expiresAt: string
      additionalInformation?: string | null
      requestedAmount?: string | null
      fee?: string | null
      feePaymentMethod?: string | null
    }
  } | null
}

export type GetBankRelationshipsQueryVariables = Exact<{ [key: string]: never }>

export type GetBankRelationshipsQuery = {
  __typename?: 'Query'
  invest?: {
    __typename?: 'Invest'
    banks?: Array<{
      __typename?: 'AlpacaBankRelationship'
      id: string
      accountId: string
      name: string
      status: string
      country?: string | null
      stateProvince?: string | null
      postalCode?: string | null
      city?: string | null
      streetAddress?: string | null
      accountNumber: string
      bankCode: string
      bankCodeType: string
      createdAt: string
      updatedAt: string
      errorMessage?: string | null
    }> | null
  } | null
}

export type CreateBankRelationshipMutationVariables = Exact<{
  name: Scalars['String']
  bankCode: Scalars['String']
  bankCodeType: AlpacaBankCodeType
  accountNumber: Scalars['String']
  country: Scalars['String']
  stateProvince: Scalars['String']
  postalCode: Scalars['String']
  city: Scalars['String']
  streetAddress: Scalars['String']
}>

export type CreateBankRelationshipMutation = {
  __typename?: 'Mutation'
  invest?: {
    __typename?: 'InvestMutations'
    createAlpacaBankRelationship: {
      __typename?: 'AlpacaBankRelationship'
      id: string
      accountId: string
      name: string
      status: string
      country?: string | null
      stateProvince?: string | null
      postalCode?: string | null
      city?: string | null
      streetAddress?: string | null
      accountNumber: string
      bankCode: string
      bankCodeType: string
      createdAt: string
      updatedAt: string
      errorMessage?: string | null
    }
  } | null
}

export type DeleteBankRelationshipMutationVariables = Exact<{
  bankId: Scalars['String']
}>

export type DeleteBankRelationshipMutation = {
  __typename?: 'Mutation'
  invest?: {
    __typename?: 'InvestMutations'
    deleteAlpacaBankRelationship: {
      __typename?: 'SuccessResponse'
      success: boolean
    }
  } | null
}

export type GetInvestAccountOrdersQueryVariables = Exact<{
  status?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  until?: InputMaybe<Scalars['String']>
  direction?: InputMaybe<Scalars['String']>
  limit?: InputMaybe<Scalars['Int']>
  symbols?: InputMaybe<Scalars['String']>
}>

export type GetInvestAccountOrdersQuery = {
  __typename?: 'Query'
  invest?: {
    __typename?: 'Invest'
    orders?: Array<{
      __typename?: 'AlpacaOrder'
      id: string
      createdAt?: string | null
      updatedAt?: string | null
      submittedAt?: string | null
      filledAt?: string | null
      expiredAt?: string | null
      canceledAt?: string | null
      failedAt?: string | null
      replacedAt?: string | null
      replacedBy?: string | null
      replaces?: string | null
      assetId: string
      symbol: string
      assetClass?: string | null
      notional?: string | null
      qty?: string | null
      filledQty?: string | null
      filledAvgPrice?: string | null
      type: AlpacaOrderType
      side: AlpacaOrderSide
      timeInForce: AlpacaOrderTimeInForce
      limitPrice?: string | null
      status: AlpacaOrderStatus
      extendedHours?: boolean | null
    }> | null
  } | null
}

export type GetInvestAccountOrderQueryVariables = Exact<{
  orderId: Scalars['String']
}>

export type GetInvestAccountOrderQuery = {
  __typename?: 'Query'
  invest?: {
    __typename?: 'Invest'
    order?: {
      __typename?: 'AlpacaOrder'
      id: string
      createdAt?: string | null
      updatedAt?: string | null
      submittedAt?: string | null
      filledAt?: string | null
      expiredAt?: string | null
      canceledAt?: string | null
      failedAt?: string | null
      replacedAt?: string | null
      replacedBy?: string | null
      replaces?: string | null
      assetId: string
      symbol: string
      assetClass?: string | null
      notional?: string | null
      qty?: string | null
      filledQty?: string | null
      filledAvgPrice?: string | null
      type: AlpacaOrderType
      side: AlpacaOrderSide
      timeInForce: AlpacaOrderTimeInForce
      limitPrice?: string | null
      status: AlpacaOrderStatus
      extendedHours?: boolean | null
    } | null
  } | null
}

export type CancelOrderMutationVariables = Exact<{
  orderId: Scalars['String']
}>

export type CancelOrderMutation = {
  __typename?: 'Mutation'
  invest?: {
    __typename?: 'InvestMutations'
    cancelAlpacaOrder: { __typename?: 'SuccessResponse'; success: boolean }
  } | null
}

export type GetAssetWithOrdersAndPositionQueryVariables = Exact<{
  symbol: Scalars['String']
}>

export type GetAssetWithOrdersAndPositionQuery = {
  __typename?: 'Query'
  invest?: {
    __typename?: 'Invest'
    asset?: {
      __typename?: 'AlpacaAsset'
      symbol: string
      exchange: AlpacaAssetExchange
      name: string
      status: AlpacaAssetStatus
      tradable: boolean
      fractionable: boolean
    } | null
    orders?: Array<{
      __typename?: 'AlpacaOrder'
      id: string
      symbol: string
      notional?: string | null
      qty?: string | null
      filledQty?: string | null
      filledAvgPrice?: string | null
      type: AlpacaOrderType
      side: AlpacaOrderSide
      timeInForce: AlpacaOrderTimeInForce
      limitPrice?: string | null
      status: AlpacaOrderStatus
      extendedHours?: boolean | null
      createdAt?: string | null
      updatedAt?: string | null
      submittedAt?: string | null
    }> | null
    positions?: Array<{
      __typename?: 'AlpacaPosition'
      symbol: string
      qty: string
      marketValue: string
    }> | null
  } | null
}

export type ClosePositionMutationVariables = Exact<{
  symbol: Scalars['String']
}>

export type ClosePositionMutation = {
  __typename?: 'Mutation'
  invest?: {
    __typename?: 'InvestMutations'
    closePosition: { __typename?: 'AlpacaOrder'; symbol: string }
  } | null
}

export type GetInvestmentPositionsQueryVariables = Exact<{
  [key: string]: never
}>

export type GetInvestmentPositionsQuery = {
  __typename?: 'Query'
  invest?: {
    __typename?: 'Invest'
    accountTradingInfo?: {
      __typename?: 'AlpacaAccountTradingInfo'
      cash: string
      equity: string
    } | null
    positions?: Array<{
      __typename?: 'AlpacaPosition'
      symbol: string
      exchange: string
      assetClass: string
      qty: string
      marketValue: string
      costBasis: string
      unrealizedPl: string
      unrealizedPlpc: string
      unrealizedIntradayPl?: string | null
      unrealizedIntradayPlpc?: string | null
      currentPrice: string
      changeToday: string
      usd?: string | null
    }> | null
    portfolioHistory?: {
      __typename?: 'AlpacaPortfolioHistory'
      timestamp: Array<number>
      equity: Array<number | null>
      profitLoss: Array<number | null>
      profitLossPct: Array<number | null>
    } | null
  } | null
}

export type UpdatePortfolioAccountMutationVariables = Exact<{
  accountId: Scalars['ID']
  nickname?: InputMaybe<Scalars['String']>
}>

export type UpdatePortfolioAccountMutation = {
  __typename?: 'Mutation'
  portfolio?: {
    __typename?: 'PortfolioMutations'
    updateLinkedAccount?: {
      __typename?: 'updateLinkedAccountResponse'
      nickname?: string | null
    } | null
  } | null
}

export type CheckAssetTradableAndInvestAccountStatusQueryVariables = Exact<{
  symbol: Scalars['String']
}>

export type CheckAssetTradableAndInvestAccountStatusQuery = {
  __typename?: 'Query'
  invest?: {
    __typename?: 'Invest'
    asset?: {
      __typename?: 'AlpacaAsset'
      status: AlpacaAssetStatus
      tradable: boolean
    } | null
    account?: {
      __typename?: 'AlpacaAccount'
      accountNumber: string
      status: AlpacaAccountStatus
    } | null
  } | null
}

export type GetInvestAccountStatusQueryVariables = Exact<{
  [key: string]: never
}>

export type GetInvestAccountStatusQuery = {
  __typename?: 'Query'
  invest?: {
    __typename?: 'Invest'
    account?: {
      __typename?: 'AlpacaAccount'
      accountNumber: string
      status: AlpacaAccountStatus
    } | null
  } | null
}

export type GetInvestDocumentsQueryVariables = Exact<{
  start?: InputMaybe<Scalars['String']>
  end?: InputMaybe<Scalars['String']>
}>

export type GetInvestDocumentsQuery = {
  __typename?: 'Query'
  invest?: {
    __typename?: 'Invest'
    accountStatements: Array<{
      __typename?: 'AlpacaDocumentMetadata'
      id: string
      date: string
      name: string
      subType: string
      type: string
    }>
    tradeConfirmations: Array<{
      __typename?: 'AlpacaDocumentMetadata'
      id: string
      date: string
      name: string
      subType: string
      type: string
    }>
    taxStatements: Array<{
      __typename?: 'AlpacaDocumentMetadata'
      id: string
      date: string
      name: string
      subType: string
      type: string
    }>
  } | null
}

export type GetInvestDocumentDownloadLinkQueryVariables = Exact<{
  documentId: Scalars['String']
}>

export type GetInvestDocumentDownloadLinkQuery = {
  __typename?: 'Query'
  invest?: { __typename?: 'Invest'; documentDownloadLink: string } | null
}

export type CashAndTransfersQueryVariables = Exact<{
  limitTransfers?: InputMaybe<Scalars['Int']>
  offsetTransfers?: InputMaybe<Scalars['Int']>
  filterTransfers?: InputMaybe<AlpacaFundTransferDirection>
}>

export type CashAndTransfersQuery = {
  __typename?: 'Query'
  invest?: {
    __typename?: 'Invest'
    accountTradingInfo?: {
      __typename?: 'AlpacaAccountTradingInfo'
      cash: string
      currency: string
      buyingPower: string
      cashWithdrawable: string
      pendingTransferOut: string
    } | null
    fundTransfers?: Array<{
      __typename?: 'AlpacaFundTransfer'
      id: string
      type: string
      status: string
      direction: string
      amount: string
      requestedAmount?: string | null
      updatedAt?: string | null
    }> | null
  } | null
}

export type PortfolioHistoryQueryVariables = Exact<{
  period?: InputMaybe<Scalars['String']>
  dateEnd?: InputMaybe<Scalars['String']>
  timeframe?: InputMaybe<Scalars['String']>
  extendedHours?: InputMaybe<Scalars['Boolean']>
  sinceMidnight?: InputMaybe<Scalars['Boolean']>
}>

export type PortfolioHistoryQuery = {
  __typename?: 'Query'
  invest?: {
    __typename?: 'Invest'
    portfolioHistory?: {
      __typename?: 'AlpacaPortfolioHistory'
      timestamp: Array<number>
      equity: Array<number | null>
      profitLoss: Array<number | null>
      profitLossPct: Array<number | null>
      baseValue: number
      timeframe: string
    } | null
  } | null
}

export type StockBarsQueryVariables = Exact<{
  symbol: Scalars['String']
  timeframe: Scalars['String']
  start?: InputMaybe<Scalars['String']>
  end?: InputMaybe<Scalars['String']>
  limit?: InputMaybe<Scalars['Int']>
  pageToken?: InputMaybe<Scalars['String']>
  adjustment?: InputMaybe<Scalars['String']>
  asof?: InputMaybe<Scalars['String']>
  feed?: InputMaybe<AlpacaMarketFeed>
  currency?: InputMaybe<Scalars['String']>
}>

export type StockBarsQuery = {
  __typename?: 'Query'
  market?: {
    __typename?: 'Market'
    alpaca?: {
      __typename?: 'AlpacaMarketData'
      bars?: {
        __typename?: 'AlpacaMarketBarResponse'
        symbol: string
        nextPageToken?: string | null
        bars?: Array<{
          __typename?: 'AlpacaMarketBar'
          t: string
          c: number
          v: number
        }> | null
      } | null
    } | null
  } | null
}

export type GetPortfolioAccountsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>
}>

export type GetPortfolioAccountsQuery = {
  __typename?: 'Query'
  portfolio?: {
    __typename?: 'Portfolio'
    accounts?: {
      __typename?: 'PortfolioAccountConnection'
      pageInfo: {
        __typename?: 'PageInfo'
        endCursor?: string | null
        hasNextPage: boolean
      }
      edges?: Array<{
        __typename?: 'PortfolioAccountEdge'
        node: {
          __typename?: 'PortfolioAccount'
          name?: string | null
          nickname?: string | null
          accountHidden?: boolean | null
          balance?: number | null
          lastSyncTime?: string | null
          holdingsLastSyncTime?: string | null
          transactionsLastSyncTime?: string | null
          holdingSyncStatus?: SyncStatus | null
          transactionsSyncStatus?: SyncStatus | null
          latestPrice?: number | null
          latestChangePercent?: number | null
          latestChange?: number | null
          allTimeChangePercent?: number | null
          allTimeChange?: number | null
          priceAllTime?: number | null
          compliantPercentage?: number | null
          compliantPercentageWithFunds?: number | null
          nonCompliantPercentage?: number | null
          nonCompliantPercentageWithFunds?: number | null
          unratedPercentage?: number | null
          unratedPercentageWithFunds?: number | null
          questionablePercentage?: number | null
          questionablePercentageWithFunds?: number | null
          accountId?: string | null
          mask?: string | null
          type?: string | null
          subtype?: string | null
          holdingsId?: string | null
          institution?: string | null
          institutionId?: string | null
          linkStatus?: string | null
          groupId?: string | null
          currencyCode?: string | null
          aggregator?: string | null
          vendor?: string | null
          buyingPower?: number | null
          cash?: Array<{
            __typename?: 'PortfolioAccountCash'
            available?: number | null
            currency?: string | null
            current?: number | null
            lastUpdatedDate?: string | null
          } | null> | null
        }
      }> | null
    } | null
  } | null
}

export type GetPortfolioHoldingSyncStatusQueryVariables = Exact<{
  [key: string]: never
}>

export type GetPortfolioHoldingSyncStatusQuery = {
  __typename?: 'Query'
  portfolio?: {
    __typename?: 'Portfolio'
    account?: {
      __typename: 'PortfolioAccount'
      holdingSyncStatus?: SyncStatus | null
    } | null
  } | null
}

export type SnaptradeBrokeragesQueryVariables = Exact<{ [key: string]: never }>

export type SnaptradeBrokeragesQuery = {
  __typename?: 'Query'
  portfolio?: {
    __typename?: 'Portfolio'
    snaptrade: {
      __typename?: 'SnapTrade'
      brokerages: Array<{
        __typename?: 'SnapTradeBrokerage'
        id: string
        name: string
        displayName: string
        slug: string
        logoUrl: string
        squareLogoUrl?: string | null
      }>
    }
  } | null
}

export type CreateSnaptradeConnectionUrlMutationVariables = Exact<{
  input?: InputMaybe<CreateSnaptradeConnectionUrlInput>
}>

export type CreateSnaptradeConnectionUrlMutation = {
  __typename?: 'Mutation'
  portfolio?: {
    __typename?: 'PortfolioMutations'
    snaptrade: {
      __typename?: 'SnapTradeMutations'
      createConnectionUrl?: {
        __typename?: 'SnaptradeConnectionUrl'
        sessionId: string
        redirectUri: string
      } | null
    }
  } | null
}

export type SyncAllInstitutionsMutationVariables = Exact<{
  [key: string]: never
}>

export type SyncAllInstitutionsMutation = {
  __typename?: 'Mutation'
  portfolio?: {
    __typename?: 'PortfolioMutations'
    syncAccount: { __typename?: 'SuccessResponse'; success: boolean }
  } | null
}

export type SaveSnaptradeConnectionMutationVariables = Exact<{
  brokerageAuthorizationId: Scalars['ID']
}>

export type SaveSnaptradeConnectionMutation = {
  __typename?: 'Mutation'
  portfolio?: {
    __typename?: 'PortfolioMutations'
    snaptrade: {
      __typename?: 'SnapTradeMutations'
      saveConnection: {
        __typename?: 'SnaptradeSaveConnectionResponse'
        brokerageAuthorizationId: string
      }
    }
  } | null
}

export type UnlinkPortfolioAccountMutationVariables = Exact<{
  accountId: Scalars['String']
}>

export type UnlinkPortfolioAccountMutation = {
  __typename?: 'Mutation'
  portfolio?: {
    __typename?: 'PortfolioMutations'
    unlinkAccount: { __typename?: 'SuccessResponse'; success: boolean }
  } | null
}

export type CreatePlaidLinkTokenMutationVariables = Exact<{
  accountId?: InputMaybe<Scalars['String']>
  countryCodes?: InputMaybe<
    Array<InputMaybe<SupportedCountryCodes>> | InputMaybe<SupportedCountryCodes>
  >
}>

export type CreatePlaidLinkTokenMutation = {
  __typename?: 'Mutation'
  plaid?: {
    __typename?: 'PlaidMutations'
    createLinkToken: { __typename?: 'PlaidLinkToken'; linkToken: string }
  } | null
}

export type SavePlaidLinkedAccountMutationVariables = Exact<{
  publicToken: Scalars['String']
  institution: PlaidInstitution
  accounts: Array<PlaidAccountMeta> | PlaidAccountMeta
}>

export type SavePlaidLinkedAccountMutation = {
  __typename?: 'Mutation'
  plaid?: {
    __typename?: 'PlaidMutations'
    saveLinkedAccount: { __typename?: 'SuccessResponse'; success: boolean }
  } | null
}

export type GetPlaidInstitutionQueryVariables = Exact<{
  institutionId: Scalars['String']
  countryCodes?: InputMaybe<
    Array<InputMaybe<SupportedCountryCodes>> | InputMaybe<SupportedCountryCodes>
  >
}>

export type GetPlaidInstitutionQuery = {
  __typename?: 'Query'
  portfolio?: {
    __typename?: 'Portfolio'
    institution: {
      __typename?: 'PortfolioGetInstitutionData'
      institution?: {
        __typename?: 'PortfolioInstitutionData'
        name: string
        institutionId: string
        countryCodes: Array<string>
        primaryColor?: string | null
        url?: string | null
        logo?: string | null
        investmentUpdateHealth: {
          __typename?: 'InstitutionHealth'
          healthStatus: InstitutionHealthStatus
        }
      } | null
    }
  } | null
}

export const GetApiDocument = gql`
  query getApi {
    api {
      name
    }
  }
`

/**
 * __useGetApiQuery__
 *
 * To run a query within a React component, call `useGetApiQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApiQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetApiQuery(
  baseOptions?: Apollo.QueryHookOptions<GetApiQuery, GetApiQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetApiQuery, GetApiQueryVariables>(
    GetApiDocument,
    options,
  )
}
export function useGetApiLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetApiQuery, GetApiQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetApiQuery, GetApiQueryVariables>(
    GetApiDocument,
    options,
  )
}
export type GetApiQueryHookResult = ReturnType<typeof useGetApiQuery>
export type GetApiLazyQueryHookResult = ReturnType<typeof useGetApiLazyQuery>
export type GetApiQueryResult = Apollo.QueryResult<
  GetApiQuery,
  GetApiQueryVariables
>
export function refetchGetApiQuery(variables?: GetApiQueryVariables) {
  return { query: GetApiDocument, variables: variables }
}
export const GetHoldingQuoteDocument = gql`
  query getHoldingQuote($symbol: ID!) {
    iexQuote(symbol: $symbol) {
      change
      changePercent
      latestPrice
      currency
    }
  }
`

/**
 * __useGetHoldingQuoteQuery__
 *
 * To run a query within a React component, call `useGetHoldingQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHoldingQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHoldingQuoteQuery({
 *   variables: {
 *      symbol: // value for 'symbol'
 *   },
 * });
 */
export function useGetHoldingQuoteQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetHoldingQuoteQuery,
    GetHoldingQuoteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetHoldingQuoteQuery, GetHoldingQuoteQueryVariables>(
    GetHoldingQuoteDocument,
    options,
  )
}
export function useGetHoldingQuoteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHoldingQuoteQuery,
    GetHoldingQuoteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetHoldingQuoteQuery,
    GetHoldingQuoteQueryVariables
  >(GetHoldingQuoteDocument, options)
}
export type GetHoldingQuoteQueryHookResult = ReturnType<
  typeof useGetHoldingQuoteQuery
>
export type GetHoldingQuoteLazyQueryHookResult = ReturnType<
  typeof useGetHoldingQuoteLazyQuery
>
export type GetHoldingQuoteQueryResult = Apollo.QueryResult<
  GetHoldingQuoteQuery,
  GetHoldingQuoteQueryVariables
>
export function refetchGetHoldingQuoteQuery(
  variables: GetHoldingQuoteQueryVariables,
) {
  return { query: GetHoldingQuoteDocument, variables: variables }
}
export const GetPortfolioInstitutionsDocument = gql`
  query getPortfolioInstitutions($after: String) {
    portfolio {
      institutions(first: 20, after: $after) {
        __typename
        edges {
          node {
            institutionId
            name
            accounts(first: 20) {
              edges {
                node {
                  name
                  nickname
                  accountHidden
                  balance
                  lastSyncTime
                  holdingSyncStatus
                  latestPrice
                  latestChangePercent
                  latestChange
                  allTimeChangePercent
                  allTimeChange
                  priceAllTime
                  compliantPercentage
                  nonCompliantPercentage
                  unratedPercentage
                  questionablePercentage
                  accountId
                  mask
                  type
                  holdingsId
                  institution
                  linkStatus
                }
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`

/**
 * __useGetPortfolioInstitutionsQuery__
 *
 * To run a query within a React component, call `useGetPortfolioInstitutionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPortfolioInstitutionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPortfolioInstitutionsQuery({
 *   variables: {
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetPortfolioInstitutionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPortfolioInstitutionsQuery,
    GetPortfolioInstitutionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetPortfolioInstitutionsQuery,
    GetPortfolioInstitutionsQueryVariables
  >(GetPortfolioInstitutionsDocument, options)
}
export function useGetPortfolioInstitutionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPortfolioInstitutionsQuery,
    GetPortfolioInstitutionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetPortfolioInstitutionsQuery,
    GetPortfolioInstitutionsQueryVariables
  >(GetPortfolioInstitutionsDocument, options)
}
export type GetPortfolioInstitutionsQueryHookResult = ReturnType<
  typeof useGetPortfolioInstitutionsQuery
>
export type GetPortfolioInstitutionsLazyQueryHookResult = ReturnType<
  typeof useGetPortfolioInstitutionsLazyQuery
>
export type GetPortfolioInstitutionsQueryResult = Apollo.QueryResult<
  GetPortfolioInstitutionsQuery,
  GetPortfolioInstitutionsQueryVariables
>
export function refetchGetPortfolioInstitutionsQuery(
  variables?: GetPortfolioInstitutionsQueryVariables,
) {
  return { query: GetPortfolioInstitutionsDocument, variables: variables }
}
export const GetPortfolioHoldingsDocument = gql`
  query getPortfolioHoldings($holdingsId: String!, $after: String) {
    portfolio {
      holdings(holdingsId: $holdingsId, first: 200, after: $after) {
        __typename
        edges {
          node {
            symbol
            complianceStatus
            quantity
            name
            type
            change
            changePercent
            allTimeChange
            allTimeChangePercent
            value
            weight
            costBasis
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`

/**
 * __useGetPortfolioHoldingsQuery__
 *
 * To run a query within a React component, call `useGetPortfolioHoldingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPortfolioHoldingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPortfolioHoldingsQuery({
 *   variables: {
 *      holdingsId: // value for 'holdingsId'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetPortfolioHoldingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPortfolioHoldingsQuery,
    GetPortfolioHoldingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetPortfolioHoldingsQuery,
    GetPortfolioHoldingsQueryVariables
  >(GetPortfolioHoldingsDocument, options)
}
export function useGetPortfolioHoldingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPortfolioHoldingsQuery,
    GetPortfolioHoldingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetPortfolioHoldingsQuery,
    GetPortfolioHoldingsQueryVariables
  >(GetPortfolioHoldingsDocument, options)
}
export type GetPortfolioHoldingsQueryHookResult = ReturnType<
  typeof useGetPortfolioHoldingsQuery
>
export type GetPortfolioHoldingsLazyQueryHookResult = ReturnType<
  typeof useGetPortfolioHoldingsLazyQuery
>
export type GetPortfolioHoldingsQueryResult = Apollo.QueryResult<
  GetPortfolioHoldingsQuery,
  GetPortfolioHoldingsQueryVariables
>
export function refetchGetPortfolioHoldingsQuery(
  variables: GetPortfolioHoldingsQueryVariables,
) {
  return { query: GetPortfolioHoldingsDocument, variables: variables }
}
export const CreateInvestAccountDocument = gql`
  mutation createInvestAccount(
    $enabledAssets: [AlpacaAssetClass]!
    $contact: AlpacaContactInput!
    $identity: AlpacaIdentityInput!
    $disclosures: AlpacaDisclosuresInput!
    $documents: [AlpacaDocumentUploadInput]
    $trustedContact: AlpacaTrustedContactInput
    $agreements: [AlpacaAgreementInput!]!
  ) {
    invest {
      createAlpacaAccount(
        enabledAssets: $enabledAssets
        contact: $contact
        identity: $identity
        disclosures: $disclosures
        documents: $documents
        trustedContact: $trustedContact
        agreements: $agreements
      ) {
        accountNumber
        status
        cryptoStatus
        currency
        lastEquity
        accountType
        createdAt
      }
    }
  }
`
export type CreateInvestAccountMutationFn = Apollo.MutationFunction<
  CreateInvestAccountMutation,
  CreateInvestAccountMutationVariables
>

/**
 * __useCreateInvestAccountMutation__
 *
 * To run a mutation, you first call `useCreateInvestAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvestAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvestAccountMutation, { data, loading, error }] = useCreateInvestAccountMutation({
 *   variables: {
 *      enabledAssets: // value for 'enabledAssets'
 *      contact: // value for 'contact'
 *      identity: // value for 'identity'
 *      disclosures: // value for 'disclosures'
 *      documents: // value for 'documents'
 *      trustedContact: // value for 'trustedContact'
 *      agreements: // value for 'agreements'
 *   },
 * });
 */
export function useCreateInvestAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInvestAccountMutation,
    CreateInvestAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateInvestAccountMutation,
    CreateInvestAccountMutationVariables
  >(CreateInvestAccountDocument, options)
}
export type CreateInvestAccountMutationHookResult = ReturnType<
  typeof useCreateInvestAccountMutation
>
export type CreateInvestAccountMutationResult =
  Apollo.MutationResult<CreateInvestAccountMutation>
export type CreateInvestAccountMutationOptions = Apollo.BaseMutationOptions<
  CreateInvestAccountMutation,
  CreateInvestAccountMutationVariables
>
export const CreateAlpacaOrderDocument = gql`
  mutation createAlpacaOrder(
    $symbol: String!
    $qty: String
    $notional: String
    $side: AlpacaOrderSide!
    $type: AlpacaOrderType!
    $timeInForce: AlpacaOrderTimeInForce!
    $limitPrice: String
    $extendedHours: Boolean
  ) {
    invest {
      placeAlpacaOrder(
        symbol: $symbol
        qty: $qty
        side: $side
        timeInForce: $timeInForce
        type: $type
        extendedHours: $extendedHours
        limitPrice: $limitPrice
        notional: $notional
      ) {
        assetClass
        assetId
        canceledAt
        createdAt
        extendedHours
        expiredAt
        failedAt
        filledAt
        filledAvgPrice
        filledQty
        id
        limitPrice
        notional
        replacedAt
        qty
        replacedBy
        replaces
        side
        status
        submittedAt
        symbol
        timeInForce
        type
        updatedAt
      }
    }
  }
`
export type CreateAlpacaOrderMutationFn = Apollo.MutationFunction<
  CreateAlpacaOrderMutation,
  CreateAlpacaOrderMutationVariables
>

/**
 * __useCreateAlpacaOrderMutation__
 *
 * To run a mutation, you first call `useCreateAlpacaOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAlpacaOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAlpacaOrderMutation, { data, loading, error }] = useCreateAlpacaOrderMutation({
 *   variables: {
 *      symbol: // value for 'symbol'
 *      qty: // value for 'qty'
 *      notional: // value for 'notional'
 *      side: // value for 'side'
 *      type: // value for 'type'
 *      timeInForce: // value for 'timeInForce'
 *      limitPrice: // value for 'limitPrice'
 *      extendedHours: // value for 'extendedHours'
 *   },
 * });
 */
export function useCreateAlpacaOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAlpacaOrderMutation,
    CreateAlpacaOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateAlpacaOrderMutation,
    CreateAlpacaOrderMutationVariables
  >(CreateAlpacaOrderDocument, options)
}
export type CreateAlpacaOrderMutationHookResult = ReturnType<
  typeof useCreateAlpacaOrderMutation
>
export type CreateAlpacaOrderMutationResult =
  Apollo.MutationResult<CreateAlpacaOrderMutation>
export type CreateAlpacaOrderMutationOptions = Apollo.BaseMutationOptions<
  CreateAlpacaOrderMutation,
  CreateAlpacaOrderMutationVariables
>
export const GetInvestAccountDocument = gql`
  query getInvestAccount {
    invest {
      account {
        accountNumber
        accountType
        createdAt
        cryptoStatus
        currency
        enabledAssets
        lastEquity
        status
        contact {
          city
          unit
          emailAddress
          phoneNumber
          postalCode
          state
          streetAddress
        }
        agreements {
          revision
          agreement
          ipAddress
          signedAt
        }
        disclosures {
          employerAddress
          employerName
          employmentPosition
          employmentStatus
          immediateFamilyExposed
          isAffiliatedExchangeOrFinra
          isControlPerson
          isPoliticallyExposed
        }
        identity {
          countryOfTaxResidence
          dateOfBirth
          extra
          familyName
          givenName
          taxId
          taxIdType
        }
        trustedContact {
          givenName
          familyName
          emailAddress
          phoneNumber
          streetAddress
          city
          state
          postalCode
          country
        }
      }
    }
  }
`

/**
 * __useGetInvestAccountQuery__
 *
 * To run a query within a React component, call `useGetInvestAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvestAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvestAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInvestAccountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetInvestAccountQuery,
    GetInvestAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetInvestAccountQuery, GetInvestAccountQueryVariables>(
    GetInvestAccountDocument,
    options,
  )
}
export function useGetInvestAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInvestAccountQuery,
    GetInvestAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetInvestAccountQuery,
    GetInvestAccountQueryVariables
  >(GetInvestAccountDocument, options)
}
export type GetInvestAccountQueryHookResult = ReturnType<
  typeof useGetInvestAccountQuery
>
export type GetInvestAccountLazyQueryHookResult = ReturnType<
  typeof useGetInvestAccountLazyQuery
>
export type GetInvestAccountQueryResult = Apollo.QueryResult<
  GetInvestAccountQuery,
  GetInvestAccountQueryVariables
>
export function refetchGetInvestAccountQuery(
  variables?: GetInvestAccountQueryVariables,
) {
  return { query: GetInvestAccountDocument, variables: variables }
}
export const GetInvestAccountActivitiesDocument = gql`
  query getInvestAccountActivities(
    $activityType: AlpacaAccountActivityType
    $date: String
    $until: String
    $after: String
    $direction: String
    $pageSize: Int
    $pageToken: String
  ) {
    invest {
      activities(
        activityType: $activityType
        date: $date
        until: $until
        after: $after
        direction: $direction
        pageSize: $pageSize
        pageToken: $pageToken
      ) {
        ... on AlpacaAccountTradingActivity {
          id
          accountId
          activityType
          cumQty
          leavesQty
          orderId
          orderStatus
          price
          qty
          side
          symbol
          transactionTime
          type
        }
        ... on AlpacaAccountNonTradingActivity {
          id
          accountId
          activityType
          date
          description
          netAmount
          perShareAmount
          qty
          status
          symbol
        }
      }
    }
  }
`

/**
 * __useGetInvestAccountActivitiesQuery__
 *
 * To run a query within a React component, call `useGetInvestAccountActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvestAccountActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvestAccountActivitiesQuery({
 *   variables: {
 *      activityType: // value for 'activityType'
 *      date: // value for 'date'
 *      until: // value for 'until'
 *      after: // value for 'after'
 *      direction: // value for 'direction'
 *      pageSize: // value for 'pageSize'
 *      pageToken: // value for 'pageToken'
 *   },
 * });
 */
export function useGetInvestAccountActivitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetInvestAccountActivitiesQuery,
    GetInvestAccountActivitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetInvestAccountActivitiesQuery,
    GetInvestAccountActivitiesQueryVariables
  >(GetInvestAccountActivitiesDocument, options)
}
export function useGetInvestAccountActivitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInvestAccountActivitiesQuery,
    GetInvestAccountActivitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetInvestAccountActivitiesQuery,
    GetInvestAccountActivitiesQueryVariables
  >(GetInvestAccountActivitiesDocument, options)
}
export type GetInvestAccountActivitiesQueryHookResult = ReturnType<
  typeof useGetInvestAccountActivitiesQuery
>
export type GetInvestAccountActivitiesLazyQueryHookResult = ReturnType<
  typeof useGetInvestAccountActivitiesLazyQuery
>
export type GetInvestAccountActivitiesQueryResult = Apollo.QueryResult<
  GetInvestAccountActivitiesQuery,
  GetInvestAccountActivitiesQueryVariables
>
export function refetchGetInvestAccountActivitiesQuery(
  variables?: GetInvestAccountActivitiesQueryVariables,
) {
  return { query: GetInvestAccountActivitiesDocument, variables: variables }
}
export const GetAchRelationshipsDocument = gql`
  query getACHRelationships {
    invest {
      achRelationships {
        id
        accountId
        createdAt
        updatedAt
        status
        accountOwnerName
        bankAccountType
        bankAccountNumber
        nickname
        processorToken
        correspondentCode
        approvalMethod
        plaidInstitution
        plaidVerificationStatus
        accountMask
      }
    }
  }
`

/**
 * __useGetAchRelationshipsQuery__
 *
 * To run a query within a React component, call `useGetAchRelationshipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAchRelationshipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAchRelationshipsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAchRelationshipsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAchRelationshipsQuery,
    GetAchRelationshipsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetAchRelationshipsQuery,
    GetAchRelationshipsQueryVariables
  >(GetAchRelationshipsDocument, options)
}
export function useGetAchRelationshipsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAchRelationshipsQuery,
    GetAchRelationshipsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetAchRelationshipsQuery,
    GetAchRelationshipsQueryVariables
  >(GetAchRelationshipsDocument, options)
}
export type GetAchRelationshipsQueryHookResult = ReturnType<
  typeof useGetAchRelationshipsQuery
>
export type GetAchRelationshipsLazyQueryHookResult = ReturnType<
  typeof useGetAchRelationshipsLazyQuery
>
export type GetAchRelationshipsQueryResult = Apollo.QueryResult<
  GetAchRelationshipsQuery,
  GetAchRelationshipsQueryVariables
>
export function refetchGetAchRelationshipsQuery(
  variables?: GetAchRelationshipsQueryVariables,
) {
  return { query: GetAchRelationshipsDocument, variables: variables }
}
export const CreateAchRelationshipDocument = gql`
  mutation createACHRelationship(
    $accountOwnerName: String!
    $bankAccountType: AlpacaBankAccountType!
    $bankAccountNumber: String!
    $bankRoutingNumber: String!
    $nickname: String
    $processorToken: String
    $instant: Boolean
  ) {
    invest {
      createAlpacaAchRelationship(
        accountOwnerName: $accountOwnerName
        bankAccountType: $bankAccountType
        bankAccountNumber: $bankAccountNumber
        bankRoutingNumber: $bankRoutingNumber
        nickname: $nickname
        processorToken: $processorToken
        instant: $instant
      ) {
        id
        accountId
        createdAt
        updatedAt
        status
        accountOwnerName
        bankAccountType
        bankAccountNumber
        nickname
        processorToken
        correspondentCode
        approvalMethod
        plaidInstitution
        plaidVerificationStatus
        accountMask
      }
    }
  }
`
export type CreateAchRelationshipMutationFn = Apollo.MutationFunction<
  CreateAchRelationshipMutation,
  CreateAchRelationshipMutationVariables
>

/**
 * __useCreateAchRelationshipMutation__
 *
 * To run a mutation, you first call `useCreateAchRelationshipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAchRelationshipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAchRelationshipMutation, { data, loading, error }] = useCreateAchRelationshipMutation({
 *   variables: {
 *      accountOwnerName: // value for 'accountOwnerName'
 *      bankAccountType: // value for 'bankAccountType'
 *      bankAccountNumber: // value for 'bankAccountNumber'
 *      bankRoutingNumber: // value for 'bankRoutingNumber'
 *      nickname: // value for 'nickname'
 *      processorToken: // value for 'processorToken'
 *      instant: // value for 'instant'
 *   },
 * });
 */
export function useCreateAchRelationshipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAchRelationshipMutation,
    CreateAchRelationshipMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateAchRelationshipMutation,
    CreateAchRelationshipMutationVariables
  >(CreateAchRelationshipDocument, options)
}
export type CreateAchRelationshipMutationHookResult = ReturnType<
  typeof useCreateAchRelationshipMutation
>
export type CreateAchRelationshipMutationResult =
  Apollo.MutationResult<CreateAchRelationshipMutation>
export type CreateAchRelationshipMutationOptions = Apollo.BaseMutationOptions<
  CreateAchRelationshipMutation,
  CreateAchRelationshipMutationVariables
>
export const DeleteAchRelationshipDocument = gql`
  mutation deleteACHRelationship($relationshipId: String!) {
    invest {
      deleteAlpacaAchRelationship(relationshipId: $relationshipId) {
        success
      }
    }
  }
`
export type DeleteAchRelationshipMutationFn = Apollo.MutationFunction<
  DeleteAchRelationshipMutation,
  DeleteAchRelationshipMutationVariables
>

/**
 * __useDeleteAchRelationshipMutation__
 *
 * To run a mutation, you first call `useDeleteAchRelationshipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAchRelationshipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAchRelationshipMutation, { data, loading, error }] = useDeleteAchRelationshipMutation({
 *   variables: {
 *      relationshipId: // value for 'relationshipId'
 *   },
 * });
 */
export function useDeleteAchRelationshipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAchRelationshipMutation,
    DeleteAchRelationshipMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteAchRelationshipMutation,
    DeleteAchRelationshipMutationVariables
  >(DeleteAchRelationshipDocument, options)
}
export type DeleteAchRelationshipMutationHookResult = ReturnType<
  typeof useDeleteAchRelationshipMutation
>
export type DeleteAchRelationshipMutationResult =
  Apollo.MutationResult<DeleteAchRelationshipMutation>
export type DeleteAchRelationshipMutationOptions = Apollo.BaseMutationOptions<
  DeleteAchRelationshipMutation,
  DeleteAchRelationshipMutationVariables
>
export const GetTransfersDocument = gql`
  query getTransfers(
    $direction: AlpacaFundTransferDirection
    $limit: Int
    $offset: Int
  ) {
    invest {
      fundTransfers(direction: $direction, limit: $limit, offset: $offset) {
        id
        relationshipId
        accountId
        type
        status
        reason
        amount
        direction
        createdAt
        updatedAt
        expiresAt
        additionalInformation
        requestedAmount
        fee
        feePaymentMethod
      }
    }
  }
`

/**
 * __useGetTransfersQuery__
 *
 * To run a query within a React component, call `useGetTransfersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransfersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransfersQuery({
 *   variables: {
 *      direction: // value for 'direction'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetTransfersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTransfersQuery,
    GetTransfersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetTransfersQuery, GetTransfersQueryVariables>(
    GetTransfersDocument,
    options,
  )
}
export function useGetTransfersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTransfersQuery,
    GetTransfersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetTransfersQuery, GetTransfersQueryVariables>(
    GetTransfersDocument,
    options,
  )
}
export type GetTransfersQueryHookResult = ReturnType<
  typeof useGetTransfersQuery
>
export type GetTransfersLazyQueryHookResult = ReturnType<
  typeof useGetTransfersLazyQuery
>
export type GetTransfersQueryResult = Apollo.QueryResult<
  GetTransfersQuery,
  GetTransfersQueryVariables
>
export function refetchGetTransfersQuery(
  variables?: GetTransfersQueryVariables,
) {
  return { query: GetTransfersDocument, variables: variables }
}
export const CancelTransferDocument = gql`
  mutation cancelTransfer($transferId: String!) {
    invest {
      cancelTransfer: deleteAlpacaFundTransfer(transferId: $transferId) {
        success
      }
    }
  }
`
export type CancelTransferMutationFn = Apollo.MutationFunction<
  CancelTransferMutation,
  CancelTransferMutationVariables
>

/**
 * __useCancelTransferMutation__
 *
 * To run a mutation, you first call `useCancelTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelTransferMutation, { data, loading, error }] = useCancelTransferMutation({
 *   variables: {
 *      transferId: // value for 'transferId'
 *   },
 * });
 */
export function useCancelTransferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelTransferMutation,
    CancelTransferMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CancelTransferMutation,
    CancelTransferMutationVariables
  >(CancelTransferDocument, options)
}
export type CancelTransferMutationHookResult = ReturnType<
  typeof useCancelTransferMutation
>
export type CancelTransferMutationResult =
  Apollo.MutationResult<CancelTransferMutation>
export type CancelTransferMutationOptions = Apollo.BaseMutationOptions<
  CancelTransferMutation,
  CancelTransferMutationVariables
>
export const CreateTransferDocument = gql`
  mutation createTransfer(
    $transferType: AlpacaFundTransferType!
    $relationshipId: String
    $bankId: String
    $amount: String!
    $direction: AlpacaFundTransferDirection!
    $timing: AlpacaFundTransferTiming!
  ) {
    invest {
      createTransfer: createAlpacaFundTransferEntity(
        transferType: $transferType
        relationshipId: $relationshipId
        bankId: $bankId
        amount: $amount
        direction: $direction
        timing: $timing
      ) {
        id
        relationshipId
        accountId
        type
        status
        reason
        amount
        direction
        createdAt
        updatedAt
        expiresAt
        additionalInformation
        requestedAmount
        fee
        feePaymentMethod
      }
    }
  }
`
export type CreateTransferMutationFn = Apollo.MutationFunction<
  CreateTransferMutation,
  CreateTransferMutationVariables
>

/**
 * __useCreateTransferMutation__
 *
 * To run a mutation, you first call `useCreateTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTransferMutation, { data, loading, error }] = useCreateTransferMutation({
 *   variables: {
 *      transferType: // value for 'transferType'
 *      relationshipId: // value for 'relationshipId'
 *      bankId: // value for 'bankId'
 *      amount: // value for 'amount'
 *      direction: // value for 'direction'
 *      timing: // value for 'timing'
 *   },
 * });
 */
export function useCreateTransferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTransferMutation,
    CreateTransferMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateTransferMutation,
    CreateTransferMutationVariables
  >(CreateTransferDocument, options)
}
export type CreateTransferMutationHookResult = ReturnType<
  typeof useCreateTransferMutation
>
export type CreateTransferMutationResult =
  Apollo.MutationResult<CreateTransferMutation>
export type CreateTransferMutationOptions = Apollo.BaseMutationOptions<
  CreateTransferMutation,
  CreateTransferMutationVariables
>
export const GetBankRelationshipsDocument = gql`
  query getBankRelationships {
    invest {
      banks: bankRelationships {
        id
        accountId
        name
        status
        country
        stateProvince
        postalCode
        city
        streetAddress
        accountNumber
        bankCode
        bankCodeType
        createdAt
        updatedAt
        errorMessage
      }
    }
  }
`

/**
 * __useGetBankRelationshipsQuery__
 *
 * To run a query within a React component, call `useGetBankRelationshipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankRelationshipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankRelationshipsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBankRelationshipsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBankRelationshipsQuery,
    GetBankRelationshipsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetBankRelationshipsQuery,
    GetBankRelationshipsQueryVariables
  >(GetBankRelationshipsDocument, options)
}
export function useGetBankRelationshipsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBankRelationshipsQuery,
    GetBankRelationshipsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetBankRelationshipsQuery,
    GetBankRelationshipsQueryVariables
  >(GetBankRelationshipsDocument, options)
}
export type GetBankRelationshipsQueryHookResult = ReturnType<
  typeof useGetBankRelationshipsQuery
>
export type GetBankRelationshipsLazyQueryHookResult = ReturnType<
  typeof useGetBankRelationshipsLazyQuery
>
export type GetBankRelationshipsQueryResult = Apollo.QueryResult<
  GetBankRelationshipsQuery,
  GetBankRelationshipsQueryVariables
>
export function refetchGetBankRelationshipsQuery(
  variables?: GetBankRelationshipsQueryVariables,
) {
  return { query: GetBankRelationshipsDocument, variables: variables }
}
export const CreateBankRelationshipDocument = gql`
  mutation createBankRelationship(
    $name: String!
    $bankCode: String!
    $bankCodeType: AlpacaBankCodeType!
    $accountNumber: String!
    $country: String!
    $stateProvince: String!
    $postalCode: String!
    $city: String!
    $streetAddress: String!
  ) {
    invest {
      createAlpacaBankRelationship(
        name: $name
        bankCode: $bankCode
        bankCodeType: $bankCodeType
        accountNumber: $accountNumber
        country: $country
        stateProvince: $stateProvince
        postalCode: $postalCode
        city: $city
        streetAddress: $streetAddress
      ) {
        id
        accountId
        name
        status
        country
        stateProvince
        postalCode
        city
        streetAddress
        accountNumber
        bankCode
        bankCodeType
        createdAt
        updatedAt
        errorMessage
      }
    }
  }
`
export type CreateBankRelationshipMutationFn = Apollo.MutationFunction<
  CreateBankRelationshipMutation,
  CreateBankRelationshipMutationVariables
>

/**
 * __useCreateBankRelationshipMutation__
 *
 * To run a mutation, you first call `useCreateBankRelationshipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBankRelationshipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBankRelationshipMutation, { data, loading, error }] = useCreateBankRelationshipMutation({
 *   variables: {
 *      name: // value for 'name'
 *      bankCode: // value for 'bankCode'
 *      bankCodeType: // value for 'bankCodeType'
 *      accountNumber: // value for 'accountNumber'
 *      country: // value for 'country'
 *      stateProvince: // value for 'stateProvince'
 *      postalCode: // value for 'postalCode'
 *      city: // value for 'city'
 *      streetAddress: // value for 'streetAddress'
 *   },
 * });
 */
export function useCreateBankRelationshipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBankRelationshipMutation,
    CreateBankRelationshipMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateBankRelationshipMutation,
    CreateBankRelationshipMutationVariables
  >(CreateBankRelationshipDocument, options)
}
export type CreateBankRelationshipMutationHookResult = ReturnType<
  typeof useCreateBankRelationshipMutation
>
export type CreateBankRelationshipMutationResult =
  Apollo.MutationResult<CreateBankRelationshipMutation>
export type CreateBankRelationshipMutationOptions = Apollo.BaseMutationOptions<
  CreateBankRelationshipMutation,
  CreateBankRelationshipMutationVariables
>
export const DeleteBankRelationshipDocument = gql`
  mutation deleteBankRelationship($bankId: String!) {
    invest {
      deleteAlpacaBankRelationship(bankId: $bankId) {
        success
      }
    }
  }
`
export type DeleteBankRelationshipMutationFn = Apollo.MutationFunction<
  DeleteBankRelationshipMutation,
  DeleteBankRelationshipMutationVariables
>

/**
 * __useDeleteBankRelationshipMutation__
 *
 * To run a mutation, you first call `useDeleteBankRelationshipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBankRelationshipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBankRelationshipMutation, { data, loading, error }] = useDeleteBankRelationshipMutation({
 *   variables: {
 *      bankId: // value for 'bankId'
 *   },
 * });
 */
export function useDeleteBankRelationshipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteBankRelationshipMutation,
    DeleteBankRelationshipMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteBankRelationshipMutation,
    DeleteBankRelationshipMutationVariables
  >(DeleteBankRelationshipDocument, options)
}
export type DeleteBankRelationshipMutationHookResult = ReturnType<
  typeof useDeleteBankRelationshipMutation
>
export type DeleteBankRelationshipMutationResult =
  Apollo.MutationResult<DeleteBankRelationshipMutation>
export type DeleteBankRelationshipMutationOptions = Apollo.BaseMutationOptions<
  DeleteBankRelationshipMutation,
  DeleteBankRelationshipMutationVariables
>
export const GetInvestAccountOrdersDocument = gql`
  query getInvestAccountOrders(
    $status: String
    $after: String
    $until: String
    $direction: String
    $limit: Int
    $symbols: String
  ) {
    invest {
      orders(
        status: $status
        after: $after
        until: $until
        direction: $direction
        limit: $limit
        symbols: $symbols
      ) {
        id
        createdAt
        updatedAt
        submittedAt
        filledAt
        expiredAt
        canceledAt
        failedAt
        replacedAt
        replacedBy
        replaces
        assetId
        symbol
        assetClass
        notional
        qty
        filledQty
        filledAvgPrice
        type
        side
        timeInForce
        limitPrice
        status
        extendedHours
      }
    }
  }
`

/**
 * __useGetInvestAccountOrdersQuery__
 *
 * To run a query within a React component, call `useGetInvestAccountOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvestAccountOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvestAccountOrdersQuery({
 *   variables: {
 *      status: // value for 'status'
 *      after: // value for 'after'
 *      until: // value for 'until'
 *      direction: // value for 'direction'
 *      limit: // value for 'limit'
 *      symbols: // value for 'symbols'
 *   },
 * });
 */
export function useGetInvestAccountOrdersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetInvestAccountOrdersQuery,
    GetInvestAccountOrdersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetInvestAccountOrdersQuery,
    GetInvestAccountOrdersQueryVariables
  >(GetInvestAccountOrdersDocument, options)
}
export function useGetInvestAccountOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInvestAccountOrdersQuery,
    GetInvestAccountOrdersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetInvestAccountOrdersQuery,
    GetInvestAccountOrdersQueryVariables
  >(GetInvestAccountOrdersDocument, options)
}
export type GetInvestAccountOrdersQueryHookResult = ReturnType<
  typeof useGetInvestAccountOrdersQuery
>
export type GetInvestAccountOrdersLazyQueryHookResult = ReturnType<
  typeof useGetInvestAccountOrdersLazyQuery
>
export type GetInvestAccountOrdersQueryResult = Apollo.QueryResult<
  GetInvestAccountOrdersQuery,
  GetInvestAccountOrdersQueryVariables
>
export function refetchGetInvestAccountOrdersQuery(
  variables?: GetInvestAccountOrdersQueryVariables,
) {
  return { query: GetInvestAccountOrdersDocument, variables: variables }
}
export const GetInvestAccountOrderDocument = gql`
  query getInvestAccountOrder($orderId: String!) {
    invest {
      order(id: $orderId) {
        id
        createdAt
        updatedAt
        submittedAt
        filledAt
        expiredAt
        canceledAt
        failedAt
        replacedAt
        replacedBy
        replaces
        assetId
        symbol
        assetClass
        notional
        qty
        filledQty
        filledAvgPrice
        type
        side
        timeInForce
        limitPrice
        status
        extendedHours
      }
    }
  }
`

/**
 * __useGetInvestAccountOrderQuery__
 *
 * To run a query within a React component, call `useGetInvestAccountOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvestAccountOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvestAccountOrderQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useGetInvestAccountOrderQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInvestAccountOrderQuery,
    GetInvestAccountOrderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetInvestAccountOrderQuery,
    GetInvestAccountOrderQueryVariables
  >(GetInvestAccountOrderDocument, options)
}
export function useGetInvestAccountOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInvestAccountOrderQuery,
    GetInvestAccountOrderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetInvestAccountOrderQuery,
    GetInvestAccountOrderQueryVariables
  >(GetInvestAccountOrderDocument, options)
}
export type GetInvestAccountOrderQueryHookResult = ReturnType<
  typeof useGetInvestAccountOrderQuery
>
export type GetInvestAccountOrderLazyQueryHookResult = ReturnType<
  typeof useGetInvestAccountOrderLazyQuery
>
export type GetInvestAccountOrderQueryResult = Apollo.QueryResult<
  GetInvestAccountOrderQuery,
  GetInvestAccountOrderQueryVariables
>
export function refetchGetInvestAccountOrderQuery(
  variables: GetInvestAccountOrderQueryVariables,
) {
  return { query: GetInvestAccountOrderDocument, variables: variables }
}
export const CancelOrderDocument = gql`
  mutation cancelOrder($orderId: String!) {
    invest {
      cancelAlpacaOrder(orderId: $orderId) {
        success
      }
    }
  }
`
export type CancelOrderMutationFn = Apollo.MutationFunction<
  CancelOrderMutation,
  CancelOrderMutationVariables
>

/**
 * __useCancelOrderMutation__
 *
 * To run a mutation, you first call `useCancelOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelOrderMutation, { data, loading, error }] = useCancelOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useCancelOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelOrderMutation,
    CancelOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CancelOrderMutation, CancelOrderMutationVariables>(
    CancelOrderDocument,
    options,
  )
}
export type CancelOrderMutationHookResult = ReturnType<
  typeof useCancelOrderMutation
>
export type CancelOrderMutationResult =
  Apollo.MutationResult<CancelOrderMutation>
export type CancelOrderMutationOptions = Apollo.BaseMutationOptions<
  CancelOrderMutation,
  CancelOrderMutationVariables
>
export const GetAssetWithOrdersAndPositionDocument = gql`
  query getAssetWithOrdersAndPosition($symbol: String!) {
    invest {
      asset(symbol: $symbol) {
        symbol
        exchange
        name
        status
        tradable
        fractionable
      }
      orders(symbols: $symbol) {
        id
        symbol
        notional
        qty
        filledQty
        filledAvgPrice
        type
        side
        timeInForce
        limitPrice
        status
        extendedHours
        createdAt
        updatedAt
        submittedAt
      }
      positions {
        symbol
        qty
        marketValue
      }
    }
  }
`

/**
 * __useGetAssetWithOrdersAndPositionQuery__
 *
 * To run a query within a React component, call `useGetAssetWithOrdersAndPositionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetWithOrdersAndPositionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetWithOrdersAndPositionQuery({
 *   variables: {
 *      symbol: // value for 'symbol'
 *   },
 * });
 */
export function useGetAssetWithOrdersAndPositionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAssetWithOrdersAndPositionQuery,
    GetAssetWithOrdersAndPositionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetAssetWithOrdersAndPositionQuery,
    GetAssetWithOrdersAndPositionQueryVariables
  >(GetAssetWithOrdersAndPositionDocument, options)
}
export function useGetAssetWithOrdersAndPositionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssetWithOrdersAndPositionQuery,
    GetAssetWithOrdersAndPositionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetAssetWithOrdersAndPositionQuery,
    GetAssetWithOrdersAndPositionQueryVariables
  >(GetAssetWithOrdersAndPositionDocument, options)
}
export type GetAssetWithOrdersAndPositionQueryHookResult = ReturnType<
  typeof useGetAssetWithOrdersAndPositionQuery
>
export type GetAssetWithOrdersAndPositionLazyQueryHookResult = ReturnType<
  typeof useGetAssetWithOrdersAndPositionLazyQuery
>
export type GetAssetWithOrdersAndPositionQueryResult = Apollo.QueryResult<
  GetAssetWithOrdersAndPositionQuery,
  GetAssetWithOrdersAndPositionQueryVariables
>
export function refetchGetAssetWithOrdersAndPositionQuery(
  variables: GetAssetWithOrdersAndPositionQueryVariables,
) {
  return { query: GetAssetWithOrdersAndPositionDocument, variables: variables }
}
export const ClosePositionDocument = gql`
  mutation closePosition($symbol: String!) {
    invest {
      closePosition: closeAlpacaPosition(symbol: $symbol) {
        symbol
      }
    }
  }
`
export type ClosePositionMutationFn = Apollo.MutationFunction<
  ClosePositionMutation,
  ClosePositionMutationVariables
>

/**
 * __useClosePositionMutation__
 *
 * To run a mutation, you first call `useClosePositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClosePositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closePositionMutation, { data, loading, error }] = useClosePositionMutation({
 *   variables: {
 *      symbol: // value for 'symbol'
 *   },
 * });
 */
export function useClosePositionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClosePositionMutation,
    ClosePositionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ClosePositionMutation,
    ClosePositionMutationVariables
  >(ClosePositionDocument, options)
}
export type ClosePositionMutationHookResult = ReturnType<
  typeof useClosePositionMutation
>
export type ClosePositionMutationResult =
  Apollo.MutationResult<ClosePositionMutation>
export type ClosePositionMutationOptions = Apollo.BaseMutationOptions<
  ClosePositionMutation,
  ClosePositionMutationVariables
>
export const GetInvestmentPositionsDocument = gql`
  query getInvestmentPositions {
    invest {
      accountTradingInfo {
        cash
        equity
      }
      positions {
        symbol
        exchange
        assetClass
        qty
        marketValue
        costBasis
        unrealizedPl
        unrealizedPlpc
        unrealizedIntradayPl
        unrealizedIntradayPlpc
        currentPrice
        changeToday
        usd
      }
      portfolioHistory(sinceMidnight: true, timeframe: "1H") {
        timestamp
        equity
        profitLoss
        profitLossPct
      }
    }
  }
`

/**
 * __useGetInvestmentPositionsQuery__
 *
 * To run a query within a React component, call `useGetInvestmentPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvestmentPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvestmentPositionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInvestmentPositionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetInvestmentPositionsQuery,
    GetInvestmentPositionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetInvestmentPositionsQuery,
    GetInvestmentPositionsQueryVariables
  >(GetInvestmentPositionsDocument, options)
}
export function useGetInvestmentPositionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInvestmentPositionsQuery,
    GetInvestmentPositionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetInvestmentPositionsQuery,
    GetInvestmentPositionsQueryVariables
  >(GetInvestmentPositionsDocument, options)
}
export type GetInvestmentPositionsQueryHookResult = ReturnType<
  typeof useGetInvestmentPositionsQuery
>
export type GetInvestmentPositionsLazyQueryHookResult = ReturnType<
  typeof useGetInvestmentPositionsLazyQuery
>
export type GetInvestmentPositionsQueryResult = Apollo.QueryResult<
  GetInvestmentPositionsQuery,
  GetInvestmentPositionsQueryVariables
>
export function refetchGetInvestmentPositionsQuery(
  variables?: GetInvestmentPositionsQueryVariables,
) {
  return { query: GetInvestmentPositionsDocument, variables: variables }
}
export const UpdatePortfolioAccountDocument = gql`
  mutation updatePortfolioAccount($accountId: ID!, $nickname: String) {
    portfolio {
      updateLinkedAccount(accountId: $accountId, nickname: $nickname) {
        nickname
      }
    }
  }
`
export type UpdatePortfolioAccountMutationFn = Apollo.MutationFunction<
  UpdatePortfolioAccountMutation,
  UpdatePortfolioAccountMutationVariables
>

/**
 * __useUpdatePortfolioAccountMutation__
 *
 * To run a mutation, you first call `useUpdatePortfolioAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePortfolioAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePortfolioAccountMutation, { data, loading, error }] = useUpdatePortfolioAccountMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      nickname: // value for 'nickname'
 *   },
 * });
 */
export function useUpdatePortfolioAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePortfolioAccountMutation,
    UpdatePortfolioAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdatePortfolioAccountMutation,
    UpdatePortfolioAccountMutationVariables
  >(UpdatePortfolioAccountDocument, options)
}
export type UpdatePortfolioAccountMutationHookResult = ReturnType<
  typeof useUpdatePortfolioAccountMutation
>
export type UpdatePortfolioAccountMutationResult =
  Apollo.MutationResult<UpdatePortfolioAccountMutation>
export type UpdatePortfolioAccountMutationOptions = Apollo.BaseMutationOptions<
  UpdatePortfolioAccountMutation,
  UpdatePortfolioAccountMutationVariables
>
export const CheckAssetTradableAndInvestAccountStatusDocument = gql`
  query checkAssetTradableAndInvestAccountStatus($symbol: String!) {
    invest {
      asset(symbol: $symbol) {
        status
        tradable
      }
      account {
        accountNumber
        status
      }
    }
  }
`

/**
 * __useCheckAssetTradableAndInvestAccountStatusQuery__
 *
 * To run a query within a React component, call `useCheckAssetTradableAndInvestAccountStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckAssetTradableAndInvestAccountStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckAssetTradableAndInvestAccountStatusQuery({
 *   variables: {
 *      symbol: // value for 'symbol'
 *   },
 * });
 */
export function useCheckAssetTradableAndInvestAccountStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    CheckAssetTradableAndInvestAccountStatusQuery,
    CheckAssetTradableAndInvestAccountStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CheckAssetTradableAndInvestAccountStatusQuery,
    CheckAssetTradableAndInvestAccountStatusQueryVariables
  >(CheckAssetTradableAndInvestAccountStatusDocument, options)
}
export function useCheckAssetTradableAndInvestAccountStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckAssetTradableAndInvestAccountStatusQuery,
    CheckAssetTradableAndInvestAccountStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CheckAssetTradableAndInvestAccountStatusQuery,
    CheckAssetTradableAndInvestAccountStatusQueryVariables
  >(CheckAssetTradableAndInvestAccountStatusDocument, options)
}
export type CheckAssetTradableAndInvestAccountStatusQueryHookResult =
  ReturnType<typeof useCheckAssetTradableAndInvestAccountStatusQuery>
export type CheckAssetTradableAndInvestAccountStatusLazyQueryHookResult =
  ReturnType<typeof useCheckAssetTradableAndInvestAccountStatusLazyQuery>
export type CheckAssetTradableAndInvestAccountStatusQueryResult =
  Apollo.QueryResult<
    CheckAssetTradableAndInvestAccountStatusQuery,
    CheckAssetTradableAndInvestAccountStatusQueryVariables
  >
export function refetchCheckAssetTradableAndInvestAccountStatusQuery(
  variables: CheckAssetTradableAndInvestAccountStatusQueryVariables,
) {
  return {
    query: CheckAssetTradableAndInvestAccountStatusDocument,
    variables: variables,
  }
}
export const GetInvestAccountStatusDocument = gql`
  query getInvestAccountStatus {
    invest {
      account {
        accountNumber
        status
      }
    }
  }
`

/**
 * __useGetInvestAccountStatusQuery__
 *
 * To run a query within a React component, call `useGetInvestAccountStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvestAccountStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvestAccountStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInvestAccountStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetInvestAccountStatusQuery,
    GetInvestAccountStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetInvestAccountStatusQuery,
    GetInvestAccountStatusQueryVariables
  >(GetInvestAccountStatusDocument, options)
}
export function useGetInvestAccountStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInvestAccountStatusQuery,
    GetInvestAccountStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetInvestAccountStatusQuery,
    GetInvestAccountStatusQueryVariables
  >(GetInvestAccountStatusDocument, options)
}
export type GetInvestAccountStatusQueryHookResult = ReturnType<
  typeof useGetInvestAccountStatusQuery
>
export type GetInvestAccountStatusLazyQueryHookResult = ReturnType<
  typeof useGetInvestAccountStatusLazyQuery
>
export type GetInvestAccountStatusQueryResult = Apollo.QueryResult<
  GetInvestAccountStatusQuery,
  GetInvestAccountStatusQueryVariables
>
export function refetchGetInvestAccountStatusQuery(
  variables?: GetInvestAccountStatusQueryVariables,
) {
  return { query: GetInvestAccountStatusDocument, variables: variables }
}
export const GetInvestDocumentsDocument = gql`
  query getInvestDocuments($start: String, $end: String) {
    invest {
      accountStatements: documents(
        type: "account_statement"
        start: $start
        end: $end
      ) {
        id
        date
        name
        subType
        type
      }
      tradeConfirmations: documents(
        type: "trade_confirmation"
        start: $start
        end: $end
      ) {
        id
        date
        name
        subType
        type
      }
      taxStatements: documents(
        type: "tax_statement"
        start: $start
        end: $end
      ) {
        id
        date
        name
        subType
        type
      }
    }
  }
`

/**
 * __useGetInvestDocumentsQuery__
 *
 * To run a query within a React component, call `useGetInvestDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvestDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvestDocumentsQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useGetInvestDocumentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetInvestDocumentsQuery,
    GetInvestDocumentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetInvestDocumentsQuery,
    GetInvestDocumentsQueryVariables
  >(GetInvestDocumentsDocument, options)
}
export function useGetInvestDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInvestDocumentsQuery,
    GetInvestDocumentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetInvestDocumentsQuery,
    GetInvestDocumentsQueryVariables
  >(GetInvestDocumentsDocument, options)
}
export type GetInvestDocumentsQueryHookResult = ReturnType<
  typeof useGetInvestDocumentsQuery
>
export type GetInvestDocumentsLazyQueryHookResult = ReturnType<
  typeof useGetInvestDocumentsLazyQuery
>
export type GetInvestDocumentsQueryResult = Apollo.QueryResult<
  GetInvestDocumentsQuery,
  GetInvestDocumentsQueryVariables
>
export function refetchGetInvestDocumentsQuery(
  variables?: GetInvestDocumentsQueryVariables,
) {
  return { query: GetInvestDocumentsDocument, variables: variables }
}
export const GetInvestDocumentDownloadLinkDocument = gql`
  query getInvestDocumentDownloadLink($documentId: String!) {
    invest {
      documentDownloadLink(documentId: $documentId)
    }
  }
`

/**
 * __useGetInvestDocumentDownloadLinkQuery__
 *
 * To run a query within a React component, call `useGetInvestDocumentDownloadLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvestDocumentDownloadLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvestDocumentDownloadLinkQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useGetInvestDocumentDownloadLinkQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInvestDocumentDownloadLinkQuery,
    GetInvestDocumentDownloadLinkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetInvestDocumentDownloadLinkQuery,
    GetInvestDocumentDownloadLinkQueryVariables
  >(GetInvestDocumentDownloadLinkDocument, options)
}
export function useGetInvestDocumentDownloadLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInvestDocumentDownloadLinkQuery,
    GetInvestDocumentDownloadLinkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetInvestDocumentDownloadLinkQuery,
    GetInvestDocumentDownloadLinkQueryVariables
  >(GetInvestDocumentDownloadLinkDocument, options)
}
export type GetInvestDocumentDownloadLinkQueryHookResult = ReturnType<
  typeof useGetInvestDocumentDownloadLinkQuery
>
export type GetInvestDocumentDownloadLinkLazyQueryHookResult = ReturnType<
  typeof useGetInvestDocumentDownloadLinkLazyQuery
>
export type GetInvestDocumentDownloadLinkQueryResult = Apollo.QueryResult<
  GetInvestDocumentDownloadLinkQuery,
  GetInvestDocumentDownloadLinkQueryVariables
>
export function refetchGetInvestDocumentDownloadLinkQuery(
  variables: GetInvestDocumentDownloadLinkQueryVariables,
) {
  return { query: GetInvestDocumentDownloadLinkDocument, variables: variables }
}
export const CashAndTransfersDocument = gql`
  query cashAndTransfers(
    $limitTransfers: Int
    $offsetTransfers: Int
    $filterTransfers: AlpacaFundTransferDirection
  ) {
    invest {
      accountTradingInfo {
        cash
        currency
        buyingPower
        cashWithdrawable
        pendingTransferOut
      }
      fundTransfers(
        limit: $limitTransfers
        offset: $offsetTransfers
        direction: $filterTransfers
      ) {
        id
        type
        status
        direction
        amount
        requestedAmount
        updatedAt
      }
    }
  }
`

/**
 * __useCashAndTransfersQuery__
 *
 * To run a query within a React component, call `useCashAndTransfersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCashAndTransfersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCashAndTransfersQuery({
 *   variables: {
 *      limitTransfers: // value for 'limitTransfers'
 *      offsetTransfers: // value for 'offsetTransfers'
 *      filterTransfers: // value for 'filterTransfers'
 *   },
 * });
 */
export function useCashAndTransfersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CashAndTransfersQuery,
    CashAndTransfersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CashAndTransfersQuery, CashAndTransfersQueryVariables>(
    CashAndTransfersDocument,
    options,
  )
}
export function useCashAndTransfersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CashAndTransfersQuery,
    CashAndTransfersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CashAndTransfersQuery,
    CashAndTransfersQueryVariables
  >(CashAndTransfersDocument, options)
}
export type CashAndTransfersQueryHookResult = ReturnType<
  typeof useCashAndTransfersQuery
>
export type CashAndTransfersLazyQueryHookResult = ReturnType<
  typeof useCashAndTransfersLazyQuery
>
export type CashAndTransfersQueryResult = Apollo.QueryResult<
  CashAndTransfersQuery,
  CashAndTransfersQueryVariables
>
export function refetchCashAndTransfersQuery(
  variables?: CashAndTransfersQueryVariables,
) {
  return { query: CashAndTransfersDocument, variables: variables }
}
export const PortfolioHistoryDocument = gql`
  query portfolioHistory(
    $period: String
    $dateEnd: String
    $timeframe: String
    $extendedHours: Boolean
    $sinceMidnight: Boolean
  ) {
    invest {
      portfolioHistory(
        period: $period
        dateEnd: $dateEnd
        timeframe: $timeframe
        extendedHours: $extendedHours
        sinceMidnight: $sinceMidnight
      ) {
        timestamp
        equity
        profitLoss
        profitLossPct
        baseValue
        timeframe
      }
    }
  }
`

/**
 * __usePortfolioHistoryQuery__
 *
 * To run a query within a React component, call `usePortfolioHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioHistoryQuery({
 *   variables: {
 *      period: // value for 'period'
 *      dateEnd: // value for 'dateEnd'
 *      timeframe: // value for 'timeframe'
 *      extendedHours: // value for 'extendedHours'
 *      sinceMidnight: // value for 'sinceMidnight'
 *   },
 * });
 */
export function usePortfolioHistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PortfolioHistoryQuery,
    PortfolioHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PortfolioHistoryQuery, PortfolioHistoryQueryVariables>(
    PortfolioHistoryDocument,
    options,
  )
}
export function usePortfolioHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PortfolioHistoryQuery,
    PortfolioHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    PortfolioHistoryQuery,
    PortfolioHistoryQueryVariables
  >(PortfolioHistoryDocument, options)
}
export type PortfolioHistoryQueryHookResult = ReturnType<
  typeof usePortfolioHistoryQuery
>
export type PortfolioHistoryLazyQueryHookResult = ReturnType<
  typeof usePortfolioHistoryLazyQuery
>
export type PortfolioHistoryQueryResult = Apollo.QueryResult<
  PortfolioHistoryQuery,
  PortfolioHistoryQueryVariables
>
export function refetchPortfolioHistoryQuery(
  variables?: PortfolioHistoryQueryVariables,
) {
  return { query: PortfolioHistoryDocument, variables: variables }
}
export const StockBarsDocument = gql`
  query stockBars(
    $symbol: String!
    $timeframe: String!
    $start: String
    $end: String
    $limit: Int
    $pageToken: String
    $adjustment: String
    $asof: String
    $feed: AlpacaMarketFeed
    $currency: String
  ) {
    market {
      alpaca {
        bars(
          symbol: $symbol
          timeframe: $timeframe
          start: $start
          end: $end
          limit: $limit
          pageToken: $pageToken
          adjustment: $adjustment
          asof: $asof
          feed: $feed
          currency: $currency
        ) {
          symbol
          nextPageToken
          bars {
            t
            c
            v
          }
        }
      }
    }
  }
`

/**
 * __useStockBarsQuery__
 *
 * To run a query within a React component, call `useStockBarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockBarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockBarsQuery({
 *   variables: {
 *      symbol: // value for 'symbol'
 *      timeframe: // value for 'timeframe'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      limit: // value for 'limit'
 *      pageToken: // value for 'pageToken'
 *      adjustment: // value for 'adjustment'
 *      asof: // value for 'asof'
 *      feed: // value for 'feed'
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useStockBarsQuery(
  baseOptions: Apollo.QueryHookOptions<StockBarsQuery, StockBarsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<StockBarsQuery, StockBarsQueryVariables>(
    StockBarsDocument,
    options,
  )
}
export function useStockBarsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StockBarsQuery,
    StockBarsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<StockBarsQuery, StockBarsQueryVariables>(
    StockBarsDocument,
    options,
  )
}
export type StockBarsQueryHookResult = ReturnType<typeof useStockBarsQuery>
export type StockBarsLazyQueryHookResult = ReturnType<
  typeof useStockBarsLazyQuery
>
export type StockBarsQueryResult = Apollo.QueryResult<
  StockBarsQuery,
  StockBarsQueryVariables
>
export function refetchStockBarsQuery(variables: StockBarsQueryVariables) {
  return { query: StockBarsDocument, variables: variables }
}
export const GetPortfolioAccountsDocument = gql`
  query getPortfolioAccounts($after: String) {
    portfolio {
      accounts(first: 200, after: $after) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            name
            nickname
            accountHidden
            balance
            lastSyncTime
            holdingsLastSyncTime
            transactionsLastSyncTime
            holdingSyncStatus
            transactionsSyncStatus
            latestPrice
            latestChangePercent
            latestChange
            allTimeChangePercent
            allTimeChange
            priceAllTime
            compliantPercentage
            compliantPercentageWithFunds
            nonCompliantPercentage
            nonCompliantPercentageWithFunds
            unratedPercentage
            unratedPercentageWithFunds
            questionablePercentage
            questionablePercentageWithFunds
            accountId
            mask
            type
            subtype
            holdingsId
            institution
            institutionId
            linkStatus
            groupId
            currencyCode
            aggregator
            vendor
            cash {
              available
              currency
              current
              lastUpdatedDate
            }
            buyingPower
          }
        }
      }
    }
  }
`

/**
 * __useGetPortfolioAccountsQuery__
 *
 * To run a query within a React component, call `useGetPortfolioAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPortfolioAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPortfolioAccountsQuery({
 *   variables: {
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetPortfolioAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPortfolioAccountsQuery,
    GetPortfolioAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetPortfolioAccountsQuery,
    GetPortfolioAccountsQueryVariables
  >(GetPortfolioAccountsDocument, options)
}
export function useGetPortfolioAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPortfolioAccountsQuery,
    GetPortfolioAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetPortfolioAccountsQuery,
    GetPortfolioAccountsQueryVariables
  >(GetPortfolioAccountsDocument, options)
}
export type GetPortfolioAccountsQueryHookResult = ReturnType<
  typeof useGetPortfolioAccountsQuery
>
export type GetPortfolioAccountsLazyQueryHookResult = ReturnType<
  typeof useGetPortfolioAccountsLazyQuery
>
export type GetPortfolioAccountsQueryResult = Apollo.QueryResult<
  GetPortfolioAccountsQuery,
  GetPortfolioAccountsQueryVariables
>
export function refetchGetPortfolioAccountsQuery(
  variables?: GetPortfolioAccountsQueryVariables,
) {
  return { query: GetPortfolioAccountsDocument, variables: variables }
}
export const GetPortfolioHoldingSyncStatusDocument = gql`
  query getPortfolioHoldingSyncStatus {
    portfolio {
      account(accountId: "all") {
        __typename
        holdingSyncStatus
      }
    }
  }
`

/**
 * __useGetPortfolioHoldingSyncStatusQuery__
 *
 * To run a query within a React component, call `useGetPortfolioHoldingSyncStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPortfolioHoldingSyncStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPortfolioHoldingSyncStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPortfolioHoldingSyncStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPortfolioHoldingSyncStatusQuery,
    GetPortfolioHoldingSyncStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetPortfolioHoldingSyncStatusQuery,
    GetPortfolioHoldingSyncStatusQueryVariables
  >(GetPortfolioHoldingSyncStatusDocument, options)
}
export function useGetPortfolioHoldingSyncStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPortfolioHoldingSyncStatusQuery,
    GetPortfolioHoldingSyncStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetPortfolioHoldingSyncStatusQuery,
    GetPortfolioHoldingSyncStatusQueryVariables
  >(GetPortfolioHoldingSyncStatusDocument, options)
}
export type GetPortfolioHoldingSyncStatusQueryHookResult = ReturnType<
  typeof useGetPortfolioHoldingSyncStatusQuery
>
export type GetPortfolioHoldingSyncStatusLazyQueryHookResult = ReturnType<
  typeof useGetPortfolioHoldingSyncStatusLazyQuery
>
export type GetPortfolioHoldingSyncStatusQueryResult = Apollo.QueryResult<
  GetPortfolioHoldingSyncStatusQuery,
  GetPortfolioHoldingSyncStatusQueryVariables
>
export function refetchGetPortfolioHoldingSyncStatusQuery(
  variables?: GetPortfolioHoldingSyncStatusQueryVariables,
) {
  return { query: GetPortfolioHoldingSyncStatusDocument, variables: variables }
}
export const SnaptradeBrokeragesDocument = gql`
  query snaptradeBrokerages {
    portfolio {
      snaptrade {
        brokerages {
          id
          name
          displayName
          slug
          logoUrl
          squareLogoUrl
        }
      }
    }
  }
`

/**
 * __useSnaptradeBrokeragesQuery__
 *
 * To run a query within a React component, call `useSnaptradeBrokeragesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSnaptradeBrokeragesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSnaptradeBrokeragesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSnaptradeBrokeragesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SnaptradeBrokeragesQuery,
    SnaptradeBrokeragesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SnaptradeBrokeragesQuery,
    SnaptradeBrokeragesQueryVariables
  >(SnaptradeBrokeragesDocument, options)
}
export function useSnaptradeBrokeragesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SnaptradeBrokeragesQuery,
    SnaptradeBrokeragesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SnaptradeBrokeragesQuery,
    SnaptradeBrokeragesQueryVariables
  >(SnaptradeBrokeragesDocument, options)
}
export type SnaptradeBrokeragesQueryHookResult = ReturnType<
  typeof useSnaptradeBrokeragesQuery
>
export type SnaptradeBrokeragesLazyQueryHookResult = ReturnType<
  typeof useSnaptradeBrokeragesLazyQuery
>
export type SnaptradeBrokeragesQueryResult = Apollo.QueryResult<
  SnaptradeBrokeragesQuery,
  SnaptradeBrokeragesQueryVariables
>
export function refetchSnaptradeBrokeragesQuery(
  variables?: SnaptradeBrokeragesQueryVariables,
) {
  return { query: SnaptradeBrokeragesDocument, variables: variables }
}
export const CreateSnaptradeConnectionUrlDocument = gql`
  mutation createSnaptradeConnectionUrl(
    $input: CreateSnaptradeConnectionUrlInput
  ) {
    portfolio {
      snaptrade {
        createConnectionUrl(input: $input) {
          sessionId
          redirectUri
        }
      }
    }
  }
`
export type CreateSnaptradeConnectionUrlMutationFn = Apollo.MutationFunction<
  CreateSnaptradeConnectionUrlMutation,
  CreateSnaptradeConnectionUrlMutationVariables
>

/**
 * __useCreateSnaptradeConnectionUrlMutation__
 *
 * To run a mutation, you first call `useCreateSnaptradeConnectionUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSnaptradeConnectionUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSnaptradeConnectionUrlMutation, { data, loading, error }] = useCreateSnaptradeConnectionUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSnaptradeConnectionUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSnaptradeConnectionUrlMutation,
    CreateSnaptradeConnectionUrlMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateSnaptradeConnectionUrlMutation,
    CreateSnaptradeConnectionUrlMutationVariables
  >(CreateSnaptradeConnectionUrlDocument, options)
}
export type CreateSnaptradeConnectionUrlMutationHookResult = ReturnType<
  typeof useCreateSnaptradeConnectionUrlMutation
>
export type CreateSnaptradeConnectionUrlMutationResult =
  Apollo.MutationResult<CreateSnaptradeConnectionUrlMutation>
export type CreateSnaptradeConnectionUrlMutationOptions =
  Apollo.BaseMutationOptions<
    CreateSnaptradeConnectionUrlMutation,
    CreateSnaptradeConnectionUrlMutationVariables
  >
export const SyncAllInstitutionsDocument = gql`
  mutation syncAllInstitutions {
    portfolio {
      syncAccount(accountId: "*") {
        success
      }
    }
  }
`
export type SyncAllInstitutionsMutationFn = Apollo.MutationFunction<
  SyncAllInstitutionsMutation,
  SyncAllInstitutionsMutationVariables
>

/**
 * __useSyncAllInstitutionsMutation__
 *
 * To run a mutation, you first call `useSyncAllInstitutionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncAllInstitutionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncAllInstitutionsMutation, { data, loading, error }] = useSyncAllInstitutionsMutation({
 *   variables: {
 *   },
 * });
 */
export function useSyncAllInstitutionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyncAllInstitutionsMutation,
    SyncAllInstitutionsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SyncAllInstitutionsMutation,
    SyncAllInstitutionsMutationVariables
  >(SyncAllInstitutionsDocument, options)
}
export type SyncAllInstitutionsMutationHookResult = ReturnType<
  typeof useSyncAllInstitutionsMutation
>
export type SyncAllInstitutionsMutationResult =
  Apollo.MutationResult<SyncAllInstitutionsMutation>
export type SyncAllInstitutionsMutationOptions = Apollo.BaseMutationOptions<
  SyncAllInstitutionsMutation,
  SyncAllInstitutionsMutationVariables
>
export const SaveSnaptradeConnectionDocument = gql`
  mutation saveSnaptradeConnection($brokerageAuthorizationId: ID!) {
    portfolio {
      snaptrade {
        saveConnection(brokerageAuthorizationId: $brokerageAuthorizationId) {
          brokerageAuthorizationId
        }
      }
    }
  }
`
export type SaveSnaptradeConnectionMutationFn = Apollo.MutationFunction<
  SaveSnaptradeConnectionMutation,
  SaveSnaptradeConnectionMutationVariables
>

/**
 * __useSaveSnaptradeConnectionMutation__
 *
 * To run a mutation, you first call `useSaveSnaptradeConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSnaptradeConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSnaptradeConnectionMutation, { data, loading, error }] = useSaveSnaptradeConnectionMutation({
 *   variables: {
 *      brokerageAuthorizationId: // value for 'brokerageAuthorizationId'
 *   },
 * });
 */
export function useSaveSnaptradeConnectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveSnaptradeConnectionMutation,
    SaveSnaptradeConnectionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SaveSnaptradeConnectionMutation,
    SaveSnaptradeConnectionMutationVariables
  >(SaveSnaptradeConnectionDocument, options)
}
export type SaveSnaptradeConnectionMutationHookResult = ReturnType<
  typeof useSaveSnaptradeConnectionMutation
>
export type SaveSnaptradeConnectionMutationResult =
  Apollo.MutationResult<SaveSnaptradeConnectionMutation>
export type SaveSnaptradeConnectionMutationOptions = Apollo.BaseMutationOptions<
  SaveSnaptradeConnectionMutation,
  SaveSnaptradeConnectionMutationVariables
>
export const UnlinkPortfolioAccountDocument = gql`
  mutation unlinkPortfolioAccount($accountId: String!) {
    portfolio {
      unlinkAccount(accountId: $accountId) {
        success
      }
    }
  }
`
export type UnlinkPortfolioAccountMutationFn = Apollo.MutationFunction<
  UnlinkPortfolioAccountMutation,
  UnlinkPortfolioAccountMutationVariables
>

/**
 * __useUnlinkPortfolioAccountMutation__
 *
 * To run a mutation, you first call `useUnlinkPortfolioAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkPortfolioAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkPortfolioAccountMutation, { data, loading, error }] = useUnlinkPortfolioAccountMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useUnlinkPortfolioAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnlinkPortfolioAccountMutation,
    UnlinkPortfolioAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UnlinkPortfolioAccountMutation,
    UnlinkPortfolioAccountMutationVariables
  >(UnlinkPortfolioAccountDocument, options)
}
export type UnlinkPortfolioAccountMutationHookResult = ReturnType<
  typeof useUnlinkPortfolioAccountMutation
>
export type UnlinkPortfolioAccountMutationResult =
  Apollo.MutationResult<UnlinkPortfolioAccountMutation>
export type UnlinkPortfolioAccountMutationOptions = Apollo.BaseMutationOptions<
  UnlinkPortfolioAccountMutation,
  UnlinkPortfolioAccountMutationVariables
>
export const CreatePlaidLinkTokenDocument = gql`
  mutation createPlaidLinkToken(
    $accountId: String
    $countryCodes: [SupportedCountryCodes]
  ) {
    plaid {
      createLinkToken(accountId: $accountId, countryCodes: $countryCodes) {
        linkToken
      }
    }
  }
`
export type CreatePlaidLinkTokenMutationFn = Apollo.MutationFunction<
  CreatePlaidLinkTokenMutation,
  CreatePlaidLinkTokenMutationVariables
>

/**
 * __useCreatePlaidLinkTokenMutation__
 *
 * To run a mutation, you first call `useCreatePlaidLinkTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlaidLinkTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlaidLinkTokenMutation, { data, loading, error }] = useCreatePlaidLinkTokenMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      countryCodes: // value for 'countryCodes'
 *   },
 * });
 */
export function useCreatePlaidLinkTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePlaidLinkTokenMutation,
    CreatePlaidLinkTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreatePlaidLinkTokenMutation,
    CreatePlaidLinkTokenMutationVariables
  >(CreatePlaidLinkTokenDocument, options)
}
export type CreatePlaidLinkTokenMutationHookResult = ReturnType<
  typeof useCreatePlaidLinkTokenMutation
>
export type CreatePlaidLinkTokenMutationResult =
  Apollo.MutationResult<CreatePlaidLinkTokenMutation>
export type CreatePlaidLinkTokenMutationOptions = Apollo.BaseMutationOptions<
  CreatePlaidLinkTokenMutation,
  CreatePlaidLinkTokenMutationVariables
>
export const SavePlaidLinkedAccountDocument = gql`
  mutation savePlaidLinkedAccount(
    $publicToken: String!
    $institution: PlaidInstitution!
    $accounts: [PlaidAccountMeta!]!
  ) {
    plaid {
      saveLinkedAccount(
        publicToken: $publicToken
        institution: $institution
        accounts: $accounts
      ) {
        success
      }
    }
  }
`
export type SavePlaidLinkedAccountMutationFn = Apollo.MutationFunction<
  SavePlaidLinkedAccountMutation,
  SavePlaidLinkedAccountMutationVariables
>

/**
 * __useSavePlaidLinkedAccountMutation__
 *
 * To run a mutation, you first call `useSavePlaidLinkedAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavePlaidLinkedAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savePlaidLinkedAccountMutation, { data, loading, error }] = useSavePlaidLinkedAccountMutation({
 *   variables: {
 *      publicToken: // value for 'publicToken'
 *      institution: // value for 'institution'
 *      accounts: // value for 'accounts'
 *   },
 * });
 */
export function useSavePlaidLinkedAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SavePlaidLinkedAccountMutation,
    SavePlaidLinkedAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SavePlaidLinkedAccountMutation,
    SavePlaidLinkedAccountMutationVariables
  >(SavePlaidLinkedAccountDocument, options)
}
export type SavePlaidLinkedAccountMutationHookResult = ReturnType<
  typeof useSavePlaidLinkedAccountMutation
>
export type SavePlaidLinkedAccountMutationResult =
  Apollo.MutationResult<SavePlaidLinkedAccountMutation>
export type SavePlaidLinkedAccountMutationOptions = Apollo.BaseMutationOptions<
  SavePlaidLinkedAccountMutation,
  SavePlaidLinkedAccountMutationVariables
>
export const GetPlaidInstitutionDocument = gql`
  query getPlaidInstitution(
    $institutionId: String!
    $countryCodes: [SupportedCountryCodes]
  ) {
    portfolio {
      institution(institutionId: $institutionId, countryCodes: $countryCodes) {
        institution {
          name
          institutionId
          countryCodes
          primaryColor
          url
          logo
          investmentUpdateHealth {
            healthStatus
          }
        }
      }
    }
  }
`

/**
 * __useGetPlaidInstitutionQuery__
 *
 * To run a query within a React component, call `useGetPlaidInstitutionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlaidInstitutionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlaidInstitutionQuery({
 *   variables: {
 *      institutionId: // value for 'institutionId'
 *      countryCodes: // value for 'countryCodes'
 *   },
 * });
 */
export function useGetPlaidInstitutionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPlaidInstitutionQuery,
    GetPlaidInstitutionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetPlaidInstitutionQuery,
    GetPlaidInstitutionQueryVariables
  >(GetPlaidInstitutionDocument, options)
}
export function useGetPlaidInstitutionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlaidInstitutionQuery,
    GetPlaidInstitutionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetPlaidInstitutionQuery,
    GetPlaidInstitutionQueryVariables
  >(GetPlaidInstitutionDocument, options)
}
export type GetPlaidInstitutionQueryHookResult = ReturnType<
  typeof useGetPlaidInstitutionQuery
>
export type GetPlaidInstitutionLazyQueryHookResult = ReturnType<
  typeof useGetPlaidInstitutionLazyQuery
>
export type GetPlaidInstitutionQueryResult = Apollo.QueryResult<
  GetPlaidInstitutionQuery,
  GetPlaidInstitutionQueryVariables
>
export function refetchGetPlaidInstitutionQuery(
  variables: GetPlaidInstitutionQueryVariables,
) {
  return { query: GetPlaidInstitutionDocument, variables: variables }
}

import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { signUpSchema } from '@/auth/validation'
import { z } from 'zod'

import { Link, useNavigate } from 'react-router-dom'
import { ExclamationTriangleIcon } from '@radix-ui/react-icons'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { useCallback } from 'react'
import { useSignUp } from '@/auth/hooks/use-sign-up'

export const SignUpForm = () => {
  const navigate = useNavigate()

  const form = useForm<z.infer<typeof signUpSchema>>({
    defaultValues: {
      email: '',
      password: '',
      confirmPassword: '',
    },
    resolver: zodResolver(signUpSchema),
    mode: 'onBlur',
  })

  const handleAuthSuccess = useCallback(() => {
    navigate('/auth/email-verification', {
      state: { email: form.watch('email'), password: form.watch('password') },
    })
  }, [])

  const { signUp, error } = useSignUp(handleAuthSuccess)

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(signUp)}>
        <div className="grid gap-4">
          <div className="grid gap-2">
            {error && (
              <Alert variant="destructive">
                <ExclamationTriangleIcon className="h-4 w-4" />
                <AlertTitle>Failed to create account</AlertTitle>
                <AlertDescription>{error.message}</AlertDescription>
              </Alert>
            )}
          </div>
          <div className="grid gap-4 mb-6">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input required {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Password</FormLabel>
                  <FormControl>
                    <Input required type="password" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="confirmPassword"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Confirm Password</FormLabel>
                  <FormControl>
                    <Input required type="password" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
        <Button
          type="submit"
          formAction="submit"
          className="w-full"
          disabled={form.formState.isSubmitting}
          loading={form.formState.isSubmitting}
        >
          Create account
        </Button>
        <div className="mt-4 text-center text-sm">
          already have an account?{' '}
          <Link to="/auth/log-in" className="underline">
            Log in
          </Link>
        </div>
      </form>
    </Form>
  )
}

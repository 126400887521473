import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { resetPasswordSchema } from '@/auth/validation'
import { z } from 'zod'

import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { ExclamationTriangleIcon } from '@radix-ui/react-icons'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { useCallback } from 'react'
import { useResetPassword } from '@/auth/hooks/use-reset-password'

export const PasswordResetForm = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const email = z
    .object({ email: z.string().email() })
    .safeParse(location.state).data?.email

  const form = useForm<z.infer<typeof resetPasswordSchema>>({
    defaultValues: {
      email,
      code: '',
      newPassword: '',
      newPasswordConfirmation: '',
    },
    resolver: zodResolver(resetPasswordSchema),
    mode: 'onBlur',
  })

  const handleResetSuccess = useCallback(() => {
    navigate('/auth/log-in', {
      state: {
        passwordReset: true,
      },
    })
  }, [])

  const { request, error } = useResetPassword(handleResetSuccess)

  if (!email) {
    return <Navigate to="/auth/forgot-password" />
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(request)}>
        <div className="grid gap-4">
          <div className="grid gap-2">
            {error && (
              <Alert variant="destructive">
                <ExclamationTriangleIcon className="h-4 w-4" />
                <AlertTitle>Failed to reset password</AlertTitle>
                <AlertDescription>{error.message}</AlertDescription>
              </Alert>
            )}
          </div>
          <div className="grid gap-4 mb-6">
            <FormField
              control={form.control}
              name="code"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Code</FormLabel>
                  <FormControl>
                    <Input required {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="newPassword"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>New Password</FormLabel>
                  <FormControl>
                    <Input required type="password" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="newPasswordConfirmation"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Confirm New Password</FormLabel>
                  <FormControl>
                    <Input required type="password" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
        <Button
          type="submit"
          formAction="submit"
          className="w-full"
          disabled={form.formState.isSubmitting}
          loading={form.formState.isSubmitting}
        >
          Reset password
        </Button>
      </form>
    </Form>
  )
}

import { memoize } from 'lodash'
import numeral from 'numeral'
import getSymbolFromCurrency from 'currency-symbol-map'


const cryptoCurrencies = new Set(['BTC'])
const isCryptoCurrency = (currency?: string | null) =>
  currency && cryptoCurrencies.has(currency)

const getSymbol = (currency?: string) => {
  let symbol: string = ''
  if (currency) {
    symbol = getSymbolFromCurrency(currency) ?? '$'
  }
  if (isCryptoCurrency(currency)) {
    symbol = ''
  }
  return symbol !== 'undefined' ? symbol : ''
}

interface NumberFormatProps {
  currency?: string
}

export class NumberFormat {
  readonly currency?: string
  moneyFormatter: (value: number) => string
  changeFormatter: (value: number) => string
  changePercentFormatter: (value: number) => string

  constructor(args: NumberFormatProps) {
    const { currency } = args
    this.currency = currency

    this.moneyFormatter = (value: number) => {
      const format = isCryptoCurrency(this.currency) ? '0,0.[000000]' : '0,0.00'
      const symbol = getSymbol(this.currency)
      const formattedValue = numeral(value).format(format)
      return `${symbol}${formattedValue}`
    }

    this.changeFormatter = (value: number) => {
      const format = isCryptoCurrency(this.currency)
        ? '0,0.[000000]'
        : '0,0.[00]'
      const symbol = getSymbol(this.currency)
      const formattedValue = numeral(value).format(format)
      return `${symbol}${formattedValue}`
    }

    this.changePercentFormatter = (value: number) => {
      const format = '0,0.00%'
      const sign = value < 0 ? '-' : '+'
      const formattedValue = numeral(Math.abs(value)).format(format)
      return `${sign}${formattedValue}`
    }
  }
}

export const generateNumberFormatter = memoize(
  (currency: string, options?: Omit<NumberFormatProps, 'currency'>) => {
    const formatter = new NumberFormat({ currency, ...options })

    return {
      money: formatter.moneyFormatter,
      change: formatter.changeFormatter,
      changePercent: formatter.changePercentFormatter,
    }
  }
)

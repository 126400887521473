import { useTheme } from '../hooks/use-theme'

export const ZoyaLogo = ({ className }: { className: string }) => {
  const { theme } = useTheme()

  if (theme === 'light') {
    return <img src="/zoya-logo.svg" alt="Zoya Finance" className={className} />
  } else {
    return (
      <img src="/zoya-logo-dark.svg" alt="Zoya Finance" className={className} />
    )
  }
}

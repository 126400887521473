import { TypeOf, z } from 'zod'

export const ZakatHoldingSchema = z.object({
  symbol: z.string(),
  name: z.string(),
  quantity: z.number(),
  unitPrice: z.number(),
  currency: z.string(),
  strategy: z.enum(['PASSIVE', 'ACTIVE']),
  currentAssets: z.number(),
  outstandingShares: z.number(),
  dataFetch: z.object({
    missingFields: z.array(z.string()).nullish(),
    finished: z.boolean(),
    error: z.string().nullish(),
  }),
})
export type ZakatHolding = z.infer<typeof ZakatHoldingSchema>

export const ZakatCalculationResponseSchema = z.object({
  calculationDate: z.string(),
  zakatLiableAmount: z.number(),
  zakatableAmount: z.number(),
  zakatDue: z.number(),
  currency: z.string(),
  holdingsMarketValue: z.number(),
  holdingsCount: z.number(),
  holdings: z.array(
    z.object({
      symbol: z.string(),
      name: z.string(),
      strategy: z.enum(['PASSIVE', 'ACTIVE']),
      treatAsCash: z.boolean(),
      quantity: z.number(),
      unitPrice: z.number(),
      currency: z.string(),
      outstandingShares: z.number().nullish(),
      currentAssets: z.number().nullish(),
      marketValue: z.number(),
      zakatLiableAmount: z.number(),
      zakatableAmount: z.number(),
      zakatDue: z.number(),
      calculationMethod: z.string(),
    }),
  ),
})
export type ZakatCalculationResponse = z.infer<
  typeof ZakatCalculationResponseSchema
>

export const CalculationSummarySchema = z.object({
  /**
   *  the dollar amount you own of a holding
   */
  baseValue: z.number(),
  /**
   *  the dollar amount that you must pay zakat on (should be equal to the
   *  baseValue in the case of activeHolding )
   */
  zakatableAmount: z.number(),
  /**
   *  zakatableAmount * 2.5%
   */
  zakatDue: z.number(),
})
export type CalculationSummary = z.infer<typeof CalculationSummarySchema>

export type SuccessCalculation = CalculationSummary & { status: 'SUCCESS' }
export type Fallback30Calculation = CalculationSummary & {
  status: 'FALLBACK30'
}
export type FailedCalculation = { status: 'FAIL' }

export type CalculationResult = Partial<ZakatHolding> &
  (SuccessCalculation | Fallback30Calculation | FailedCalculation)

export const ZakatCalculationSchema = z.object({
  zakatDue: z.number(),
  holdings: z.array(
    z.object({
      symbol: z.string(),
      name: z.string(),
      shares: z.number(),
      unitPrice: z.number(),
      currency: z.string(),
      strategy: z.enum(['PASSIVE', 'ACTIVE']),
      currentAssets: z.number().nullish(),
      outstandingShares: z.number().nullish(),
      zakatDue: z.number(),
      zakatLiableAmount: z.number(),
      zakatableAmount: z.number(),
      calculationMethod: z.string(),
      dataFetch: z
        .object({
          missingFields: z.array(z.string()),
          finished: z.boolean(),
          error: z.string(),
        })
        .nullish(),
    }),
  ),
  summary: z.object({
    active: CalculationSummarySchema,
    passive: CalculationSummarySchema,
    successCount: z.number(),
    fallbackCount: z.number(),
    failCount: z.number(),
  }),
})

export type ZakatCalculation = z.infer<typeof ZakatCalculationSchema>

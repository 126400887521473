import { z } from 'zod'
import { signInSchema } from '../validation'
import { useState } from 'react'
import { signIn } from '../api'

export const useSignIn = (onSuccess?: () => void) => {
  const [error, setError] = useState<Error>()
  const handler = async (args: z.infer<typeof signInSchema>) => {
    setError(undefined)
    const res = await signIn(args)
    if (res.error) {
      setError(res.error as Error)
    } else {
      onSuccess && onSuccess()
    }
  }

  return { signIn: handler, error }
}

import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { CheckIcon, ExternalLinkIcon } from '@radix-ui/react-icons'
import { useCustomerInfo } from '@/purchases/hooks/use-customer-info'
import { useOfferings } from '@/purchases/hooks/use-offerings'
import { format } from 'date-fns'
import { Link } from 'react-router-dom'
import { isBoolean, isDate, capitalize } from 'lodash'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { AvailablePackages } from './available-packages'
import { useCurrentUser } from '@/auth/hooks/use-current-user'
import { differenceInDays, differenceInMonths, differenceInWeeks } from 'date-fns'
import { UpgradeButton } from './upgrade-button'

// Helper function to format dates
const formatDate = (date: Date) => {
  return format(date, 'MMM dd, yyyy')
}

export const SubscriptionSection = () => {
  // Hooks to fetch user and subscription data
  const { info, isZoyaProUser } = useCustomerInfo()
  const { allOfferings } = useOfferings()
  const { user } = useCurrentUser()

  // Extract subscription details
  const activeEntitlement = info?.entitlements.active['Zoya Pro']
  const renewalDate = info?.entitlements.active['Zoya Pro']?.expirationDate
  const renewing = info?.entitlements.active['Zoya Pro']?.willRenew
  const unsubscribed =
    isBoolean(renewing) &&
    !renewing &&
    isDate(info?.entitlements.active['Zoya Pro']?.unsubscribeDetectedAt)
  const managementUrl = info?.managementURL

  // Find the active package and its details
  const activePackage = activeEntitlement
    ? allOfferings?.all[activeEntitlement.identifier].availablePackages.find(
        (_) =>
          _.rcBillingProduct.identifier === activeEntitlement.productIdentifier,
      )
    : undefined
  const price = activePackage?.rcBillingProduct.currentPrice
  const interval =
    activePackage?.rcBillingProduct.normalPeriodDuration === 'P1Y'
      ? 'year'
      : 'month'

  const isTrial = activeEntitlement?.periodType === 'trial'
  const isPromotional = activeEntitlement?.store === 'promotional'

  // Helper function to format time until expiry
  const formatTimeUntilExpiry = (expiryDate: Date) => {
    const now = new Date()
    const months = differenceInMonths(expiryDate, now)
    const weeks = differenceInWeeks(expiryDate, now)
    const days = differenceInDays(expiryDate, now)

    if (months > 0) {
      return `${months} month${months !== 1 ? 's' : ''}`
    } else if (weeks > 0) {
      return `${weeks} week${weeks !== 1 ? 's' : ''}`
    } else if (days > 0) {
      return `${days} day${days !== 1 ? 's' : ''}`
    } else {
      return 'today'
    }
  }

  const timeUntilExpiry = renewalDate ? formatTimeUntilExpiry(renewalDate) : ''

  const billingSource = activeEntitlement?.store

  // Determine subscription status
  const isTrialExpiring = isTrial && !renewing && renewalDate && differenceInDays(renewalDate, new Date()) <= 3
  const isTrialRenewing = isTrial && renewing
  const isExpiredTrial = isTrial && !renewing && renewalDate && new Date() > renewalDate
  const isPreviouslySubscribed = !isZoyaProUser && info?.entitlements.all['Zoya Pro']

  // Generate appropriate subscription message based on status
  const getSubscriptionMessage = () => {
    // Non-Zoya Pro users
    if (!isZoyaProUser) {
      // Previously subscribed user whose subscription has ended
      if (isPreviouslySubscribed && renewalDate) {
        return `Your subscription ended on ${formatDate(renewalDate)}. Renew now to regain access to premium features.`;
      } 
      // User who completed a trial but didn't subscribe
      else if (isExpiredTrial && renewalDate) {
        return `Your trial ended on ${formatDate(renewalDate)}. Upgrade now to continue enjoying premium features.`;
      }
      // New user or fallback message
      return 'Subscribe to Zoya Pro and gain access to premium insights and features.';
    } 
    // Current Zoya Pro users
    else {
      // Subscription or trial set to auto-renew
      if (renewing) {
        // User in trial period with auto-renew on
        if (isTrial) {
          return `Your free trial will automatically convert to a paid subscription in ${timeUntilExpiry}.`;
        } 
        // Active subscriber with auto-renew on
        return `You're subscribed to Zoya Pro. Your subscription will automatically renew in ${timeUntilExpiry}.`;
      } 
      // Subscription or trial not set to auto-renew
      else {
        // User in trial period with auto-renew off
        if (isTrial) {
          return `Your free trial ends in ${timeUntilExpiry}. Upgrade now to maintain uninterrupted access.`;
        } 
        // Active subscriber with auto-renew off
        return `Your subscription ends in ${timeUntilExpiry}. Renew to maintain uninterrupted access.`;
      }
    }
  }

  const isActiveSubscription = isZoyaProUser && !isExpiredTrial && !(isTrial && !renewing)

  // Function to get the management URL based on billing source
  const getManagementUrl = (managementUrl: string | null | undefined, billingSource: string | undefined): string | undefined => {
    if (managementUrl) {
      return managementUrl
    } else {
      switch (billingSource) {
        case 'stripe':
          const stripeUrl = new URL('https://billing.stripe.com/p/login/9AQdTb7zj2oM6qs8ww')
          if (user?.email) {
            stripeUrl.searchParams.append('prefilled_email', user.email)
          }
          return stripeUrl.toString()
        case 'app_store':
        case 'mac_app_store':
          return 'https://apps.apple.com/account/subscriptions'
        case 'play_store':
          return 'https://play.google.com/store/account/subscriptions?package=com.investroo.zoya'
        default:
          console.error('Unknown billing source:', billingSource)
          return undefined
      }
    }
  }

  return (
    <section className="w-full space-y-4">
      <h2 className="text-lg font-medium">Subscription</h2>
      <div className="grid gap-4">
        <div className="space-y-4 border-2 border-proGold p-6 rounded-lg">
          <h2 className="text-lg font-medium text-proGold mb-2">
            Zoya Pro
          </h2>
          {isZoyaProUser ? (
            // Display for Zoya Pro users
            <>
              <p className="text-sm mb-4">{getSubscriptionMessage()}</p>
              {isActiveSubscription && (
                // Subscription details grid
                <div className="grid grid-cols-2 gap-4 text-sm">
                  {/* Subscription details */}
                  <div>
                    <p className="font-medium">Plan</p>
                    <p>{interval === 'year' ? 'Annual' : 'Monthly'}</p>
                  </div>
                  <div>
                    <p className="font-medium">Subscriber since</p>
                    <p>{formatDate(activeEntitlement?.originalPurchaseDate || new Date())}</p>
                  </div>
                  <div>
                    <p className="font-medium">Billing via</p>
                    <p>{billingSource ? capitalize(billingSource) : 'Unknown'}</p>
                  </div>
                  <div>
                    <p className="font-medium">{renewing ? 'Renews on' : 'Expires on'}</p>
                    <p>{renewalDate ? formatDate(renewalDate) : 'N/A'}</p>
                  </div>
                </div>
              )}
              {getManagementUrl(managementUrl, billingSource) && (
                <div>
                  <a href={getManagementUrl(managementUrl, billingSource)} target="_blank" rel="noopener noreferrer">
                    <Button variant="pro" className="bg-proGold w-full">
                      {(!renewing || isTrialExpiring) ? "Renew subscription" : "Manage subscription"}
                    </Button>
                  </a>
                </div>
              )}
            </>
          ) : (
            // Display for non-Zoya Pro users
            <>
              <p className="mb-4">{getSubscriptionMessage()}</p>
              {/* Feature list for Zoya Pro */}
              <ul className="space-y-2 mb-4">
                <li className="flex items-center text-sm">
                  <CheckIcon className="h-5 w-5 mr-1 flex-shrink-0 text-proGold" />
                  Trusted by thousands of members
                </li>
                <li className="flex items-center text-sm">
                  <CheckIcon className="h-5 w-5 mr-1 flex-shrink-0 text-proGold" />
                  Start with a free 7-day trial
                </li>
                <li className="flex items-center text-sm">
                  <CheckIcon className="h-5 w-5 mr-1 flex-shrink-0 text-proGold" />
                  Cancel at any time
                </li>
              </ul>
              <UpgradeButton className="w-full" />
            </>
          )}
        </div>
      </div>
    </section>
  )
}
import { UserDropdown } from '@/common/components/user-dropdown'
import { ZoyaLogo } from '@/common/components/zoya-logo'
import { Button } from '@/components/ui/button'
import { DataTable } from '@/components/ui/data-table'
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet'
import {
  HamburgerMenuIcon,
  InfoCircledIcon,
  PlusIcon,
  CaretSortIcon,
  ExclamationTriangleIcon,
} from '@radix-ui/react-icons'
import { ColumnDef } from '@tanstack/react-table'
import { Link } from 'react-router-dom'
import {
  ZakatCalculation,
  ZakatCalculationResponse,
  ZakatHolding,
} from '../types'
import { Card, CardContent, CardHeader } from '@/components/ui/card'
import { Skeleton } from '@/components/ui/skeleton'
import { AddHoldingModal } from './components/add-holding-modal'
import { MethodologyModal } from './components/methodology-modal'
import { useZakatCalculator } from '../hooks/use-zakat-calculator'
import { useCurrentUser } from '@/auth/hooks/use-current-user'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/components/ui/collapsible'
import { useMemo } from 'react'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { ResultsHoldingsTable } from './components/result-holdings-table'
import { HoldingsTable } from './components/holdings-table'
import { useScrollIntoView } from '@mantine/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLinkedin,
  faFacebookSquare,
  faSquareXTwitter,
  faWhatsappSquare,
} from '@fortawesome/free-brands-svg-icons'

const navItems = [
  {
    label: 'Community',
    href: 'https://community.zoya.finance',
  },
  {
    label: 'Blog',
    href: 'https://blog.zoya.finance',
  },
  {
    label: 'About',
    href: 'https://zoya.finance/about',
  },
  {
    label: 'API',
    href: 'https://zoya.finance/api',
  },
]

export const CalculatorPage = () => {
  const { scrollIntoView, targetRef } = useScrollIntoView<HTMLDivElement>({
    offset: 60,
  })
  const { holdings, calculation, addHolding, removeHolding, calculate } =
    useZakatCalculator({
      onCalculationSuccess: scrollIntoView,
    })

  const resultActiveHoldings = useMemo(() => {
    if (!calculation.result) {
      return null
    }
    return calculation.result?.holdings.filter((_) => _.strategy === 'ACTIVE')
  }, [calculation.result])
  const resultPassiveHoldings = useMemo(() => {
    if (!calculation.result) {
      return null
    }
    return calculation.result?.holdings.filter((_) => _.strategy === 'PASSIVE')
  }, [calculation.result])

  return (
    <div className="relative min-h-screen space-y-6 xs:p-0  sm:p-2 lg:p-10 lg:max-w-screen-2xl bg-background-80 flex flex-col content-center mx-auto">
      <header className="sticky container flex items-center justify-between space-y-2 py-4 sm:flex-row sm:items-center sm:space-y-0 md:h-16">
        <Link to="/">
          <ZoyaLogo className="w-28 max-w-28 align-middle" />
        </Link>
        <nav className="hidden md:flex ml-0 space-x-12 sm:justify-end items-center">
          <Button variant="default">
            <a href="http://onelink.to/d23j9g" target="_blank">
              Get the app
            </a>
          </Button>
        </nav>
        <Sheet>
          <SheetTrigger asChild>
            <Button
              variant="outline"
              size="icon"
              className="shrink-0 md:hidden"
            >
              <HamburgerMenuIcon className="h-5 w-5" />
              <span className="sr-only">Toggle navigation menu</span>
            </Button>
          </SheetTrigger>
          <SheetContent side="left">
            <nav className="grid gap-6 text-lg font-medium">
              <Link
                to="/"
                className="flex items-center gap-2 text-lg font-semibold"
              >
                <ZoyaLogo className="w-28" />
              </Link>
              {navItems.map((_) => (
                <a
                  key={_.label}
                  href={_.href}
                  className="flex items-center gap-2 text-lg font-normal"
                  target="_blank"
                >
                  {_.label}
                </a>
              ))}
              <Button variant="default">Get the app</Button>
            </nav>
          </SheetContent>
        </Sheet>
      </header>
      <div className="flex flex-col space-y-8 md:flex-row md:justify-center lg:space-x-12 lg:space-y-0">
        <div className="flex-1  px-6">
          <div className="grid grid-cols-1 gap-8 py-12">
            <div className="text-center ">
              <h2 className="text-lg font-bold md:text-2xl">
                Calculate Zakat on Stocks and Funds
              </h2>
              <p className="text-sm md:text-xl">
                <span className="avoidwrap">
                  A free tool to help you instantly and accurately calculate{' '}
                </span>
                <br className="hidden md:block" />
                <span className="avoidwrap">
                  how much zakat you owe on your investments.
                </span>
              </p>
            </div>
            <div className="flex justify-center">
              <MethodologyModal
                trigger={
                  <Button variant="secondary">
                    <InfoCircledIcon className="mr-1" />
                    Methodology
                  </Button>
                }
              />
            </div>
            <Card>
              <CardHeader>
                {calculation.error && (
                  <Alert variant="destructive" className="mb-4">
                    <ExclamationTriangleIcon className="h-4 w-4" />
                    <AlertTitle>Calculation failure</AlertTitle>
                    <AlertDescription>
                      {calculation.error?.message}
                    </AlertDescription>
                  </Alert>
                )}
                <div className="flex flex-col sm:flex-row justify-between">
                  <h1 className="text-xl font-semibold pb-2">
                    Holdings
                    <span className="font-normal">
                      {' '}
                      {holdings.length ? `(${holdings.length})` : ''}
                    </span>
                  </h1>
                  <div className=" flex flex-row flex-wrap flex-1 items-center sm:justify-end justify-start">
                    <AddHoldingModal
                      trigger={
                        <Button
                          variant="outline"
                          size="sm"
                          className="mr-2 mb-2"
                        >
                          <PlusIcon className=" mr-2 h-4 w-4" />
                          Add Holding
                        </Button>
                      }
                      addHolding={addHolding}
                    />
                    {holdings.length !== 0 && (
                      <Button
                        variant="default"
                        size="sm"
                        className="mr-2 mb-2"
                        onClick={calculate}
                        loading={calculation.loading}
                        disabled={calculation.loading}
                      >
                        Calculate
                      </Button>
                    )}
                  </div>
                </div>
              </CardHeader>
              <CardContent>
                <HoldingsTable
                  holdings={holdings}
                  emptyContent={
                    <div className="h-[360px] flex flex-col justify-center items-center">
                      <AddHoldingModal
                        trigger={
                          <Button variant="outline" size="sm">
                            <PlusIcon className="mr-2 h-4 w-4" />
                            Add Holding
                          </Button>
                        }
                        addHolding={addHolding}
                      />
                    </div>
                  }
                  removeHolding={removeHolding}
                />
              </CardContent>
            </Card>
            <div ref={targetRef}>
              {calculation.result?.zakatDue && (
                <Card>
                  <CardContent>
                    <div className="space-y-6 py-8">
                      <div className="flex flex-col justify-center items-center">
                        <h1 className="text-2xl">Total zakat due</h1>
                        <h1 className="text-6xl font-semibold">
                          {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          }).format(calculation.result.zakatDue)}
                        </h1>
                      </div>
                      <div className="text-center">
                        <p className="text-md font-semibold ">
                          Share this calculator with your friends
                        </p>
                        <div className="flex flex-row justify-center items-center gap-2 pt-2">
                          <a
                            target="_blank"
                            rel="noopener"
                            href="https://api.whatsapp.com/send?text=Check%20out%20this%20free%20tool%20that%20helps%20you%20calculate%20how%20much%20zakat%20you%20owe%20on%20your%20stock%20portfolio%20instantly%20and%20accurately.%20https%3A%2F%2Fzoya.finance%2Fzakat"
                          >
                            <FontAwesomeIcon
                              icon={faWhatsappSquare}
                              className="w-8 h-8 hover:opacity-60"
                            />
                          </a>
                          <a
                            target="_blank"
                            rel="noopener"
                            href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fzoya.finance%2Fzakat&quote=Check%20out%20this%20free%20tool%20that%20helps%20you%20calculate%20how%20much%20zakat%20you%20owe%20on%20your%20stock%20portfolio%20instantly%20and%20accurately."
                          >
                            <FontAwesomeIcon
                              icon={faFacebookSquare}
                              className="w-8 h-8 hover:opacity-60"
                            />
                          </a>
                          <a
                            target="_blank"
                            rel="noopener"
                            href="https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fzoya.finance/zakat"
                          >
                            <FontAwesomeIcon
                              icon={faLinkedin}
                              className="w-8 h-8 hover:opacity-60"
                            />
                          </a>
                          <a
                            target="_blank"
                            rel="noopener"
                            href="https://twitter.com/intent/tweet?source=https%3A%2F%2Fapp.zoya.finance%2Fzakat&text=Check%20out%20this%20free%20tool%20by%20%40zoyafinance%20that%20helps%20you%20calculate%20how%20much%20zakat%20you%20owe%20on%20your%20stock%20portfolio%20instantly%20and%20accurately.&url=https%3A%2F%2Fzoya.finance%2Fzakat"
                          >
                            <FontAwesomeIcon
                              icon={faSquareXTwitter}
                              className="w-8 h-8 hover:opacity-60"
                            />
                          </a>
                        </div>
                      </div>

                      <div className="pt-6">
                        <Collapsible defaultOpen>
                          <CollapsibleTrigger className="flex flex-row w-full justify-center items-center gap-1">
                            <p className="text-sm ">Show breakdown</p>
                            <CaretSortIcon width={18} height={18} />
                          </CollapsibleTrigger>
                          <CollapsibleContent className="py-4">
                            {resultActiveHoldings &&
                              resultActiveHoldings.length > 0 && (
                                <>
                                  <p className="text-lg py-2 ">
                                    Short-term holdings
                                  </p>
                                  <ResultsHoldingsTable
                                    holdings={resultActiveHoldings}
                                  />
                                </>
                              )}
                            {resultPassiveHoldings &&
                              resultPassiveHoldings.length > 0 && (
                                <>
                                  <p className="text-lg py-2 ">
                                    Long-term holdings
                                  </p>
                                  <ResultsHoldingsTable
                                    holdings={resultPassiveHoldings}
                                  />
                                </>
                              )}
                          </CollapsibleContent>
                        </Collapsible>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import {
  SupportedCountryCodes,
  useGetPlaidInstitutionQuery,
  useSnaptradeBrokeragesQuery,
} from '@/graphQL/generatedGraphql'

export const useGetInstitutionLogo = (institutionId: string, isSnaptrade: boolean = false) => {
  const { data: plaidInstitutionData } = useGetPlaidInstitutionQuery({
    variables: {
      institutionId,
      countryCodes: [SupportedCountryCodes.Us],
    },
    skip: isSnaptrade,
  })

  const { data: snaptradeBrokeragesData } = useSnaptradeBrokeragesQuery({
    skip: !isSnaptrade,
  })

  if (isSnaptrade) {
    const snaptradeBrokerage = snaptradeBrokeragesData?.portfolio?.snaptrade?.brokerages.find(
      (brokerage) => brokerage.id === institutionId
    )
    if (snaptradeBrokerage?.squareLogoUrl) {
      return {
        image: snaptradeBrokerage.squareLogoUrl,
        type: 'url',
        format: 'png',
      }
    }
    if (snaptradeBrokerage?.logoUrl) {
      return {
        image: snaptradeBrokerage.logoUrl,
        type: 'url',
        format: 'png',
      }
    }
  } else if (plaidInstitutionData?.portfolio?.institution.institution?.logo) {
    return {
      image: plaidInstitutionData.portfolio.institution.institution.logo,
      type: 'base64',
      format: 'png',
    }
  }

  return undefined
}

import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { resetCodeRequestSchema } from '@/auth/validation'
import { z } from 'zod'

import { useNavigate } from 'react-router-dom'
import { ExclamationTriangleIcon } from '@radix-ui/react-icons'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { useCallback } from 'react'
import { usePasswordResetCode } from '@/auth/hooks/use-password-reset-code'

export const CodeRequestForm = () => {
  const navigate = useNavigate()

  const form = useForm<z.infer<typeof resetCodeRequestSchema>>({
    defaultValues: {
      email: '',
    },
    resolver: zodResolver(resetCodeRequestSchema),
    mode: 'onSubmit',
  })

  const onSuccess = useCallback(() => {
    navigate('/auth/password-reset', { state: { email: form.watch('email') } })
  }, [])

  const { request, response, error } = usePasswordResetCode(onSuccess)

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(request)}>
        <div className="grid gap-4">
          <div className="grid gap-2">
            {error && (
              <Alert variant="destructive">
                <ExclamationTriangleIcon className="h-4 w-4" />
                <AlertTitle>Failed to send password reset code</AlertTitle>
                <AlertDescription>{error.message}</AlertDescription>
              </Alert>
            )}
          </div>
          <div className="grid gap-2">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <Button
            type="submit"
            formAction="submit"
            className="w-full"
            disabled={form.formState.isSubmitting}
            loading={form.formState.isSubmitting}
          >
            Continue
          </Button>
        </div>
      </form>
    </Form>
  )
}
